import React, { Component } from 'react';
import * as d3 from "d3";
import _ from 'lodash'
import ContainerTranslation from "./../../partials/containerTranslation";
import { setPosition } from '../../../actions/vizualisation';
import { connect } from 'react-redux';

var simulation = d3.forceSimulation()
var minNodeSizeViz = 3;
var maxNodeSizeViz = 50;
var minLinkSizeViz = 0.1;
var maxLinkSizeViz = 3;

let width
let height
let map
let links
let nodes

let currentProps

class Graph extends Component {

  constructor(props) {
    super(props);
    const { params, setPosition } = this.props;
    currentProps = this.props
    this.state = {
      loading:true,
      selectedNodes:[]
    };
  }

  componentDidMount(){
    var _this = this
    d3.select('.vizualisation-graph').html('')
    var rect = d3.select('.vizualisation-graph').node().getBoundingClientRect();
    width = rect.width;
    height = rect.height;

    map = d3.select('.vizualisation-graph')
      .style("pointer-events", "all")
      .call(zoom_handler)

    links = d3.select('.vizualisation-graph')
      .append('svg')
      .attr('class', 'links')
      .append('g');

    nodes = d3.select('.vizualisation-graph')
      .append('div')
      .attr('class', 'nodes');

    d3.select('.reset-view').on("click", function () {
      resetView()
    });
    setTimeout(function(){
      _this.updateGraph()
    }, 500);
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.displayDocuments){ return }
    var _this = this

    if(prevProps.mode !== this.props.mode){
      d3.selectAll(".nodes").classed('blurred',true)
      d3.selectAll(".link").style('display','none')
      if(_this.props.mode === 'geography') {
        d3.select('.vizualisation-graph').style("pointer-events", "none")
      }else{
        d3.select('.vizualisation-graph').style("pointer-events", "all")
      }

      this.setState({
        loading:true
      })
      setTimeout(function(){
        resetView()
        updateView(_this.props, prevProps)
        _this.updateGraph()
      }, 500);
    }else if(
      prevProps.graphData.nodes.length !== this.props.graphData.nodes.length ||
      prevProps.graphData.links.length !== this.props.graphData.links.length ||
      (
        this.state.selectedNodes.length !== this.props.selectedNodes.length &&
        this.state.loading === false &&
        prevState.loading === false &&
        prevProps.displayLinks === this.props.displayLinks
      )
    ){
      if(!this.props.playMode || this.props.playMode === false){
        d3.selectAll(".nodes").classed('blurred',true)
        d3.selectAll(".link").style('display','none')
        this.setState({
          loading:true,
          selectedNodes:_this.props.selectedNodes
        })
        setTimeout(function(){
          resetView()
          updateView(_this.props, prevProps)
          d3.select('.links').select('g').html('')
          _this.updateGraph()
        }, 500);
      }else{
        this.setState({
          selectedNodes:_this.props.selectedNodes
        })
        resetView()
        updateView(_this.props, prevProps)
        d3.select('.links').select('g').html('')
        _this.updateGraph()
      }
    }else{
      d3.selectAll(".node")
        .style("opacity", function(d){
          if(_this.props.hiddenCategories.indexOf(d.category) === -1){
            d.visible = true
            return 1
          }
          d.visible = false
          return 0.1
        })

      if(prevState.loading === this.state.loading){
        d3.selectAll(".link")
          .style("display", function(d){
            var value = "none"
            if(d.source.visible && d.target.visible && _this.props.displayLinks === true){
              return "block"
            }
            return value
          })
      }

      if(this.props.displayLinks === false){
        d3.selectAll(".link").style('display','none')
      }
    }

  }

  setSelectedNode(node) {
    this.props.setSelectedNode(node);
  }

  updateGraph(){
    console.log("update")

    var _this = this
    var selectedNodes = this.props.selectedNodes
    var hiddenCategories = this.props.hiddenCategories
    var dataCategories = this.props.graphData.categories
    var dataNodes = this.props.graphData.nodes
    var dataLinks = this.props.graphData.links

    var maxNodeSize = _.maxBy(dataNodes, function (node) {
      return node.count;
    });
    if(maxNodeSize){
      maxNodeSize = maxNodeSize.count
    }else{
      maxNodeSize = 0
    }
    var maxLinkSize = _.maxBy(dataLinks, function (link) {
      return link.count;
    });
    if(maxLinkSize){
      maxLinkSize = maxLinkSize.count
    }else{
      maxLinkSize = 0
    }

    _.each(dataLinks, function (link) {
      link.size = minLinkSizeViz + link.count * maxLinkSizeViz / maxLinkSize;
    });
    _.each(dataNodes, function (node) {
      node.size = Math.round(minNodeSizeViz + node.count * maxNodeSizeViz / maxNodeSize);
      var group = _.findIndex(dataCategories, function (category) {
        return category.title == node.category;
      });
      node.group = group
    });

    var node = nodes.selectAll(".node")
      .data(dataNodes, function(e) { return e.compound })

    node.exit().remove()

    var nodeEnter = node
      .enter()
      .append("div")
        .style("display", "block")
        .attr("class", "node")
        .style("opacity", 0)
        .on("mouseover", function (d) {
          d3.selectAll('.node').style("opacity", function(n){
            if (n === d){
              return 1
            }
            var filteredLinks = _.filter(dataLinks, function(l){
              return l.source === d.index || l.target === d.index || l.source.index === d.index || l.target.index === d.index
             })
            var foundNode = _.find(filteredLinks, function(l){
              return l.source === n.index || l.target === n.index || l.source.index === n.index || l.target.index === n.index
            })
            if(foundNode && hiddenCategories.indexOf(n.category) === -1){
              return 1
            }
            return 0.1
          })
          d3.selectAll('.link').style("opacity", function(l){
            if (l.source === d || l.target === d){
              return 0.5
            }
            return 0
          })
          d3.select(this).select('.viz-label')
            .style("display", "inline-block");
        })
        .on("mouseout", function (d) {
          d3.selectAll('.node').style("opacity", function(d){
            if(hiddenCategories.indexOf(d.category) === -1){
              return 1
            }
            return 0.1
          })
          d3.selectAll('.link').style("opacity", 0.5)
          d3.select(this).select('.viz-label')
            .style("display", function (d) {
              return d.size * d3.zoomTransform(map).k > 10 ? "inline-block" : "none";
            });
        })
        .on("click", function (d) {
          _this.setSelectedNode(d)
        })
        .style("transform", function(d) {
          d.x = width/2
          d.y = height
          return "translate(" + d.x + "px ," + d.y + "px)";
        })
    nodeEnter
      .append("div")
        .attr("id", function (d) {
          return d.value;
        })
        .attr("class", "circle")
        .style("display", "inline-block")
        .style("background", function (d) {
          var foundColor = _.find(dataCategories, function(category){return category.title === d.category}).color;
          return "rgba(" + foundColor.r + ", " + foundColor.g + ", " + foundColor.b + ", " + foundColor.a + ")";
        })
        .style("box-shadow", function (d) {
          var value = d.size / 3;
          var foundSelectedNode = _.find(selectedNodes, function(n){
            return n.category === d.category && n.value === d.value
          })
          var foundColor = _.find(dataCategories, function(category){return category.title === d.category}).color;
          var alpha = 0.1
          if(foundSelectedNode){
            foundColor = {
              r:255,
              g:255,
              b:255
            }
            alpha = 1
          }
          return "0px 0px 0px " + value + "px rgba(" + foundColor.r + ", " + foundColor.g + ", " + foundColor.b + ","+alpha+")";
        })
        .transition()
        .duration(500)
        .style("height", function (d) {
          var value = d.size;
          return value + "px";
        })
        .style("width", function (d) {
          var value = d.size;
          return value + "px";
        })
        .style("border-radius", function (d) {
          var value = d.size;
          return value + "px";
        })
    nodeEnter
      .append("p")
        .attr("class", "viz-label")
        .html(function (d) {
          return d.value;
        })
        .style("transition", "none")
        .style("height", function (d) {
          return (d.size) + "px";
        })
        .style("line-height", function (d) {
          return (d.size) + "px";
        })
        .style("margin-left", function (d) {
          var value = 1
          var foundSelectedNode = _.find(selectedNodes, function(n){
            return n.category === d.category && n.value === d.value
          })
          if(foundSelectedNode){
            value = d.size / 3 + 1;
          }
          return value+"px"
        })
        .style("display", function (d) {
          return d.size * d3.zoomTransform(map).k > 10 ? "inline-block" : "none";
        });
    nodeEnter
      .append("title")
      .text(function (d) {
        return d.value;
      });

    node.select('.circle')
      .style("box-shadow", function (d) {
        var value = d.size / 3;
        var foundSelectedNode = _.find(selectedNodes, function(n){
          return n.category === d.category && n.value === d.value
        })
        var foundColor = _.find(dataCategories, function(category){return category.title === d.category}).color;
        var alpha = 0.1
        if(foundSelectedNode){
          foundColor = {
            r:255,
            g:255,
            b:255
          }
          alpha = 1
        }
        return "0px 0px 0px " + value + "px rgba(" + foundColor.r + ", " + foundColor.g + ", " + foundColor.b + ","+alpha+")";
      })
      .transition()
      .duration(500)
      .style("height", function (d) {
        var value = d.size;
        return value + "px";
      })
      .style("width", function (d) {
        var value = d.size;
        return value + "px";
      })
      .style("border-radius", function (d) {
        var value = d.size;
        return value + "px";
      })

    node.select(".viz-label")
      .transition()
      .duration(500)
      .style("height", function (d) {
        return (d.size) + "px";
      })
      .style("line-height", function (d) {
        return (d.size) + "px";
      })
      .style("margin-left", function (d) {
        var value = 1
        var foundSelectedNode = _.find(selectedNodes, function(n){
          return n.category === d.category && n.value === d.value
        })
        if(foundSelectedNode){
          value = d.size / 3 + 1;
        }
        return value+"px"
      })
      .style("display", function (d) {
        return d.size * d3.zoomTransform(map).k > 10 ? "inline-block" : "none";
      });

    drag_handler(node);

    links.selectAll(".link").remove()
    var link = links.selectAll(".link")
      .data(dataLinks)
      .enter()
      .append("path")
      .attr("class", "link")
      .style("stroke-width", function (d) {
        return d.size;
      })
      .style("fill", "none")
      .style("display", "none")

    this.setForce()
  }

  setForce(){

    var mode = this.props.mode
    var _this = this

    var hiddenCategories = this.props.hiddenCategories
    var dataCategories = this.props.graphData.categories
    var dataNodes = this.props.graphData.nodes
    var dataLinks = this.props.graphData.links

    var countryCategory = _.find(dataCategories, function(cat){return cat.type === 'country'})
    var countries = d3.select('.vizualisation-map').selectAll('path')

    if(countries._groups){
      countries = countries._groups[0]
    }else{
      countries = []
    }

    var node = d3.selectAll(".node")
    var link = d3.selectAll(".link")

    if(mode === 'group'){

      var yScale = []
      _.each(dataCategories, function(category){
        var catNodes = _.filter(dataNodes, function(n){
          return category.title === n.category
        })
        catNodes.sort(function (a, b) {
          return b.size - a.size;
        });
        var domain = _.map(catNodes, 'id')
        var range = _.map(catNodes, function(c, i){
          if(width >= 790){
            return 200 + ((height-350)/catNodes.length)*i
          }else{
            return 80 + ((width-150)/catNodes.length)*i
          }
        })
        yScale.push(d3.scaleOrdinal().domain(domain).range(range))
      })

      simulation
        .nodes(dataNodes)
        .force("links", d3.forceLink(dataLinks)
          .id(function(d) { return d.id })
          .distance(function(d) { return 0 })
          .strength([0])
        )
        .force("charge", null)
        .force("center", null)
        .force('collision', d3.forceCollide().radius(function(d) {
          return d.size + minNodeSizeViz
        }))
        .force("x",d3.forceX(function(d){
          if(width >= 790){
            var decalage = (width-300) / dataCategories.length
            return (decalage*d.group)+(decalage/2)+150
          }else{
            if(yScale[d.group]){
              return yScale[d.group](d.id)
            }
            return width / 2
          }
        }))
        .force("y", d3.forceY(function(d) {
          if(width >= 790){
            if(yScale[d.group]){
              return yScale[d.group](d.id)
            }
            return height / 2
          }else{
            var decalage = (height-150) / dataCategories.length
            return (decalage*d.group)+(decalage/2)+75
          }
        }))

      simulation.alpha(1).stop()

    }else if(mode === 'network' || mode === 'geography'){
      simulation
        .nodes(dataNodes)
        .force("links", d3.forceLink(dataLinks)
          .id(function(d) { return d.id; })
          .distance(function(d) { return /*maxLinkSizeViz*100 - 100*d.size;*/ 50 })
          .strength([1])
        )
        .force("charge", d3.forceManyBody()
          .strength(function(d) { return -100*d.size; })
        )
        .force('collision', d3.forceCollide()
          .radius(function(d) { return d.size + minNodeSizeViz })
        )
        if(mode === 'network'){
          simulation.force("center", d3.forceCenter(width / 2, height / 2))
        }else if(mode === 'geography'){
          simulation.force("center", null)
        }

      simulation.alpha(1).stop()
    }

    var numberOfTicks = Math.ceil(Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay()))
    for (var i = 0, n = numberOfTicks; i < n; ++i) {
      simulation.tick();
      node.each(function(d) {
        if(mode === 'geography' && d.category.toString() === countryCategory.title.toString() && d.code){
          var foundCountry = _.find(countries, function(c){
            return c.__data__ && c.__data__.id && d.code && c.__data__.id.toString() === d.code.toString()
          })
          if(foundCountry){
            var surroundingRectangle = foundCountry.getBoundingClientRect();
            d.x = surroundingRectangle.left+surroundingRectangle.width/2;
            d.y = surroundingRectangle.top+surroundingRectangle.height/2;
          }
        }
      })
    }

    d3.selectAll(".nodes").classed('blurred',false)
    this.setState({
      loading:false
    })

    if(this.props.playMode){
      node.transition()
        .duration(500)
        .style("opacity", function(d){
          if(hiddenCategories.indexOf(d.category) === -1){
            return 1
          }
          return 0.1
        })
        .style("transform", function(d) {
          return "translate(" + (d.x-d.size/2) + "px ," + (d.y-d.size/2) + "px)";
        })
    }else{
      setTimeout(function(){
        node.transition()
          .delay(function(d,i){
            return i*5
          })
          .duration(500)
          .style("opacity", function(d){
            if(hiddenCategories.indexOf(d.category) === -1){
              return 1
            }
            return 0.1
          })
          .style("transform", function(d) {
            return "translate(" + (d.x-d.size/2) + "px ," + (d.y-d.size/2) + "px)";
          })
      }, 500);
    }

    if(this.props.displayLinks === true){
      setTimeout(function(){
        d3.selectAll(".link")
          .style("display", function(d){
            var value = "none"
            if(d.source.visible && d.target.visible){
              value = "block"
            }
            return value
          })
          .style("opacity", 0)

        d3.selectAll(".link")
          .transition()
          .duration(500)
          .style("opacity", 0.5)
      }, dataNodes.length*5+1000);
    }
    setTimeout(function(){
      link.attr("d", linkArc);
    }, dataNodes.length*5+1000);

  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div style={{left:'40%',right:'40%',top:'40%', bottom:'40%', display:'flex', position:'absolute', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
            <i className="fa fa-circle-notch fa-spin fa-3x" style={{color: '#00e3cc'}}></i>
            <p style={{color:"#fff", marginTop:"10px", textAlign:'center'}}><ContainerTranslation id={"vizualisationView.displaying"}/> <span style={{fontWeight:"bold"}}>{this.props.graphData.nodes.length}</span> <ContainerTranslation id={"vizualisationView.node"}/> ...</p>
          </div>
        ) : (
          null
        )}
        <div className="vizualisation-graph" style={{width:this.props.width}}>
        </div>
      </div>
    );
  }

}

/*UTILS*/

/*
DRAG
*/

var drag_start = function (d) {
 if (!d3.event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
}

//make sure you can't drag the circle outside the box
var drag_moving = function (d) {
  d.fx = d3.event.x;
  d.fy = d3.event.y;
}

var drag_end = function (d) {
  if (!d3.event.active) simulation.alphaTarget(0);
  d.fx = null;
  d.fy = null;
}

var drag_handler = d3.drag()
  .on("start", drag_start)
  .on("drag", drag_moving)
  .on("end", drag_end);

/*
ZOOM
*/

var zoom_actions = function () {
  d3.select('.nodes')
    .attr("style", "transform:translate(" + d3.event.transform.x + "px ," + d3.event.transform.y + "px)" + " scale(" + d3.event.transform.k + ")");
  d3.select('.links').select('g')
    .attr("style", "transform:translate(" + d3.event.transform.x + "px ," + d3.event.transform.y + "px)" + " scale(" + d3.event.transform.k + ")");

  var node = d3.select('.vizualisation-graph').selectAll(".node")
  node.selectAll(".viz-label")
    .style("font-size", function (d) {
      return (12/d3.event.transform.k)+"px"
    })
    .style("display", function (d) {
      return d.size * d3.event.transform.k > 10 ? "inline-block" : "none";
    })
}

var zoomend_actions = function () {
  currentProps.setPosition(d3.event.transform)
}

var zoom_handler = d3.zoom()
  .scaleExtent([0.2, 8])
  .on("zoom", zoom_actions)
  .on("end", zoomend_actions)

var resetView = function () {
  var transform = d3.zoomIdentity
    .translate(0, 0)
    .scale(1);
  d3.select('.vizualisation-graph')
    .transition()
    .duration(500)
    .call(zoom_handler.transform, transform);
}

var updateView = function (props, prevProps) {
  if(props.comment !== prevProps.comment && props.comment.position){
    var transform = d3.zoomIdentity
      .translate(props.comment.position.x, props.comment.position.y)
      .scale(props.comment.position.k);
    d3.select('.vizualisation-graph')
      .transition()
      .duration(500)
      .call(zoom_handler.transform, transform);
  }
}

/*
LINK ARC
*/

var linkArc = function linkArc(d) {
  var dx = d.target.x - d.source.x,
    dy = d.target.y - d.source.y,
    dr = Math.sqrt(dx * dx + dy * dy);
  return "M" + (d.source.x) + "," + (d.source.y) + "A" + dr + "," + dr + " 0 0,1 " + (d.target.x) + "," + (d.target.y);
};

function mapStateToProps(state) {
  return {
    comment:state.comment.comment
  };
}

export default connect(mapStateToProps, {setPosition})(Graph);
