import { CLOSE_V1_MESSAGE } from '../actions/types';

const INITIAL_STATE = { message: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLOSE_V1_MESSAGE:
      return { ...state, message: false};
  }

  return state;
}
