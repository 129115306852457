import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getForgotPasswordToken } from '../../actions/auth';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';

const form = reduxForm({
  form: 'forgotPassword',
});

class ForgotPassword extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/');
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.authenticated) {
      this.props.history.push('/');
    }
  }

  handleFormSubmit(formProps) {
    this.props.getForgotPasswordToken(formProps);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="message error">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message) {
      return (
        <div className="message">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="page-login">
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className="form-login" style={{top:0, animationDelay: 0}}>
          <img src={logo}/>
          {this.renderAlert()}
          <h1>Forgot password</h1>
          <div className="row">
            <label className="" htmlFor="email">Email</label>
            <Field name="email" id="email" className="form-control" component="input" type="email" placeholder="Type your email here ..."/>
          </div>
          <button type="submit" className="btn btn-submit">Reset Password</button>
          <Link to="/login" style={{display:"block",marginTop:"10px"}}>Go to Login</Link>
          <Link to="/register" style={{display:"block",marginTop:"10px"}}>Create an account</Link>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
  };
}

export default withRouter(connect(mapStateToProps, { getForgotPasswordToken })(form(ForgotPassword)));
