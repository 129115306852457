import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContainerTranslation from "./../containerTranslation";

class Button extends Component {

  pushButton(){
    this.props.action()
  }

  render() {
    var customClass = "btn "+this.props.type
    var customStyle = this.props.style

    if(this.props.link){
      return (
        <Link className={customClass} to={this.props.link} style={customStyle}>
          {this.props.icon ? (
            <i className={this.props.icon}></i>
          ) : (
            null
          )}
          {this.props.textTranslation ? (
            <ContainerTranslation id={this.props.textTranslation}/>
          ) : (
            <p>{this.props.text}</p>
          )}
        </Link>
      );
    }

    if(this.props.href){
      return (
        <a className={customClass} href={this.props.href} style={customStyle}>
          {this.props.icon ? (
            <i className={this.props.icon}></i>
          ) : (
            null
          )}
          {this.props.textTranslation ? (
            <ContainerTranslation id={this.props.textTranslation}/>
          ) : (
            <p>{this.props.text}</p>
          )}
        </a>
      );
    }

    return (
      <a className={customClass} onClick={this.pushButton.bind(this)} style={customStyle}>
        {this.props.icon ? (
          <i className={this.props.icon}></i>
        ) : (
          null
        )}
        {this.props.textTranslation ? (
          <ContainerTranslation id={this.props.textTranslation}/>
        ) : (
          <p>{this.props.text}</p>
        )}
      </a>
    );

  }
}

export default Button;
