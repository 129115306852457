import React, { Component } from 'react'
import * as d3 from "d3";
import _ from 'lodash';

import { country_data } from "./../../../assets/geography/countries";
import { string_to_slug } from "./../../helpers";

var scaleValue = 6

class Geography extends Component {

   constructor(props){
      super(props)
      this.state = {
        focusOn:null
      };
   }
   componentDidMount() {
     var _this = this
     this.createMap()
     var foundSelectedNodeCountry = _.find(_this.props.selectedNodes, function(n){
       return n.category.toString() === _this.props.category.title.toString()
     })
     if(foundSelectedNodeCountry){
       var countries = d3.select('.vizualisation-map').selectAll('path')
       if(countries._groups){
         countries = countries._groups[0]
       }else{
         countries = []
       }
       var foundCountry = _.find(countries, function(c){
         return c.__data__ && c.__data__.id && foundSelectedNodeCountry.code && c.__data__.id.toString() === foundSelectedNodeCountry.code.toString()
       })
       if(foundCountry){
         this.setState({
           focusOn:foundCountry
         })
       }else{
         this.setState({
           focusOn:null
         })
       }
     }
   }
   componentDidUpdate(prevProps, prevState) {
     var _this = this
     var foundSelectedNodeCountry = _.find(_this.props.selectedNodes, function(n){
       return n.category.toString() === _this.props.category.title.toString()
     })
     if(foundSelectedNodeCountry){
       var countries = d3.select('.vizualisation-map').selectAll('path')
       if(countries._groups){
         countries = countries._groups[0]
       }else{
         countries = []
       }
       var foundCountry = _.find(countries, function(c){
         return c.__data__ && c.__data__.id && foundSelectedNodeCountry.code && c.__data__.id.toString() === foundSelectedNodeCountry.code.toString()
       })
       if(foundCountry && !this.state.focusOn){
         this.setState({
           focusOn:foundCountry
         })
       }else if(!foundCountry && this.state.focusOn){
         this.setState({
           focusOn:null
         })
       }
     }else if(!foundSelectedNodeCountry && this.state.focusOn){
       this.setState({
         focusOn:null
       })
     }
     if(!this.state.focusOn){
       d3.select('.vizualisation-map')
         .transition()
         .duration(500)
          .attr("style", "transform:translate(0px ,0px) scale(1)");
     }else if(this.state.focusOn !== prevState.focusOn){
       let width = window.innerWidth
       let height = window.innerHeight

       var surroundingRectangle = this.state.focusOn.getBoundingClientRect();
       var translate_x = width*scaleValue/2-(surroundingRectangle.left*scaleValue+surroundingRectangle.width*scaleValue/2)
       var translate_y = height*scaleValue/2-(surroundingRectangle.top*scaleValue+surroundingRectangle.height*scaleValue/2)

       return d3.select('.vizualisation-map')
         .transition()
         .duration(500)
         .attr("style", "transform:translate(" + translate_x + "px ," + translate_y + "px)" + " scale("+scaleValue+")");
     }
   }
   createMap() {
      const _this = this

      let width = d3.select('.vizualisation-map').style('width')
      width = parseFloat(width.substring(0, width.length - 2))
      let height = d3.select('.vizualisation-map').style('height')
      height = parseFloat(height.substring(0, height.length - 2))

      var scale  = width/6;
      var offset = [width/2, height/1.6];
      //var projection = d3.geoOrthographic()
      var projection = d3.geoMercator()
        .scale(scale)
        .translate(offset);

      // create the path
      var path = d3.geoPath().projection(projection);

      var svg = d3.select('.vizualisation-map')
      svg.html('')

      /*const graticule = d3.geoGraticule();
        svg.append("path")
            .datum(graticule)
            .attr("class", "graticule")
            .attr("d", path);*/

      var countries = svg.selectAll("path")
        .data(country_data.features) // get the data here: https://gist.github.com/2969317
        .enter()
        .append('svg:path')
          .attr('d', path)
          .attr('class', 'country')
          .attr('fill', "#081148")
          .attr('stroke', 'none')
          .style('opacity', 1)

   }

   render() {
      return (
        <div style={{height:'100%', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
          <svg className="vizualisation-map" width={'100%'} height={'100%'}></svg>
        </div>
      )
   }
}
export default Geography
