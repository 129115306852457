import React, { Component } from 'react';
import Item from './Item';

class List extends Component {

  selectItem(id){
    this.props.selectItem(id)
  }

  deleteItem(id){
    this.props.deleteItem(id)
  }

  render() {
    var customClass = "list "+this.props.theme

    return (
      <div className={customClass}>
        {this.props.data.map(d => <Item
          key={d._id}
          name={d.name}
          nameTranslate={d.nameTranslate}
          description={d.description}
          id={d._id}
          icon={this.props.icon}
          theme={this.props.theme}
          withDetails={this.props.withDetails}
          withTools={this.props.withTools}
          selectedItem={this.props.selectedItem}
          selectItem={this.selectItem.bind(this)}
          deleteItem={this.deleteItem.bind(this)}
        />)}
      </div>
    );

  }
}

export default List;
