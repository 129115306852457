import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import ContainerTranslation from "../partials/containerTranslation";
import ReactTooltip from 'react-tooltip'
import { fetchTeams, createTeam, updateTeam, deleteTeam } from '../../actions/team';
import { fetchAccount } from '../../actions/account';
import _ from 'lodash';

import List from './../partials/List/List'
import MemberList from './member-list';

var validateEmail = function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

class Team extends Component {

  constructor(props) {
    super(props);
    const { params, fetchTeams, createTeam, fetchAccount, updateTeam , deleteTeam} = this.props;
    this.state = {
      displayAddTeam:false,
      displayAddMember:false,
      member:"",
      selectedTeam:null,
      teamName:"",
      error: false,
    };
    fetchTeams();
    fetchAccount()
  }

  componentDidUpdate(prevProps){
    if(this.props.message && this.props.message !== prevProps.message){
      if(this.props.message === "Team successfully updated!" || this.props.message === "Account successfully updated!" || this.props.message === "Team successfully removed!" || this.props.message === 'Team created !'){
        this.props.fetchTeams()
        this.props.fetchAccount()
      }
    }
  }

  addMember(elm) {
    var _this= this
    if(validateEmail(_this.state.member)){
      this.props.updateTeam(_this.state.member, _this.state.selectedTeam)
      this.setState({
        displayAddMember:false,
        error:false
      })
    }else{
      this.setState({
        member:"",
        error:true
      })
    }
  }

  addMemberTeam(elm) {
    var _this= this
    this.props.updateTeam(elm.value, _this.state.selectedTeam)
    this.setState({
      displayAddMember:false
    })
  }

  addTeam(elm) {
    var _this= this
    this.props.createTeam({name:_this.state.teamName})
    this.setState({
      displayAddTeam:false
    })
  }

  displayAddMember(elm) {
    this.setState({
      displayAddMember:!this.state.displayAddMember
    })
  }

  displayAddTeam(elm) {
    this.setState({
      displayAddTeam:!this.state.displayAddTeam
    })
  }

  handleTeamChange(e){
    this.setState({
      teamName:e.currentTarget.value
    })
  }

  handleMemberChange(e){
    this.setState({
      member:e.currentTarget.value
    })
  }

  selectTeam(id){
    this.setState({
      selectedTeam:id
    })
  }

  handleDeleteTeam(id) {

    var result = window.confirm("Do you really want to delete this team ?");
    if (result) {
      this.props.deleteTeam(id)
      this.props.fetchTeams()
    }

  }

  renderMembers() {
    var _this = this
    if(this.props.account && this.props.account._id){
      if(this.state.selectedTeam === null){
        return (
          <MemberList members={this.props.account.members} selectedTeam={this.state.selectedTeam}/>
        );
      }else{
        var foundTeam = _.find(_this.props.teams, function(t){
          return t._id.toString() === _this.state.selectedTeam.toString()
        })
        if(foundTeam){
          return (
            <MemberList members={foundTeam.members} selectedTeam={this.state.selectedTeam}/>
          );
        }
      }
    }
  }

  render() {
    var classLine = "list-item"
    if(this.state.selectedTeam === null){
      classLine += " selected"
    }

    var limitationStyle = {}
    if(this.props.account && this.props.account.members && this.props.account.members.length >= this.props.account.limitations.sources){
      limitationStyle = {backgroundColor:'#c92b2b'}
    }
    var limitationTextStyle = {margin:0}
    if(this.props.account && this.props.account.members && this.props.account.members.length >= this.props.account.limitations.sources){
      limitationTextStyle = {color:'#ffffff',margin:0}
    }

    return (
      <div className="page-container" style={{marginBottom:'300px'}}>
        <div className="col-1-3">
          <h1 className="page-title"><ContainerTranslation id={"user.team"}/></h1>
          <div className="container" style={{marginTop: 0, marginLeft: 0, paddingLeft:0}}>
            <div className={classLine} onClick={this.selectTeam.bind(this, null)} style={{boxShadow: 'none', paddingTop: 11, paddingBottom: 10, display: 'block', marginLeft:20, paddingLeft: 0}}>
              <a className="">
                <i className="fas fa-users" aria-hidden="true"></i> <p>All members</p>
              </a>
            </div>
            { this.props.teams && this.props.teams.length ? (
              <List
                data = {this.props.teams}
                icon = "fas fa-user-friends"
                theme = "light"
                withTools = {true}
                selectedItem={this.state.selectedTeam}
                selectItem={this.selectTeam.bind(this)}
                deleteItem={this.handleDeleteTeam.bind(this)}
              />
            ) : (
              null
            )}
            { this.state.displayAddTeam ? (
              <div>
                <input type="text" placeholder="Name of the team..." onChange={ (e) => this.handleTeamChange(e) } style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  marginTop: '16px',
                  marginRight: '5px',
                  width: 'calc(100% - 110px)'
                }}/>
                <a className="add-btn btn small" data-for='addTeam' data-tip="team.addTeam" onClick={this.addTeam.bind(this)} style={{marginTop: '17px'}}><i className="fas fa-check"></i></a>
                <a onClick={this.displayAddTeam.bind(this)} data-for='closeMember3' data-tip="team.closeMember" style={{display: 'inline-block', verticalAlign: 'top',marginTop: '18px',marginLeft: '10px',fontSize: '24px', color:"#0a1760"}}><i className="fas fa-times"></i></a>
                <ReactTooltip className='tooltip' id='addTeam' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
                <ReactTooltip className='tooltip' id='closeMember3' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
              </div>
            ) : (
              <div>
                <a className="add-btn btn small" data-for='displayAddTeam' data-tip="team.displayAddTeam" onClick={this.displayAddTeam.bind(this)} style={{marginTop: '10px'}}><i className="fas fa-plus"></i></a>
                <ReactTooltip className='tooltip' id='displayAddTeam' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
              </div>
            )}

          </div>
        </div>
        <div className="col-2-3">
          <h1 className="page-title"><ContainerTranslation id={"user.member"}/></h1>
          { this.props.account && this.props.account.members && !this.state.selectedTeam ? (
            <div className="limitation-box" style={limitationStyle}><p style={limitationTextStyle}><span style={{fontWeight:'bold'}}>{this.props.account.members.length}</span>/{this.props.account.limitations.sources}</p></div>
          ) : (
            null
          )}
          <div className="container" style={{marginTop: 0, marginLeft: 0}}>
            {this.renderMembers()}
            { this.state.displayAddMember && this.state.selectedTeam ? (
              <div>
                <div style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  marginTop: '16px',
                  marginRight: '10px',
                  width: 'calc(100% - 50px)'
                }}>
                  <Select
                    className="Select"
                    isClearable
                    isSearchable
                    options={_.map(this.props.account.members, function(member){return {value:member.email,label:member.email}})}
                    multi={false}
                    placeholder={'Select your member'}
                    onChange={ (e) => this.addMemberTeam(e) }
                  />
                </div>
                <a onClick={this.displayAddMember.bind(this)} data-for='closeMember2' data-tip="team.closeMember" style={{display: 'inline-block', verticalAlign: 'top',marginTop: '18px',marginLeft: '10px',fontSize: '24px', color:"#0a1760"}}><i className="fas fa-times"></i></a>
                <ReactTooltip className='tooltip' id='closeMember2' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
              </div>
            ) : (
              null
            )}
            { this.state.displayAddMember && !this.state.selectedTeam ? (
              <div>
                <input type="text" placeholder="Email of the member..." onChange={ (e) => this.handleMemberChange(e) } style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  marginTop: '16px',
                  marginRight: '5px',
                  width: 'calc(100% - 110px)'
                }}/>
                <a className="add-btn btn small" data-for='addMember' data-tip="team.addMember" onClick={this.addMember.bind(this)} style={{marginTop: '16px', position: 'relative'}}><i className="fas fa-check"></i></a>
                <ReactTooltip className='tooltip' id='addMember' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
                <a onClick={this.displayAddMember.bind(this)} data-for='closeMember' data-tip="team.closeMember" style={{display: 'inline-block', verticalAlign: 'top',marginTop: '18px',marginLeft: '10px',fontSize: '24px', color:"#0a1760"}}><i className="fas fa-times"></i></a>
                <ReactTooltip className='tooltip' id='closeMember' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
                { this.state.error ? (
                  <div className="message error" style={{marginTop: '20px'}}>Please provide a valid email adress !</div>
                ) : (
                  null
                )}
              </div>
            ) : (
              null
            )}
            { !this.state.displayAddMember && this.props.account && this.props.account.members && this.props.account.members.length < this.props.account.limitations.sources ? (
              <div>
                <a className="add-btn btn small" data-for='displayAddMember' data-tip="team.displayAddMember" onClick={this.displayAddMember.bind(this)} style={{marginTop: '10px'}}><i className="fas fa-plus"></i></a>
                <ReactTooltip className='tooltip' id='displayAddMember' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
              </div>
            ) : (
              null
            )}
            { !this.state.displayAddMember && !this.state.selectedTeam && this.props.account && this.props.account.members && this.props.account.members.length >= this.props.account.limitations.sources ? (
              <div>
                <a className="add-btn btn small" data-for='displayAddMember' data-tip="team.displayAddMemberNo" style={{marginTop: '10px', cursor:'not-allowed', opacity:0.3}}><i className="fas fa-plus"></i></a>
                <ReactTooltip className='tooltip' id='displayAddMember' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
              </div>
            ) : (
              null
            )}
            { !this.state.displayAddMember && this.state.selectedTeam && this.props.account && this.props.account.members && this.props.account.members.length >= this.props.account.limitations.sources ? (
              <div>
                <a className="add-btn btn small" data-for='displayAddMember' data-tip="team.displayAddMember" onClick={this.displayAddMember.bind(this)} style={{marginTop: '10px'}}><i className="fas fa-plus"></i></a>
                <ReactTooltip className='tooltip' id='displayAddMember' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
              </div>
            ) : (
              null
            )}
          </div>
        </div>


      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    teams: state.team.teams,
    message: state.team.message,
    account: state.account.account
  };
}

export default connect(mapStateToProps, {fetchTeams, createTeam, fetchAccount, updateTeam, deleteTeam})(Team);
