import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateTeam } from '../../actions/team';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "../partials/containerTranslation";

class MemberItem extends Component {

  constructor(props) {
    super(props);
    const { params, updateTeam } = this.props;
  }

  handleDelete(elm) {
    var _this = this
    var result = window.confirm("Do you really want to remove this member ?");
    if (result) {
      this.props.updateTeam(_this.props.email, _this.props.selectedTeam)
    }

  }

  render() {
    if(this.props.profile){
      return (
        <div className="line" key={"user"+this.props.i}>
          <a className="lineContent">
            <i className="fa fa-user" aria-hidden="true"></i> <p>{this.props.profile.firstName} {this.props.profile.lastName}</p>
            <p className="hideOnMobile" style={{opacity:0.4, marginLeft:'5px'}}>- {this.props.email}</p>
          </a>
          <div className="tools">
            <a onClick={this.handleDelete.bind(this)} data-for='deleteMember' data-tip="team.deleteMember" className="Message-remove"><i className="fa fa-trash" aria-hidden="true"></i></a>
            <ReactTooltip className='tooltip' id='deleteMember' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
          </div>
        </div>
      );
    }else{
      return (
        <div className="line" key={"user"+this.props.i}>
          <a className="lineContent">
            <i className="fa fa-envelope" aria-hidden="true"></i> <p>{this.props.email} - <span className="hideOnMobile" style={{color:'#FF8F4C'}}>Not yet a Bakamap User</span></p>
          </a>
          <div className="tools">
            <a onClick={this.handleDelete.bind(this)} data-for='deleteMember' data-tip="team.deleteMember" className="Message-remove"><i className="fa fa-trash" aria-hidden="true"></i></a>
            <ReactTooltip className='tooltip' id='deleteMember' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
          </div>
        </div>
      )
    }

  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps, {updateTeam})(MemberItem);
