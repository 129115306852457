import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "../../partials/containerTranslation";
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'
import { fetchKeywords } from '../../../actions/vizualisation';

class Information extends Component {

  constructor(props) {
    super(props);
    const { params, fetchKeywords } = this.props;
    fetchKeywords()
    this.state = {
      thumbnail:null
    };
  }

  componentDidUpdate(prevProps){
    var _this = this
    if(this.state.thumbnail === null && this.props.thumbnail){
      this.setState({
        thumbnail:_this.props.thumbnail
      })
    }
  }

  handleNameChange(e){
    var _this = this
    this.props.setNewState({
      file:_this.props.file,
      name:e.currentTarget.value,
      description:_this.props.description,
      keywords:_this.props.keywords,
      error:_this.props.error
    })
  }

  handleKeywordsChange(e){
    var _this = this
    this.props.setNewState({
      file:_this.props.file,
      name:_this.props.name,
      description:_this.props.description,
      keywords:_.map(e,'value'),
      error:_this.props.error
    })
  }

  handleDescriptionChange(e){
    var _this = this
    this.props.setNewState({
      file:_this.props.file,
      name:_this.props.name,
      description:e.currentTarget.value,
      keywords:_this.props.keywords,
      error:_this.props.error
    })
  }

  handleImageChange(selectorFiles){
    var extension = selectorFiles[0].name.split('.')[selectorFiles[0].name.split('.').length-1]
    var _this = this
    if(extension.toLowerCase() === 'jpg' || extension.toLowerCase() === 'jpeg' || extension.toLowerCase() === 'png'){
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;
        _this.props.setNewState({
          file:selectorFiles[0],
          name:_this.props.name,
          description:_this.props.description,
          keywords:_this.props.keywords,
          error:null
        })
      };
      reader.readAsBinaryString(selectorFiles[0])
    }else{
      this.props.setNewState({
        file:null,
        name:_this.props.name,
        description:_this.props.description,
        keywords:_this.props.keywords,
        error:"Wrong format, please use JPEG or PNG file"
      })
    }
  }

  render() {

    var _this = this

    var tileStyle = {
      background:'#384276',
      borderLeft: 'none',
      height: '286px',
      marginTop: '24px',
      paddingLeft: 0,
      borderRadius: '8px'
    };

    if(this.state.thumbnail){
      tileStyle.background = 'url('+this.state.thumbnail+')'
      tileStyle.backgroundSize = 'cover'
      tileStyle.backgroundPosition = 'center'
    }
    var key = new Date().getTime()+"thumbnail"

    var keywordsOptions = []
    var defaultValue = []
    if(this.props.allKeywords){
      keywordsOptions = _.map(this.props.allKeywords, function(keyword){
        return { value: keyword, label: keyword }
      })
    }
    defaultValue = _.map(_this.props.keywords, function(keyword){
      return { value: keyword, label: keyword }
    })

    return (
      <div className="container container-add" style={{display: 'inline-block', marginLeft: '100px', width: 'calc(100% - 200px)', textAlign:'center', paddingTop:0, marginTop:0}}>
        <div className="col-container">
          <div className="col half" style={{textAlign:'left'}}>
            <label className="dark"><ContainerTranslation id={"vizualisation.name"}/></label>
            <input type="text" value={this.props.name} placeholder="Name of the Vizualisation..." onChange={ (e) => this.handleNameChange(e) } style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}/>
            <label className="dark" style={{marginBottom: '5px', display:'block'}}><ContainerTranslation id={"vizualisation.keywords"}/></label>
            {this.props.allKeywords.length ? (
              <CreatableSelect
                className="Select"
                value={defaultValue}
                placeholder="Keywords..."
                options={keywordsOptions}
                isMulti
                isSearchable
                isClearable
                onChange={ (e) => this.handleKeywordsChange(e) }
              />
            ) : (
              null
            )}
            <label className="dark" style={{marginTop: '15px', display:'block'}}><ContainerTranslation id={"vizualisation.description"}/></label>
            <textarea id="story" value={this.props.description} name="story" placeholder="Description of the Vizualisation..." onChange={ (e) => this.handleDescriptionChange(e) } style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '5px'
            }}>
            </textarea>
          </div>
          <div className="col half" style={tileStyle} key={key}>
            {this.state.thumbnail ? (
              <label htmlFor="image" className="btn" style={{marginTop:'110px', textTransform:'initial'}}>
                <i className="fas fa-upload"></i>
                <ContainerTranslation id={"vizualisation.browseChange"}/>
              </label>
            ) : (
              <label htmlFor="image" className="btn" style={{marginTop:'110px', textTransform:'initial'}}>
                <i className="fas fa-upload"></i>
                <ContainerTranslation id={"vizualisation.browse"}/>
              </label>
            )}
            <input type="file" className="input-file" id="image" accept=".jpg" onChange={ (e) => this.handleImageChange(e.target.files) }/>
            {this.props.file ? (
              <div>
                <div style={{width:'100%'}}>
                  <p style={{
                    color:'#fff',
                    fontWeight:'700',
                    marginTop:'20px',
                    display:'inline-block',
                  }}>{this.props.file.name}</p>
                </div>
              </div>
            ) : (
              null
            )}
          </div>
        </div>
        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allKeywords: state.vizualisation.keywords,
  };
}

export default connect(mapStateToProps,{fetchKeywords})(Information);
