import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import ContainerTranslation from "../partials/containerTranslation";
import LanguageToggle from "./language-toggle";
import { editProfile } from '../../actions/auth';
import { fetchProfile } from '../../actions/index';
import { updateAccountLogo } from '../../actions/account';

const form = reduxForm({
  form: 'register',
  validate,
});

const renderField = field => (
  <div>
    {field.meta.touched && field.meta.error && <div className="error">{field.meta.error}</div>}
    <input className="form-control" type={field.type} placeholder={field.placeholder} {...field.input}/>
  </div>
);

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstName = 'Please enter a first name';
  }

  if (!formProps.lastName) {
    errors.lastName = 'Please enter a last name';
  }

  if (!formProps.password) {
    errors.password = 'Please enter a password';
  }

  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  if(!strongRegex.test(formProps.password)) {
    errors.password = 'Please enter a password with all requirement';
  }

  return errors;
}

class EditProfile extends Component {

  constructor(props) {
    super(props);
    const { params, updateAccountLogo, fetchProfile } = this.props;
    this.state = {
      logo:null,
      error:null
    };
  }

  handleFormSubmit(formProps) {
    this.props.editProfile(this.props.profile._id,formProps);
  }

  componentDidUpdate(prevProps) {
    if(this.props.message && this.props.message === "User successfully updated!"){
      this.props.history.push('/');
    }
    if(this.props.messageAccount && this.props.messageAccount === "Account successfully updated!"){
      this.props.fetchProfile()
    }
    if(this.props.messageAccount && this.props.messageAccount === "Logo successfully removed!"){
      this.props.fetchProfile()
    }
  }

  changeLanguage(elm) {
    var _this= this
  }

  handleImageChange(selectorFiles){
    var extension = ""
    if(selectorFiles[0]){
      extension = selectorFiles[0].name.split('.')[selectorFiles[0].name.split('.').length-1]
    }

    var _this = this
    if(extension.toLowerCase() === 'jpg' || extension.toLowerCase() === 'jpeg' || extension.toLowerCase() === 'png'){
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;
        _this.props.updateAccountLogo(selectorFiles[0])
      };
      reader.readAsBinaryString(selectorFiles[0])
    }else{
      this.setState({
        error:"Wrong format, please use JPEG or PNG file"
      })
    }
  }

  removeLogo(){
    this.props.updateAccountLogo()
  }

  renderAlert() {
    if (this.state.error) {
      return (
        <div className="message error">
          <strong>Oops!</strong> {this.state.error}
        </div>
      );
    }
  }

  renderFields() {
    if (this.props.initialValues) {
      return (
        <div>
          <div style={{marginBottom: '15px'}}>
            <label style={{marginBottom: '5px', display:'block'}}>Language</label>
            <LanguageToggle/>
          </div>
          <div>
            <label>FirstName</label>
            <Field name="firstName" component={renderField} type="text" placeholder="Your first name ..."/>
          </div>
          <div>
            <label>Name</label>
            <Field name="lastName" component={renderField} type="text" placeholder="Your last name ..."/>
          </div>
          <div>
            <label>Change your password</label>
            <p style={{fontStyle: 'italic', color: '#999', marginTop: 0}}>Your new password must contain one uppercase, one lowercase, one number, one special character (!@#$%^$*) and be 8 characters long</p>
            <Field name="password" component={renderField} type="password" placeholder="Type your password ..."/>
          </div>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;
    var logoStyle = {
      background:'#e6e6e6',
      width: '128px',
      height: '128px',
      marginTop: '24px',
      paddingLeft: 0,
      marginLeft: 'auto',
      marginRight: 'auto'
    };

    if(this.props.profile && this.props.profile.logo && this.props.profile.accountType !== 'Member'){
      logoStyle.background = 'url('+this.props.profile.logo+')'
      logoStyle.backgroundSize = 'cover'
      logoStyle.backgroundPosition = 'center'
    }
    var key = new Date().getTime()+"thumbnail"

    return (
      <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}
        className="container container-form"
        style={{width: 'calc(100% - 40px)', 'paddingRight': '20px', 'paddingLeft': '20px', borderRadius: '8px', marginTop: '20px', boxShadow: '0 5px 9px rgba(36,117,130,.2)',marginBottom: '20px'}}>
        <p style={{color:'#152764', marginTop:0}}>Email : <b>{this.props.email}</b></p>

        {/*IMAGE*/}
        {this.props.profile && this.props.profile.accountType !== 'Member' ? (
          <div>
            {this.props.profile && this.props.profile.logo ? (
              <div style={logoStyle} key={key}>
              </div>
            ) : (
              null
            )}
            {this.props.profile && this.props.profile.logo ? (
              <label htmlFor="image" className="btn" style={{marginBottom:'10px', textTransform:'initial'}}>
                <i className="fas fa-upload"></i>
                <ContainerTranslation id={"profile.browseChange"}/>
              </label>
            ) : (
              <label htmlFor="image" className="btn" style={{marginBottom:'10px', textTransform:'initial'}}>
                <i className="fas fa-upload"></i>
                <ContainerTranslation id={"profile.browse"}/>
              </label>
            )}
            <input type="file" className="input-file" id="image" accept="image/png, image/jpeg" onChange={ (e) => this.handleImageChange(e.target.files) }/><br/>
            {this.props.profile && this.props.profile.logo ? (
              <a style={{marginBottom: 30, display: 'block'}} onClick={ (e) => this.removeLogo(e) }><ContainerTranslation id={"profile.removeLogo"}/></a>
            ) : (
              null
            )}
            
          </div>
        ):(
          null
        )}
        {/*END IMAGE*/}

        {this.renderAlert()}
        {this.renderFields()}
        <button type="submit" className="btn btn-submit"><ContainerTranslation id={"profile.editInformation"}/></button>
      </form>
    );
  }
}

function mapStateToProps(state) {
  let values = null

  if(state.user && state.user.profile && state.user.profile.email){
    values = state.user.profile
  }
  return {
    profile: state.user.profile,
    message: state.user.message,
    messageAccount: state.account.message,
    initialValues: values,
  };
}

export default withRouter(connect(mapStateToProps, { editProfile, updateAccountLogo, fetchProfile })(form(EditProfile)));
