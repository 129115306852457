//= =====================
// Global Actions
//= =====================
export const CLOSE_V1_MESSAGE = 'close_v1_message';

//= =====================
// Auth Actions
//= =====================
export const AUTH_USER = 'auth_user',
  UNAUTH_USER = 'unauth_user',
  AUTH_ERROR = 'auth_error',
  REGISTER_USER = 'register_user',
  FORGOT_PASSWORD_REQUEST = 'forgot_password_request',
  RESET_PASSWORD_REQUEST = 'reset_password_request',
  PROTECTED_TEST = 'protected_test';

//= =====================
// User Profile Actions
//= =====================
export const FETCH_PROFILE = 'fetch_profile';

//= =====================
// Users Actions
//= =====================
export const FETCH_USERS = 'fetch_users',
  FETCH_USER = 'fetch_user',
  UPDATE_USER = 'update_user',
  DELETE_USER = 'delete_user',
  USER_ERROR = 'user_error';

//= =====================
// Accounts Actions
//= =====================
export const FETCH_ACCOUNT = 'fetch_account',
  UPDATE_ACCOUNT = 'update_account',
  UPDATE_ACCOUNT_LOGO = 'update_account_logo',
  ACCOUNT_ERROR = 'account_error';

//= =====================
// Teams Actions
//= =====================
export const FETCH_TEAMS = 'fetch_teams',
  CREATE_TEAM = 'create_team',
  UPDATE_TEAM = 'update_team',
  DELETE_TEAM = 'delete_team',
  FETCH_TEAM = 'fetch_team',
  TEAM_ERROR = 'team_error';

//= =====================
// Comments Actions
//= =====================
export const FETCH_COMMENTS = 'fetch_comments',
  CREATE_COMMENT = 'create_comment',
  UPDATE_COMMENT = 'update_comment',
  DELETE_COMMENT = 'delete_comment',
  FETCH_COMMENT = 'fetch_comment',
  SELECT_COMMENT = 'select_comment',
  COMMENT_ERROR = 'comment_error';

//= =====================
// Sources Actions
//= =====================
export const CREATE_SOURCE = 'create_source',
  FETCH_SOURCES = 'fetch_sources',
  FETCH_SOURCE = 'fetch_source',
  FETCH_SOURCE_NODES = 'fetch_source_nodes',
  UPDATE_SOURCE = 'update_source',
  INIT_SOURCE = 'init_source',
  DELETE_SOURCE = 'delete_source',
  SOURCE_ERROR = 'source_error';

//= =====================
// Vizualisations Actions
//= =====================
export const CREATE_VIZUALISATION = 'create_vizualisation',
  FETCH_VIZUALISATIONS = 'fetch_vizualisations',
  FETCH_KEYWORDS = 'fetch_keywords',
  FETCH_VIZUALISATION = 'fetch_vizualisation',
  FETCH_VIZUALISATION_VIEW = 'fetch_vizualisation_view',
  UPDATE_VIZUALISATION = 'update_vizualisation',
  INIT_VIZUALISATION = 'init_vizualisation',
  DELETE_VIZUALISATION = 'delete_vizualisation',
  VIZUALISATION_ERROR = 'vizualisation_error',
  //////////////////////////////////////////VIEW
  SET_POSITION = 'set_position',
  SET_SELECTED_NODES = 'set_selected_nodes',
  SET_SELECTED_DATES = 'set_selected_dates',
  SET_MODE = 'set_mode',
  SET_PLAY_MODE = 'set_play_mode';

//= =====================
// Page Actions
//= =====================
export const STATIC_ERROR = 'static_error';
