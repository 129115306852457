import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "../partials/containerTranslation";
import { fetchSources, deleteSource } from '../../actions/source';
import empty from '../../assets/images/empty.png';

class Source extends Component {

  constructor(props) {
    super(props);
    const { fetchSources } = this.props;
    fetchSources('personal');
    this.state = {
      type: 'personal',
    };
  }

  renderSources() {
    var _this = this
    if (this.props.sources && this.props.sources.length) {
      return (
        <div>
          {this.props.sources.map((data, i) =>
            _this.renderSource(data, i)
          )}
        </div>
      );
    }else{
      return (
        <div className="empty">
          <img className="empty-illu" src={empty} alt="empty"/>
          <p><ContainerTranslation id={"source.empty"}/> <Link to="help/create_source"><ContainerTranslation id={"source.emptyLink"}/></Link></p>
        </div>
      );
    }
  }

  handleDelete(id) {

    var result = window.confirm("Do you really want to delete this dataset ? It will remove all associated Vizualisations and Records !");
    if (result) {
      this.props.deleteSource(id)
    }

  }

  componentDidUpdate(prevProps){
    var _this = this
    if(this.props.message && this.props.message === 'Source successfully removed!') {
      this.props.fetchSources('personal');
    }
    if(this.props.type && this.props.type !== prevProps.type) {
      this.setState({
        type:_this.props.type
      })
    }
  }

  setType(type) {
    if(type !== this.state.type){
      this.props.fetchSources(type);
    }
  }

  renderSource(source, i) {
    var type = "fas fa-database"
    if(source.type === "connection"){
      type = "fas fa-plug"
    }
    if(source.type === "google"){
      type = "fab fa-google-drive"
    }
    const sourceId = source._id
    return (
      <div className="line source" key={'sourceLine'+i}>
        { source.isShared ? (
          <div style={{display:'inline-block', marginLeft:20}}>
            <div className="picto"><i className={type}></i></div>
            <div className="picto"><i className="fas fa-share-alt" aria-hidden="true"></i></div>
          </div>
        ) : (
          <div className="picto" style={{marginLeft:20}}><i className={type}></i></div>
        )}
        <p>{source.name}</p>
        { this.state.type === "personal" ? (
          <div className="tools">
            <Link className='btn hideOnMobile' style={{height: '36px', lineHeight: '36px', fontSize: '14px'}} to={{pathname: '/vizualisation/add',query: {sourceId}}}><ContainerTranslation id='vizualisation.new'/></Link>
            {this.props.profile.accountType === "Owner" ? (
              <Link to={"/source/add/"+source._id} data-for='toolsource' data-tip="source.edit"><i className="fa fa-pen" aria-hidden="true"></i></Link>
            ) : (
              null
            )}
            <a onClick={this.handleDelete.bind(this, source._id)} data-for='toolsource' data-tip="source.delete" className="Message-remove"><i className="fa fa-trash" aria-hidden="true" style={{marginLeft:"10px"}}></i></a>
            <ReactTooltip className='tooltip' id='toolsource' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
          </div>
        ) : (
        null
        )}
      </div>
    );
  }

  render() {

    var limitationStyle = {}
    if(this.props.profile && this.props.profile.limitations && this.props.sources.length >= this.props.profile.limitations.sources){
      limitationStyle = {backgroundColor:'#c92b2b'}
    }
    var limitationTextStyle = {margin:0}
    if(this.props.profile && this.props.profile.limitations && this.props.sources.length >= this.props.profile.limitations.sources){
      limitationTextStyle = {color:'#ffffff',margin:0}
    }

    return (
      <div className="page-container">
        { this.props.profile && this.props.profile.limitations && this.props.sources.length < this.props.profile.limitations.sources && this.state.type === "personal" ? (
          <Link className="add-btn btn" to="/source/add" data-for='new' data-tip="source.new"><i className="fas fa-plus"></i></Link>
        ) : (
          <Link className="add-btn btn" style={{opacity:0.3, cursor:"not-allowed"}} to="" data-for='new' data-tip="source.newDisabled"><i className="fas fa-plus"></i></Link>
        )}
        <h1 className="page-title"><ContainerTranslation id={"source.title"}/></h1>
        { this.props.profile && this.props.profile.limitations && this.state.type === "personal" ? (
          <div className="limitation-box" style={limitationStyle}><p style={limitationTextStyle}><span style={{fontWeight:'bold'}}>{this.props.sources.length}</span>/{this.props.profile.limitations.sources}</p></div>
        ) : (
          null
        )}
        <div className="tabs" style={{}}>
          <a className={(this.state.type === 'personal' ? 'tab active' : 'tab')} onClick={ (e) => this.setType('personal') }>
            <ContainerTranslation id={"vizualisation.tabPersonal"}/>
          </a>
          <a className={(this.state.type === 'shared' ? 'tab active' : 'tab')} onClick={ (e) => this.setType('shared') }>
            <ContainerTranslation id={"vizualisation.tabShared"}/>
          </a>
        </div>
        <div className="container" style={{marginTop: 0, marginLeft: '36px'}}>
          {this.renderSources()}
        </div>
        <ReactTooltip className='tooltip' id='new' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    type: state.source.type,
    sources: state.source.sources,
    profile: state.user.profile,
    message: state.source.message,
  };
}

export default connect(mapStateToProps, {fetchSources, deleteSource})(Source);
