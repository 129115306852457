import React, { Component } from 'react';
import InvoiceItem from './invoice-item';

const moment = require('moment');

class InvoiceList extends Component {
  render() {
    return (
      <div className="fullpage-list">
        {this.props.invoices.map((data, i) => <InvoiceItem
          amount={data.amount_paid}
          number={data.number}
          id={data.id}
          pdf={data.invoice_pdf}
          date={data.date}
          currency={data.currency}
          key={"invoice"+i}
        />)}
      </div>
    );
  }
}

export default InvoiceList;
