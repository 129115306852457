import { getData, postData, putData, deleteData } from './index';
import { CREATE_SOURCE, FETCH_SOURCES, FETCH_SOURCE, FETCH_SOURCE_NODES, UPDATE_SOURCE, DELETE_SOURCE, SOURCE_ERROR, INIT_SOURCE } from './types';

//= ===============================
// Source actions
//= ===============================

export function createSource(source) {
  const data = source;
  const url = '/source';
  return dispatch => postData(CREATE_SOURCE, SOURCE_ERROR, true, url, dispatch, data);
}

export function fetchSources(type) {
  const url = '/source/type/'+type;
  return dispatch => getData(FETCH_SOURCES, SOURCE_ERROR, true, url, dispatch);
}

export function fetchSource(sourceId) {
  const url = '/source/'+sourceId;
  return dispatch => getData(FETCH_SOURCE, SOURCE_ERROR, true, url, dispatch);
}

export function fetchSourceNodes(sourceId) {
  const url = '/source/'+sourceId+'/nodes';
  return dispatch => getData(FETCH_SOURCE_NODES, SOURCE_ERROR, true, url, dispatch);
}

export function updateSource(source, sourceId) {
  const url = '/source/'+sourceId
  let data = source
  return dispatch => putData(UPDATE_SOURCE, SOURCE_ERROR, true, url, dispatch, data);
}

export function initSource() {
  return dispatch =>   dispatch({
    type: INIT_SOURCE,
    payload: {
      message:''
    },
  });
}

export function deleteSource(source) {
  const url = '/source/'+source;
  return dispatch => deleteData(DELETE_SOURCE, SOURCE_ERROR, true, url, dispatch);
}
