import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "../../partials/containerTranslation";
import Select from 'react-select'
import _ from 'lodash'
import { SketchPicker } from 'react-color';

class SourceAddCategories extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
  }

  toggleSelected(e) {
    var categories = this.props.categories
    categories[e.currentTarget.id].selected = !categories[e.currentTarget.id].selected
    this.props.setNewState({
      categories:categories,
    })
  }

  checkAll(e) {
    var categories = this.props.categories
    _.each(categories, function(c){
      c.selected = true
    })
    this.props.setNewState({
      categories:categories,
    })
  }

  uncheckAll(e) {
    var categories = this.props.categories
    _.each(categories, function(c){
      c.selected = false
    })
    this.props.setNewState({
      categories:categories,
    })
  }

  toggleOpen(e,i) {
    var categories = this.props.categories
    categories[i].open = !categories[i].open
    this.props.setNewState({
      categories:categories,
    })
  }

  handleColorClick(e) {
    var categories = this.props.categories
    categories[e.currentTarget.id].displayColorPicker = true
    this.props.setNewState({
      categories:categories,
    })
  }

  handleColorClose(e) {
    var categories = this.props.categories
    categories[e.currentTarget.id].displayColorPicker = false
    this.props.setNewState({
      categories:categories,
    })
  }

  handleColorChange(color,i) {
    var categories = this.props.categories
    categories[i].color = color.rgb
    this.props.setNewState({
      categories:categories,
    })
  }

  handleTypeChange(e,i) {
    var categories = this.props.categories
    categories[i].type = e
    this.props.setNewState({
      categories:categories,
    })
  }

  handleSeparatorChange(e,i) {
    var categories = this.props.categories
    categories[i].separator = e.currentTarget.value
    this.props.setNewState({
      categories:categories,
    })
  }

  renderCategory(category, i) {
    var categoryClass = "category"

    var color = `rgba(${ category.color.r }, ${ category.color.g }, ${ category.color.b }, ${ category.color.a })`
    if(!category.selected){
      color = `rgba(0,0,0,0.1)`
      categoryClass+=' disabled'
    }

    var title = category.title
    if(!title || title === ""){
      title = "(Undefined)"
    }

    const styles = {
      color: {
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        display: 'inline-block',
        cursor: 'pointer',
        background: color,
        verticalAlign: 'top',
        marginTop: '6px',
        marginLeft: '5px',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    var typeOptions = [
      {
        value:"text",
        label:"Text"
      },{
        value:"number",
        label:"Number"
      },{
        value:"country",
        label:"Countries"
      },{
        value:"city",
        label:"Cities"
      },{
        value:"lat",
        label:"Latitude"
      },{
        value:"long",
        label:"Longitude"
      },{
        value:"date",
        label:"Dates"
      }
    ]

    var key = new Date()
    key = key.getTime()

    return (
      <div className={categoryClass} key={key+"-cat-"+i}>
        {category.selected ? (
          <div onClick={ (e) => this.toggleSelected(e) } key={key} id={i} style={{display: 'inline-block', verticalAlign: 'top', marginTop: '8px', fontSize: '24px', cursor:'pointer', marginRight:'10px'}}>
            <i className="fas fa-check-circle" style={{color:'#00e3cc'}}></i>
          </div>
        ) : (
          <div onClick={ (e) => this.toggleSelected(e) } key={key} id={i} style={{display: 'inline-block', verticalAlign: 'top', marginTop: '8px', fontSize: '24px', cursor:'pointer', marginRight:'10px'}}>
            <i className="far fa-circle" style={{color:'#00e3cc'}}></i>
          </div>
        )}
        <p className='category-title'>{title}</p>
        {/*<a className='right' style={{fontSize:'20px'}} onClick={ (e) => this.toggleOpen(e, i) }><i className="fas fa-cog"></i></a>*/}
        <div className='right' style={{paddingRight:0}}>
          <p><ContainerTranslation id={"source.color"}/> :</p>
          <div style={{display:'inline-block', verticalAlign:"top"}}>
            <div style={ styles.color } onClick={ (e) => this.handleColorClick(e) } id={i}></div>
            { category.displayColorPicker ? <div style={ styles.popover }>
              <div style={ styles.cover } onClick={ (e) => this.handleColorClose(e) } id={i}/>
              <SketchPicker disableAlpha={true} color={ category.color } onChange={ (color, e) => this.handleColorChange(color,i) } id={i} />
            </div> : null }

          </div>
        </div>
        {category.open && category.selected ? (
          <div style={{display:'block',width:'100%',borderTop: '1px solid #d7e6e9'}}>
            <div style={{display:'inline-block',width:'50%',borderRight: '1px solid #d7e6e9'}}>
              <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', marginRight: '5px', width: '110px'}}><ContainerTranslation id={"source.type"}/></p>
              <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop: '3px'}}>
                <Select
                  className="Select"
                  value={category.type}
                  clearable={false}
                  searchable={false}
                  options={typeOptions}
                  multi={false}
                  onChange={ (e) => this.handleTypeChange(e, i) }
                />
              </div>
            </div>
            <div style={{display:'inline-block',width: 'calc(50% - 1px)', verticalAlign: 'top'}}>
              <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', marginRight: '5px', marginLeft: '20px', width: '90px'}}><ContainerTranslation id={"source.separator"}/></p>
              <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop: '5px'}}>
                <input type="text" value={category.separator} placeholder="No separator..." onChange={ (e) => this.handleSeparatorChange(e, i) }/>
              </div>
            </div>
          </div>
        ) : (
          null
        )}
      </div>
    );
  }

  renderCategories() {
    var _this = this

    return (
      <div style={{marginTop:'20px'}}>
        {this.props.categories.map((data, i) =>
          _this.renderCategory(data, i)
        )}
      </div>
    );
  }

  render() {
      console.log(this.props.records)
    return (
      <div className="container container-add" style={{display: 'inline-block', marginLeft: '100px', width: 'calc(100% - 300px)', textAlign:'left', marginTop:0, paddingTop:0}}>
        <p style={{color:'#fff', fontWeight:'700',textAlign: 'left', width: '60%', marginTop:50}}><ContainerTranslation id={"source.categoriesTitle"}/></p>
        <p style={{color:'#fff', marginTop:'20px',textAlign: 'left', width: '60%', fontWeight: 300, marginBottom:'50px'}}><ContainerTranslation id={"source.categoriesDescription"}/></p>
        <a onClick={ (e) => this.checkAll(e) }><ContainerTranslation id={"source.checkAll"}/></a><p style={{color:'#fff', display:'inline-block', marginLeft:'5px',marginRight:'5px'}}> / </p><a onClick={ (e) => this.uncheckAll(e) }><ContainerTranslation id={"source.uncheckAll"}/></a>
        {this.renderCategories()}
        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(SourceAddCategories);
