import { getData, postData, putData, deleteData } from './index';
import {
  CREATE_VIZUALISATION,
  FETCH_VIZUALISATIONS,
  FETCH_VIZUALISATION,
  FETCH_KEYWORDS,
  FETCH_VIZUALISATION_VIEW,
  UPDATE_VIZUALISATION,
  DELETE_VIZUALISATION,
  VIZUALISATION_ERROR,
  INIT_VIZUALISATION,
  /////////////////////////////////VIEW
  SET_POSITION,
  SET_SELECTED_NODES,
  SET_SELECTED_DATES,
  SET_MODE,
  SET_PLAY_MODE
} from './types';

//= ===============================
// Vizualisation actions
//= ===============================

export function createVizualisation(vizualisation) {
  const url = '/vizualisation';
  var formObj  = new FormData();
  formObj.append('categories', JSON.stringify(vizualisation.categories));
  formObj.append('contributors', JSON.stringify(vizualisation.contributors));
  formObj.append('description', vizualisation.description);
  formObj.append('keywords', JSON.stringify(vizualisation.keywords));
  formObj.append('isPublic', vizualisation.isPublic);
  formObj.append('name', vizualisation.name);
  formObj.append('source', vizualisation.source);
  formObj.append('type', vizualisation.type);
  if(vizualisation.file){
    formObj.append('file',vizualisation.file)
  }
  return dispatch => postData(CREATE_VIZUALISATION, VIZUALISATION_ERROR, true, url, dispatch, formObj);
}

export function fetchVizualisations(type) {
  const url = '/vizualisation/type/'+type;
  return dispatch => getData(FETCH_VIZUALISATIONS, VIZUALISATION_ERROR, true, url, dispatch);
}

export function fetchVizualisation(vizualisationId) {
  const url = '/vizualisation/'+vizualisationId;
  return dispatch => getData(FETCH_VIZUALISATION, VIZUALISATION_ERROR, true, url, dispatch);
}

export function fetchKeywords() {
  const url = '/vizualisation/keywords';
  return dispatch => getData(FETCH_KEYWORDS, VIZUALISATION_ERROR, true, url, dispatch);
}

export function fetchVizualisationView(vizualisationId, isPublic) {
  let url = ''
  if(isPublic === true){
    url = '/vizualisation/view/public/'+vizualisationId;
  }else{
    url = '/vizualisation/view/'+vizualisationId;
  }
  return dispatch => getData(FETCH_VIZUALISATION_VIEW, VIZUALISATION_ERROR, true, url, dispatch);
}

export function updateVizualisation(vizualisation, vizualisationId) {
  const url = '/vizualisation/'+vizualisationId
  var formObj  = new FormData();
  formObj.append('categories', JSON.stringify(vizualisation.categories));
  formObj.append('contributors', JSON.stringify(vizualisation.contributors));
  formObj.append('description', vizualisation.description);
  formObj.append('keywords', JSON.stringify(vizualisation.keywords));
  formObj.append('isPublic', vizualisation.isPublic);
  formObj.append('name', vizualisation.name);
  formObj.append('source', vizualisation.source);
  formObj.append('type', vizualisation.type);
  if(vizualisation.file){
    formObj.append('file',vizualisation.file)
  }
  return dispatch => putData(UPDATE_VIZUALISATION, VIZUALISATION_ERROR, true, url, dispatch, formObj);
}

export function deleteVizualisation(vizualisation) {
  const url = '/vizualisation/'+vizualisation;
  return dispatch => deleteData(DELETE_VIZUALISATION, VIZUALISATION_ERROR, true, url, dispatch);
}

export function initVizualisation() {
  return dispatch => dispatch({
    type: INIT_VIZUALISATION,
    payload: {
      message:''
    },
  });
}

export function setMode(mode) {
  return dispatch => dispatch({
    type: SET_MODE,
    payload: mode,
  });
}

export function setPlayMode(mode) {
  return dispatch => dispatch({
    type: SET_PLAY_MODE,
    payload: mode,
  });
}

export function setPosition(position) {
  return dispatch => dispatch({
    type: SET_POSITION,
    payload: position,
  });
}

export function setSelectedNodes(nodes) {
  return dispatch => dispatch({
    type: SET_SELECTED_NODES,
    payload: nodes,
  });
}

export function setSelectedDates(dates) {
  return dispatch => dispatch({
    type: SET_SELECTED_DATES,
    payload: dates,
  });
}
