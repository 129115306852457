import { FETCH_PROFILE, FETCH_USER, FETCH_USERS, UPDATE_USER, DELETE_USER, USER_ERROR } from '../actions/types';

const INITIAL_STATE = { profile: {}, user: {}, users: {}, message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROFILE:
      return { ...state, profile: action.payload, message:'', error: '' };
    case FETCH_USER:
      return { ...state, user: action.payload, message:'', error: '' };
    case FETCH_USERS:
      return { ...state, users: action.payload.users, message:'', error: '' };
    case UPDATE_USER:
      return { ...state, message: action.payload.message, error: '' };
    case DELETE_USER:
      return { ...state, message: action.payload.message, error: '' };
    case USER_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
