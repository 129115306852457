import React, { Component } from 'react';

class VizualisationViewCategories extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
    this.state = {};
  }

  selectCategory(e,value) {
    this.props.selectCategory(value.title);
  }

  render() {
    var _this = this
    var classCategories = "page-vizualisation-categories"
    var styleContainer = {}
    var styleCategory = {}
    var right = 20
    if(this.props.displayComments === true){
      right = 320
    }

    if(this.props.displayComments === true){
      classCategories += " withComments"
    }

    if(this.props.mode === 'group'){
      var decalage = 200/this.props.categories.length
      styleContainer = {
        alignItems: 'baseline',
      }
      styleCategory = {
        width: 'calc(100% / '+this.props.categories.length+' - '+decalage+'px)',
        justifyContent: 'center',
        alignItems: 'baseline'
      }
    }

    if(this.props.mode === 'network' || this.props.mode === 'geography'){
      styleContainer = {
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        right:right+8,
        left:'initial'
      }
      styleCategory = {
        flex: 'initial',
      }
    }

    if(window.innerWidth < 790 && window.innerWidth<=window.innerHeight){
      styleContainer = {
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        right:right,
        left:'initial'
      }
      styleCategory = {
        justifyContent: 'center',
        alignItems: 'center'
      }
    }

    return (
      <div className={classCategories} style={styleContainer}>
        {this.props.categories.map((category, i) =>
          <div className="category" style={styleCategory} key={"category"+i}>
            <a className={this.props.hiddenCategories.indexOf(category.title) === -1 ? "" : "disabled"} onClick={ (e) => this.selectCategory(e, category) } style={{backgroundColor:'rgba('+category.color.r+','+category.color.g+','+category.color.b+',0.7)'}}>{category.title}</a>
          </div>
        )}
      </div>
    );

  }
}

export default VizualisationViewCategories;
