import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContainerTranslation from "./../containerTranslation";

class Message extends Component {

  close(e) {
    this.props.close()
  }

  render() {
    var customClass = "message "+this.props.type
    var customStyle = this.props.style

    return (
      <div className={customClass} style={customStyle}>
        {this.props.close ? (
          <span onClick={ (e) => this.close(e) } style={{float: 'right', fontSize: '18px', marginRight: '-20px', marginTop: '-2px', cursor: 'pointer'}}>
            <i className="fas fa-times-circle"></i>
          </span>
        ) : (
          null
        )}
        <span style={{fontWeight:'bold', display:'block'}}><ContainerTranslation id={this.props.message}/></span>
        {this.props.link && this.props.linkMessage ? (
          <Link to={this.props.link}><ContainerTranslation id={this.props.linkMessage}/></Link>
        ) : (
          null
        )}
        {this.props.href && this.props.linkMessage ? (
          <a href={this.props.href} target="_blank"><ContainerTranslation id={this.props.linkMessage}/></a>
        ) : (
          null
        )}
      </div>
    );

  }
}

export default Message;
