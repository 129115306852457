import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ContainerTranslation from "../partials/containerTranslation";

class FooterTemplate extends Component {

  render() {
    const d = new Date();
    const year = d.getFullYear();

    var classFooter = ""
    if(this.props.location.startsWith("/source/add") || this.props.location.startsWith('/vizualisation/add') || this.props.location.startsWith("/login") || this.props.location.startsWith("/forgot-password") || this.props.location.startsWith("/register") || this.props.location.startsWith("/logout")  || this.props.location.startsWith("/reset-password")){
      classFooter+="dark"
    }

    return (
      <div>
        {!this.props.location.startsWith('/help') ? (
          null
        ) : (
          <footer className={classFooter}>
            <p className="copyright">© {year} <ContainerTranslation id={"footer.rights"}/> - Made with <i className="fa fa-heart" aria-hidden="true"></i> by <a href="http://www.bakasable.fr" target="_blank"><ContainerTranslation id={"footer.bakasable"}/></a> - <ContainerTranslation id={"footer.rcs"}/> - <ContainerTranslation id={"footer.siret"}/> - <a href="https://app.bakamap.com/#/mentions" target="_blank"><ContainerTranslation id={"footer.mentions"}/></a></p>
          </footer>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, null)(FooterTemplate);
