import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "../../partials/containerTranslation";
import Message from './../../partials/Message/Message';
import Select from 'react-select'
import _ from 'lodash'
import { fetchAccount } from '../../../actions/account';
import { fetchTeams } from '../../../actions/team';
import MemberList from '../../share/member-list';

class Share extends Component {

  constructor(props) {
    super(props);
    const { params, fetchAccount, fetchTeams } = this.props;
    if(this.isRole('Owner')){
      fetchTeams();
    }
    fetchAccount()
  }

  isRole(roleToCheck) {
    const userRole = this.props.profile.accountType
    if (userRole == roleToCheck) {
      return true;
    }
    return false;
  }

  togglePublic(e) {
    var _this = this
    this.props.setNewState({
      isPublic:!_this.props.isPublic,
      shareWith:this.props.shareWith
    })
  }

  addMemberTeam(elm) {
    var _this= this
    var members = this.props.shareWith
    var foundMember = _.find(members, function(member){
      return member.value === elm.value && member.role === elm.role
    })
    if(!foundMember){
      members.push({
        value:elm.value,
        label:elm.label,
        role:'Member'
      })
    }
    this.props.setNewState({
      isPublic:_this.props.isPublic,
      shareWith:members
    })
  }

  removeMember(elm) {
    var _this= this
    var members = this.props.shareWith
    var foundMember = _.find(members, function(member){
      return member.value === elm.value && member.role === elm.role
    })
    if(foundMember){
      _.remove(members, function(member){
        return member.value === elm.value && member.role === elm.role
      })
      this.props.setNewState({
        isPublic:_this.props.isPublic,
        shareWith:members
      })
    }
  }

  renderMembers() {
    var _this = this
    if(this.props.shareWith){
      return (
        <MemberList
          members={this.props.shareWith}
          removeMember={this.removeMember.bind(this)}
        />
      )
    }
  }

  render() {
    var options = []
    if(this.props.account){
      options = _.map(this.props.account.members, function(member){return {role:'Member', value:member.email, label:member.email}})
    }
    if(this.props.teams && this.props.teams.length){
      options = options.concat(_.map(this.props.teams, function(team){return {role:'Member', value:team._id, label:team.name}}))
    }
    var key = new Date()
    key = key.getTime()

    return (
      <div className="container container-add" style={{display: 'inline-block', marginLeft: '100px', width: 'calc(100% - 200px)', textAlign:'left', marginTop:0, paddingTop:0}}>
        <p style={{color:'#fff', fontWeight:'700',textAlign: 'left', width: '60%'}}><ContainerTranslation id={"vizualisation.sharePageTitle"}/></p>
        <p style={{color:'#fff', marginTop:'20px',textAlign: 'left', width: '60%', fontWeight: 300}}><ContainerTranslation id={"vizualisation.sharePageDescription"}/></p>

        {this.isRole('Owner') ? (
          <div>
            {this.props.isPublic ? (
              <div className="line" style={{boxShadow: 'none', border: 'none', background: 'none'}} onClick={ (e) => this.togglePublic(e) } key={key}>
                <i className="fas fa-check-circle" style={{color:'#00e3cc', fontSize: '24px', cursor:'pointer', marginTop: '12px', marginLeft: '16px', verticalAlign: 'top'}}></i><p style={{color:'#fff'}}><ContainerTranslation id={"vizualisation.public"}/></p>
              </div>
            ) : (
              <div className="line disabled" style={{boxShadow: 'none', border: 'none', background: 'none'}} onClick={ (e) => this.togglePublic(e) } key={key}>
                <i className="far fa-circle" style={{color:'#00e3cc', fontSize: '24px', cursor:'pointer', marginTop: '12px', marginLeft: '16px', verticalAlign: 'top'}}></i><p style={{color:'#fff'}}><ContainerTranslation id={"vizualisation.public"}/></p>
              </div>
            )}
            {this.renderMembers()}
            <div style={{
              display: 'inline-block',
              verticalAlign: 'top',
              marginTop: '16px',
              marginRight: '10px',
              marginBottom:'50px',
              width: '100%'
            }}>
              <Select
                className="Select"
                isClearable
                isSearchable
                options={options}
                multi={false}
                placeholder={'Select your member or team to add ...'}
                onChange={ (e) => this.addMemberTeam(e) }
              />
            </div>
          </div>
        ) : (
          <Message
            icon="fas fa-coffee"
            type=""
            message="global.updatePlanShare"
            link="/profile"
            linkMessage="global.updatePlanShareLink"
          />
        )}

        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    teams: state.team.teams,
    account: state.account.account,
    profile: state.user.profile
  };
}

export default connect(mapStateToProps, {fetchAccount, fetchTeams})(Share);
