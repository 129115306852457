import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAccount } from '../../actions/account';
import { Link } from 'react-router-dom';
import CheckoutForm from './CheckoutForm';
import InvoiceList from './invoice-list';
import {Elements, StripeProvider} from 'react-stripe-elements';
import ContainerTranslation from "../partials/containerTranslation";
import {API_URL, STRIPE_API} from './../../actions/index'

class Account extends Component {

  constructor(props) {
    super(props);
    this.state = {
      displayStripe: false,
      displayStripeUpdate: false,
      complete: false,
      updateComplete: false
    };
  }

  componentDidMount() {
    this.props.fetchAccount();
  }

  displayStripe(e) {
    if(this.props.account.type !== "Owner"){
      this.setState({
        displayStripe:!this.state.displayStripe
      })
    }
  }

  displayStripeUpdate(e) {
    if(this.props.account.type !== "Member"){
      this.setState({
        displayStripeUpdate:!this.state.displayStripeUpdate
      })
    }
  }

  async cancelSubscription() {
    if(this.props.account.type !== "Member"){
      let url = API_URL+"/account/customer/cancel"
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
           Authorization: localStorage.getItem("token")
        }
      });
      if (response.ok){
        this.setState({displayStripe: false})
        this.props.fetchAccount();
        window.location.reload();
      }
    }
  }

  async submitSubscription(token) {
    let url = API_URL+"/account/customer"
    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
         Authorization: localStorage.getItem("token")
      },
      body: token.id
    });

    if (response.ok){
      this.setState({complete: true,displayStripe: false})
      this.props.fetchAccount();
      window.location.reload();
    }
  }

  async updateCard(token) {
    let url = API_URL+"/account/customer/card"
    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
         Authorization: localStorage.getItem("token")
      },
      body: token.id
    });

    if (response.ok){
      this.setState({updateComplete: true,displayStripeUpdate: false})
      this.props.fetchAccount();
    }
  }

  renderInvoices() {
    if (this.props.account && this.props.account.invoices) {
      return (
        <InvoiceList invoices={this.props.account.invoices} />
      );
    }
  }

  render() {
    if(this.props.account && this.props.account.type){
      let freeClass = "tileAccount"
      if(this.props.account.type === "Member"){
        freeClass += " active"
      }

      let proClass = "tileAccount"
      if(this.props.account.type === "Owner"){
        proClass += " active"
      }

      if(this.props.account.customer && this.props.account.customer.sources && this.props.account.customer.sources.data && this.props.account.customer.sources.data[0]){
        var displayDate = ""
        var month = "0" + this.props.account.customer.sources.data[0].exp_month;
        month = month.substr(-2)
        var year = this.props.account.customer.sources.data[0].exp_year;
        displayDate = month+"/"+year
      }

      return (
        <div style={{marginTop:'10px'}}>
          <div>
            <a className={freeClass} onClick={ (e) => this.cancelSubscription(e) }>
              <div className="tile-wrapper">
                <div className="tile-content">
                  <h3 style={{fontWeight: 'bold', fontSize:'14px', marginTop:0, marginBottom:0}}>Free</h3>
                  <p style={{display: 'block'}}>1 source</p>
                  <p style={{display: 'block'}}>1 000 records</p>
                  <p style={{display: 'block'}}>10 maps</p>
                  <p style={{display: 'block'}}>1 user</p>
                  <p style={{display: 'block'}}>-</p>
                  <h3 style={{fontWeight: 'bold', fontSize:'18px', marginTop:'20px', marginBottom:0}}>0 €</h3>
                </div>
              </div>
            </a>
            <a className={proClass} onClick={ (e) => this.displayStripe(e) }>
              <div className="tile-wrapper">
                <div className="tile-content">
                  <h3 style={{fontWeight: 'bold', fontSize:'14px', marginTop:0, marginBottom:0}}>Pro</h3>
                  <p style={{display: 'block'}}>10 sources</p>
                  <p style={{display: 'block'}}>50 000 records</p>
                  <p style={{display: 'block'}}>100 maps</p>
                  <p style={{display: 'block'}}>1 user and 10 members</p>
                  <p style={{display: 'block'}}>Geography and more !</p>
                  <h3 style={{fontWeight: 'bold', fontSize:'18px', marginTop:'20px', marginBottom:0}}>79 € HT / Month</h3>
                </div>
              </div>
            </a>
            <a style={{width: 'calc(33% - 10px)', display: 'inline-block', textAlign: 'center'}}>
              <h3 style={{fontWeight: 'bold', fontSize:'18px', marginBottom:0, marginTop: '60px'}}>Need More ?</h3>
              <p style={{marginTop:0, marginBottom: '60px'}}><i className="fas fa-child" style={{marginRight:'5px'}}></i>Contact Us !</p>
            </a>
          </div>
          {this.state.complete ? (
            <div className="message confirm" style={{marginTop: '20px'}}>Purchase Complete !</div>
          ) : (
            null
          )}
          {this.state.displayStripe ? (
            <StripeProvider apiKey={STRIPE_API}>
              <div style={{marginTop:'20px'}}>
                <Elements>
                  <CheckoutForm
                    account = {this.props.account}
                    submitSubscription={this.submitSubscription.bind(this)}
                  />
                </Elements>
              </div>
            </StripeProvider>
          ) : (
            null
          )}
          {this.props.account && this.props.account.customer && this.props.account.customer.sources && this.props.account.customer.sources.data && this.props.account.customer.sources.data[0] && this.props.account.type === 'Owner' ? (
            <div style={{marginTop:'20px'}}>
              <h1 className="page-title"><ContainerTranslation id={"profile.card"}/></h1>

              <div className="container" style={{marginTop: 10, marginLeft: 0}}>
                <div className="line">
                  <a className="lineContent">
                    <p style={{marginRight:'20px'}}><i className="far fa-credit-card"></i><span style={{fontWeight:'bold'}}>{this.props.account.customer.sources.data[0].brand}</span> •••• {this.props.account.customer.sources.data[0].last4}</p>
                    <p style={{marginRight:'20px'}}>{displayDate}</p>
                  </a>
                  <div className="tools">
                    <a
                      className="btn btn-submit"
                      style={{textAlign:'center', height: '36px', lineHeight: '36px', fontSize: '14px', marginTop: '6px'}}
                      onClick={ (e) => this.displayStripeUpdate(e) }
                    >
                      Update Card
                    </a>
                  </div>
                </div>
              </div>


              {this.state.updateComplete ? (
                <div className="message confirm" style={{marginTop: '20px'}}>Card successfuly changed !</div>
              ) : (
                null
              )}
              {this.state.displayStripeUpdate ? (
                <StripeProvider apiKey={STRIPE_API}>
                  <div style={{marginTop:'20px'}}>
                    <Elements>
                      <CheckoutForm
                        account = {this.props.account}
                        submitSubscription={this.updateCard.bind(this)}
                      />
                    </Elements>
                  </div>
                </StripeProvider>
              ) : (
                null
              )}
            </div>
          ) : (
            null
          )}
          <div style={{marginTop:'20px'}}>
            <h1 className="page-title"><ContainerTranslation id={"profile.billing"}/></h1>
            <div className="container" style={{marginTop: 10, marginLeft: 0}}>
              {this.renderInvoices()}
            </div>
          </div>

        </div>
      );
    }else{
      return (
        <div style={{textAlign: 'center', marginTop: '30px'}}>
          <p>Loading ...</p>
        </div>
      )
    }

  }
}

function mapStateToProps(state) {
  return {
    account: state.account.account,
  };
}

export default connect(mapStateToProps, { fetchAccount })(Account);
