import { getData, postData, putData, deleteData } from './index';
import { FETCH_TEAMS, FETCH_TEAM, CREATE_TEAM, UPDATE_TEAM, DELETE_TEAM, TEAM_ERROR } from './types';

//= ===============================
// Team actions
//= ===============================

export function createTeam(team) {
  const data = team;
  const url = '/team';
  return dispatch => postData(CREATE_TEAM, TEAM_ERROR, true, url, dispatch, data);
}

export function fetchTeams() {
  const url = '/team';
  return dispatch => getData(FETCH_TEAMS, TEAM_ERROR, true, url, dispatch);
}

export function fetchTeam(teamId) {
  const url = '/team/'+teamId;
  return dispatch => getData(FETCH_TEAM, TEAM_ERROR, true, url, dispatch);
}

export function updateTeam(team, teamId) {
  const url = '/team/'+teamId
  let data = {member:team}
  return dispatch => putData(UPDATE_TEAM, TEAM_ERROR, true, url, dispatch, data);
}

export function deleteTeam(teamId) {
  const url = '/team/'+teamId;
  return dispatch => deleteData(DELETE_TEAM, TEAM_ERROR, true, url, dispatch);
}
