import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "../../partials/containerTranslation";
import Select from 'react-select'
import _ from 'lodash'
import { SketchPicker } from 'react-color';
import { fetchSources, fetchSourceNodes } from '../../../actions/source';

class Categories extends Component {

  constructor(props) {
    super(props);
    const { fetchSources, fetchSourceNodes } = this.props;
    fetchSources('all')
    this.state = {
      sources:this.props.sources,
      limit:false
    };
  }

  componentDidMount(){
    if(this.props.source){
      if(this.props.profile.limitations.functionalities.indexOf("focus") !== -1){
        this.props.fetchSourceNodes(this.props.source)
      }
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.sources) {
      if(!prevProps.sources || _.difference(_.map(this.props.sources, '_id'), _.map(prevProps.sources, '_id')).length){
        this.setState({
          sources:this.props.sources
        })
      }
    }
    if(this.props.source && !prevProps.source || this.props.source !== prevProps.source){
      if(this.props.profile.limitations && this.props.profile.limitations.functionalities.indexOf("focus") !== -1){
        this.props.fetchSourceNodes(this.props.source)
      }
    }
  }

  handleSourceChange(data) {
    this.props.setNewSourceState({
      source:data.value,
    })
  }

  toggleSelected(e) {
    var categories = this.props.categories
    categories[e.currentTarget.id].selected = !categories[e.currentTarget.id].selected
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  checkAll(e) {
    var categories = this.props.categories
    _.each(categories, function(c){
      c.selected = true
    })
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  uncheckAll(e) {
    var categories = this.props.categories
    _.each(categories, function(c){
      c.selected = false
    })
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  toggleOpen(e,i) {
    var categories = this.props.categories
    if(this.props.profile.limitations.functionalities.length > 0){
      categories[i].open = !categories[i].open
      this.props.setNewCategoriesState({
        categories:categories
      })
    }else{
      this.setState({
        limit:true
      })
    }
  }

  handleColorClick(e) {
    var categories = this.props.categories
    categories[e.currentTarget.id].displayColorPicker = true
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  handleColorClose(e) {
    var categories = this.props.categories
    categories[e.currentTarget.id].displayColorPicker = false
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  handleColorChange(color,i) {
    var categories = this.props.categories
    categories[i].color = color.rgb
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  handleTypeChange(e,i) {
    var categories = this.props.categories
    categories[i].type = e
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  handleSeparatorChange(e,i) {
    var categories = this.props.categories
    categories[i].separator = e.currentTarget.value
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  handleDateFormatChange(e,i) {
    var categories = this.props.categories
    categories[i].dateFormat = e.currentTarget.value
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  handleLinkedToChange(e,i) {
    var categories = this.props.categories
    categories[i].linkedTo = _.map(e, "value")
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  handleSizedByChange(e,i) {
    var categories = this.props.categories
    categories[i].sizedBy = e
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  handleFocusOnChange(e,i) {
    var categories = this.props.categories
    categories[i].focusOn = _.map(e, "value")
    this.props.setNewCategoriesState({
      categories:categories
    })
  }

  renderCategory(category, i) {
    var categoryClass = "category"

    var color = `rgba(${ category.color.r }, ${ category.color.g }, ${ category.color.b }, ${ category.color.a })`
    if(!category.selected){
      color = `rgba(0,0,0,0.1)`
      categoryClass+=' disabled'
    }

    const styles = {
      color: {
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        display: 'inline-block',
        cursor: 'pointer',
        background: color,
        verticalAlign: 'top',
        marginTop: '6px',
        marginLeft: '5px',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    var typeOptions = [
      {
        value:"text",
        label:"Text"
      },{
        value:"number",
        label:"Number"
      }
    ]

    if(this.props.profile.limitations && this.props.profile.limitations.functionalities.indexOf("timeline") !== -1){
      typeOptions.push({
        value:"date",
        label:"Dates"
      })
    }

    if(this.props.profile.limitations && this.props.profile.limitations.functionalities.indexOf("geography") !== -1){
      typeOptions.push({
        value:"country",
        label:"Countries"
      })
    }

    var title = category.title
    if(!title || title === ""){
      title = "(Undefined)"
    }

    var dateFormat = 'YYYY-MM-DD'
    if(category.dateFormat){
      dateFormat = category.dateFormat
    }

    var linkedToOptions = _.compact(_.map(_.filter(this.props.categories, {'selected':true}), function(cat){
      if(cat.type.value !== "date" && cat.title !== category.title){
        return {
          value:cat.title,
          label:cat.title
        }
      }
    }))

    var linkedToValue = _.compact(_.map(_.filter(this.props.categories, {'selected':true}), function(cat){
      if(cat.type.value !== "date" && cat.title !== category.title){
        return {
          value:cat.title,
          label:cat.title
        }
      }
    }))

    if(category.linkedTo){
      linkedToValue = _.map(category.linkedTo, function(link){
        return {
          value:link,
          label:link
        }
      })
    }

    var sizedByOptions = _.compact(_.map(this.props.categories, function(cat){
      if(cat.type.value === "number" && cat.title !== category.title){
        return {
          value:cat.title,
          label:cat.title
        }
      }
    }))

    sizedByOptions = [{value:'frequency', label:'Frequence'}].concat(sizedByOptions)

    var valueSizedBy = category.sizedBy
    if(!valueSizedBy){
      valueSizedBy = {value:'frequency', label:'Frequence'}
    }else{
      if(!valueSizedBy.value){
        valueSizedBy = {value:valueSizedBy, label:valueSizedBy}
      }
    }

    var focusOnOptions = _.compact(_.map(this.props.nodes[category.title], function(n){
      return {
        value:n,
        label:n
      }
    }))

    if(category.separator){
      focusOnOptions = _.map(focusOnOptions, function(o){
        return o.value.split(category.separator)
      })
      focusOnOptions = _.uniq(_.flatten(focusOnOptions))
      focusOnOptions = _.map(focusOnOptions, function(o){
        return {
          value:o,
          label:o
        }
      })
    }

    var focusOnValue = []

    if(category.focusOn){
      focusOnValue = _.map(category.focusOn, function(link){
        return {
          value:link,
          label:link
        }
      })
    }

    var valueSeparator = category.separator
    if(!valueSeparator){
      valueSeparator = ""
    }

    var key = new Date()
    key = key.getTime()

    return (
      <div className={categoryClass} key={'category-'+i}>
        {category.selected ? (
          <div onClick={ (e) => this.toggleSelected(e) } key={key} id={i} style={{display: 'inline-block', verticalAlign: 'top', marginTop: '8px', fontSize: '24px', cursor:'pointer', marginRight:'10px'}}>
            <i className="fas fa-check-circle" style={{color:'#00e3cc'}}></i>
          </div>
        ) : (
          <div onClick={ (e) => this.toggleSelected(e) } key={key} id={i} style={{display: 'inline-block', verticalAlign: 'top', marginTop: '8px', fontSize: '24px', cursor:'pointer', marginRight:'10px'}}>
            <i className="far fa-circle" style={{color:'#00e3cc'}}></i>
          </div>
        )}
        <p className='category-title'>{title}</p>
        <a className='right' style={{fontSize:'20px'}} onClick={ (e) => this.toggleOpen(e, i) }><i className="fas fa-cog"></i></a>
        <div className='right' style={{display:'inline-block'}}>
          <p><ContainerTranslation id={"source.color"}/> :</p>
          <div style={{display:'inline-block', verticalAlign:'top'}}>
            <div style={ styles.color } onClick={ (e) => this.handleColorClick(e) } id={i}></div>
            { category.displayColorPicker ? <div style={ styles.popover }>
              <div style={ styles.cover } onClick={ (e) => this.handleColorClose(e) } id={i}/>
              <SketchPicker disableAlpha={true} color={ category.color } onChange={ (color, e) => this.handleColorChange(color,i) } id={i} />
            </div> : null }

          </div>
        </div>
        {category.open && category.selected ? (
          <div style={{display:'block',width:'100%', paddingTop: '5px', paddingBottom: '5px'}}>

            {/*TYPE*/}
            <div>
              <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', width: '120px'}}><ContainerTranslation id={"source.type"}/></p>
              <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop: '3px'}}>
                <Select
                  className="Select no-z-index"
                  value={category.type}
                  clearable={false}
                  searchable={false}
                  options={typeOptions}
                  multi={false}
                  onChange={ (e) => this.handleTypeChange(e, i) }
                />
              </div>
            </div>

            {/*IF DATE*/}
            {category.open && category.selected && category.type.value === "date" ? (
              <div>
                <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', width: '120px'}}><ContainerTranslation id={"source.dateFormat"}/></p>
                <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop: '5px'}}>
                  <input type="text" value={dateFormat} placeholder="YYYY-MM-DD..." onChange={ (e) => this.handleDateFormatChange(e, i) }/>
                </div>
              </div>
            ) : (
              null
            )}

            {/*SEPARATOR*/}
            <div>
              <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', width: '120px'}}><ContainerTranslation id={"source.separator"}/></p>
              <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop: '5px'}}>
                <input type="text" value={valueSeparator} placeholder="No separator..." onChange={ (e) => this.handleSeparatorChange(e, i) }/>
              </div>
            </div>

            {/*LINKED TO*/}
            {category.type.value !== "date" && this.props.profile.limitations.functionalities.indexOf("customlinks") !== -1 ? (
              <div>
                <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', width: '120px'}}><ContainerTranslation id={"source.linkedTo"}/></p>
                <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop: '5px'}}>
                  <Select
                    className="Select no-z-index"
                    value={linkedToValue}
                    placeholder="Linked to..."
                    options={linkedToOptions}
                    isMulti
                    isSearchable
                    onChange={ (e) => this.handleLinkedToChange(e, i) }
                  />
                </div>
              </div>
            ) : (
              null
            )}

            {/*SIZED BY*/}
            {category.type.value !== "date" && this.props.profile.limitations.functionalities.indexOf("customsizes") !== -1 ? (
              <div>
                <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', width: '120px'}}><ContainerTranslation id={"source.sizedBy"}/></p>
                <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop: '5px'}}>
                  <Select
                    className="Select no-z-index"
                    value={valueSizedBy}
                    placeholder="Sized by..."
                    options={sizedByOptions}
                    isSearchable
                    onChange={ (e) => this.handleSizedByChange(e, i) }
                  />
                </div>
              </div>
            ) : (
              null
            )}

            {/*FOCUS ON*/}
            {category.type.value !== "date" && this.props.profile.limitations.functionalities.indexOf("focus") !== -1 ? (
              <div>
                <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', width: '120px'}}><ContainerTranslation id={"source.focusOn"}/></p>
                <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop: '5px'}}>
                  <Select
                    className="Select no-z-index"
                    value={focusOnValue}
                    placeholder="Filtered on..."
                    options={focusOnOptions}
                    isMulti
                    isSearchable
                    onChange={ (e) => this.handleFocusOnChange(e, i) }
                  />
                </div>
              </div>
            ) : (
              null
            )}

          </div>
        ) : (
          null
        )}
      </div>
    );
  }

  renderCategories() {
    var _this = this
    return (
      <div style={{marginBottom:'50px'}}>
        {this.props.categories.map((data, i) =>
          _this.renderCategory(data, i)
        )}
      </div>
    );
  }

  render() {
    var _this = this
    let defaultValue = null
    var options = _.map(this.state.sources, function(source){return {value:source._id,label:source.name}})
    if(this.props.source){
      defaultValue = _.find(options, function(option){
        return option.value === _this.props.source
      })
    }

    return (
      <div className="container container-add" style={{display: 'inline-block', marginLeft: '100px', width: 'calc(100% - 200px)', textAlign:'left', marginTop:0, paddingTop:0}}>
        <p style={{color:'#fff', fontWeight:'700',textAlign: 'left', width: '60%'}}><ContainerTranslation id={"vizualisation.categoriesTitle"}/></p>
        <p style={{color:'#fff', marginTop:'20px',textAlign: 'left', width: '60%', fontWeight: 300}}><ContainerTranslation id={"vizualisation.categoriesDescription"}/></p>
        <div style={{display: 'inline-block', width: '100%'}}>
          <label className="dark" style={{display:'block', marginBottom:'5px'}}><ContainerTranslation id={"vizualisation.source"}/></label>
          <Select
            className="Select"
            value={defaultValue}
            clearable={false}
            searchable={false}
            options={options}
            multi={false}
            placeholder={'Select your source'}
            onChange={ (e) => this.handleSourceChange(e) }
          />
        </div>
        {this.props.categories && this.props.categories.length ? (
          <div style={{marginTop:'20px'}}>
            <a onClick={ (e) => this.checkAll(e) }><ContainerTranslation id={"source.checkAll"}/></a><p style={{color:'#fff', display:'inline-block', marginLeft:'5px',marginRight:'5px'}}> / </p><a onClick={ (e) => this.uncheckAll(e) }><ContainerTranslation id={"source.uncheckAll"}/></a>
          </div>
        ) : (
          null
        )}
        {this.renderCategories()}
        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}
        {this.state.limit ? (
          <div className="message" style={{marginTop:"10px"}}>
            <Link to="profile"><ContainerTranslation id={"global.updatePlanCategories"}/></Link>
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sources: state.source.sources,
    nodes: state.source.nodes,
    message: state.source.message,
    profile: state.user.profile,
  };
}

export default connect(mapStateToProps, {fetchSources, fetchSourceNodes})(Categories);
