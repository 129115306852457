import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "../../partials/containerTranslation";
import Select from 'react-select'
import _ from 'lodash'
import { SketchPicker } from 'react-color';

class SourceAddRecords extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
  }

  componentDidUpdate(){
    var container = document.getElementById("records");
    var els = document.getElementsByClassName("record-cell-header");
        Array.prototype.forEach.call(els, function(el) {
            el.style.transform = "translateY("+(container.scrollTop)+"px)";
        });
  }

  toggleSelected(e) {
    var categories = this.props.categories
    categories[e.currentTarget.id].selected = !categories[e.currentTarget.id].selected
    this.props.setNewState({
      categories:categories,
    })
  }

  checkAll(e) {
    var categories = this.props.categories
    _.each(categories, function(c){
      c.selected = true
    })
    this.props.setNewState({
      categories:categories,
    })
  }

  uncheckAll(e) {
    var categories = this.props.categories
    _.each(categories, function(c){
      c.selected = false
    })
    this.props.setNewState({
      categories:categories,
    })
  }

  toggleOpen(e,i) {
    var categories = this.props.categories
    categories[i].open = !categories[i].open
    this.props.setNewState({
      categories:categories,
    })
  }

  handleColorClick(e) {
    var categories = this.props.categories
    categories[e.currentTarget.id].displayColorPicker = true
    this.props.setNewState({
      categories:categories,
    })
  }

  handleColorClose(e) {
    var categories = this.props.categories
    categories[e.currentTarget.id].displayColorPicker = false
    this.props.setNewState({
      categories:categories,
    })
  }

  handleColorChange(color,i) {
    var categories = this.props.categories
    categories[i].color = color.rgb
    this.props.setNewState({
      categories:categories,
    })
  }

  handleTypeChange(e,i) {
    var categories = this.props.categories
    categories[i].type = e
    this.props.setNewState({
      categories:categories,
    })
  }

  handleSeparatorChange(e,i) {
    var categories = this.props.categories
    categories[i].separator = e.currentTarget.value
    this.props.setNewState({
      categories:categories,
    })
  }

  handleRemoveRecord(e) {
    console.log(e)
  }

  renderCell(data, i){
      var key = new Date()
      key = key.getTime()
      return (
        <div className='record-cell' key={key+"-cell-"+i}>
            <input type="text" value={data && data.value}/>
        </div>
      )
  }

  renderCol(category, i) {
      var _this = this
    var categoryClass = "record-col"

    var color = `rgba(${ category.color.r }, ${ category.color.g }, ${ category.color.b }, ${ category.color.a })`
    if(!category.selected){
      color = `rgba(0,0,0,0.1)`
      categoryClass+=' disabled'
    }

    var title = category.title
    if(!title || title === ""){
      title = "(Undefined)"
    }

    const styles = {
      color: {
        width: '23px',
        height: '23px',
        borderRadius: '14px',
        display: 'inline-block',
        cursor: 'pointer',
        background: color,
        verticalAlign: 'top',
        marginTop: '9px',
        marginRight: '5px',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    var typeOptions = [
      {
        value:"text",
        label:"Text"
      },{
        value:"number",
        label:"Number"
      },{
        value:"country",
        label:"Countries"
      },{
        value:"city",
        label:"Cities"
      },{
        value:"lat",
        label:"Latitude"
      },{
        value:"long",
        label:"Longitude"
      },{
        value:"date",
        label:"Dates"
      }
    ]

    var key = new Date()
    key = key.getTime()

    var cells = []
    _.each(_this.props.records, function(r){
        var foundCell = _.find(r, function(rc){
            return rc.category === category.title
        })
        if(foundCell){
            cells.push(foundCell)
        }else{
            cells.push(null)
        }
    })

    return (
      <div className={categoryClass} key={key+"-cat-"+i}>
          <div className={"record-cell-header"}>
            {category.selected ? (
              <div onClick={ (e) => this.toggleSelected(e) } key={key} id={i} style={{display: 'inline-block', verticalAlign: 'top', marginTop: '8px', fontSize: '24px', cursor:'pointer', marginRight:'5px'}}>
                <i className="fas fa-check-circle" style={{color:'#0A1760'}}></i>
              </div>
            ) : (
              <div onClick={ (e) => this.toggleSelected(e) } key={key} id={i} style={{display: 'inline-block', verticalAlign: 'top', marginTop: '8px', fontSize: '24px', cursor:'pointer', marginRight:'5px'}}>
                <i className="far fa-circle" style={{color:'#0A1760'}}></i>
              </div>
            )}
            <div style={{display:'inline-block', verticalAlign:"top"}}>
                <div style={ styles.color } onClick={ (e) => this.handleColorClick(e) } id={i}></div>
                { category.displayColorPicker ? <div style={ styles.popover }>
                  <div style={ styles.cover } onClick={ (e) => this.handleColorClose(e) } id={i}/>
                  <SketchPicker disableAlpha={true} color={ category.color } onChange={ (color, e) => this.handleColorChange(color,i) } id={i} />
                </div> : null }
              </div>
            <p><b>{title}</b></p>
          </div>
          {cells.map((data, j) =>
            _this.renderCell(data, j)
          )}
      </div>
    );
  }

  renderCol(category, i) {
      var _this = this
    var categoryClass = "record-col"

    var color = `rgba(${ category.color.r }, ${ category.color.g }, ${ category.color.b }, ${ category.color.a })`
    if(!category.selected){
      color = `rgba(0,0,0,0.1)`
      categoryClass+=' disabled'
    }

    var title = category.title
    if(!title || title === ""){
      title = "(Undefined)"
    }

    const styles = {
      color: {
        width: '23px',
        height: '23px',
        borderRadius: '14px',
        display: 'inline-block',
        cursor: 'pointer',
        background: color,
        verticalAlign: 'top',
        marginTop: '9px',
        marginRight: '5px',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    var typeOptions = [
      {
        value:"text",
        label:"Text"
      },{
        value:"number",
        label:"Number"
      },{
        value:"country",
        label:"Countries"
      },{
        value:"city",
        label:"Cities"
      },{
        value:"lat",
        label:"Latitude"
      },{
        value:"long",
        label:"Longitude"
      },{
        value:"date",
        label:"Dates"
      }
    ]

    var key = new Date()
    key = key.getTime()

    var cells = []
    _.each(_this.props.records, function(r){
        var foundCell = _.find(r, function(rc){
            return rc.category === category.title
        })
        if(foundCell){
            cells.push(foundCell)
        }else{
            cells.push(null)
        }
    })

    return (
      <div className={categoryClass} key={key+"-cat-"+i}>
          <div className={"record-cell-header"}>
            {category.selected ? (
              <div onClick={ (e) => this.toggleSelected(e) } key={key} id={i} style={{display: 'inline-block', verticalAlign: 'top', marginTop: '8px', fontSize: '24px', cursor:'pointer', marginRight:'5px'}}>
                <i className="fas fa-check-circle" style={{color:'#0A1760'}}></i>
              </div>
            ) : (
              <div onClick={ (e) => this.toggleSelected(e) } key={key} id={i} style={{display: 'inline-block', verticalAlign: 'top', marginTop: '8px', fontSize: '24px', cursor:'pointer', marginRight:'5px'}}>
                <i className="far fa-circle" style={{color:'#0A1760'}}></i>
              </div>
            )}
            <div style={{display:'inline-block', verticalAlign:"top"}}>
                <div style={ styles.color } onClick={ (e) => this.handleColorClick(e) } id={i}></div>
                { category.displayColorPicker ? <div style={ styles.popover }>
                  <div style={ styles.cover } onClick={ (e) => this.handleColorClose(e) } id={i}/>
                  <SketchPicker disableAlpha={true} color={ category.color } onChange={ (color, e) => this.handleColorChange(color,i) } id={i} />
                </div> : null }
              </div>
            <p><b>{title}</b></p>
          </div>
          {cells.map((data, j) =>
            _this.renderCell(data, j)
          )}
      </div>
    );
  }

  renderCols() {
    var _this = this

    return (
      <div className="records" id="records" onScroll={this.manageScroll.bind(this)}>
          <div style={{display:'flex', height:'300px'}}>
            <div className="tools-records" style={{marginTop:43}}>
            {this.props.records.map((data, i) =>
              <a onClick={ (e) => this.handleRemoveRecord(e) } key={"record-tool-"+i} style={{display:'bloc', height:'39px', lineHeight:'39px', paddingRight:8, color:'#ffffff'}}>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </a>
            )}  
            </div>
            {this.props.categories.map((data, i) =>
              _this.renderCol(data, i)
            )}
            </div>
      </div>
    );
  }

  manageScroll(e){
    var _this = this
    if(e.target.id === 'records'){
      var els = document.getElementsByClassName("record-cell-header");
      Array.prototype.forEach.call(els, function(el) {
          el.style.transform = "translateY("+(e.target.scrollTop)+"px)";
      });
    }
  }

  render() {
    return (
      <div className="container container-add" style={{display: 'block', position: 'absolute', marginLeft: '100px', width: 'calc(100% - 300px)', maxWidth:'870px', textAlign:'left', marginTop:0, paddingTop:0}}>
        <a onClick={ (e) => this.checkAll(e) }><ContainerTranslation id={"source.checkAll"}/></a><p style={{color:'#fff', display:'inline-block', marginLeft:'5px',marginRight:'5px'}}> / </p><a onClick={ (e) => this.uncheckAll(e) }><ContainerTranslation id={"source.uncheckAll"}/></a>
        {this.renderCols()}
        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(SourceAddRecords);
