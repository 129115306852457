import React, { Component } from 'react';
import MemberItem from './member-item';
import _ from 'lodash';

class MemberList extends Component {

  removeMember(data) {
    this.props.removeMember(data);
  }

  render() {
    var members = _.compact(this.props.members)
    return (
      <div className="fullpage-list">
        { members.length === 0 ? (
          <div className="line" style={{boxShadow: 'none'}}>
            <i className="far fa-frown"></i> <p>You currently have no member here !</p>
          </div>
        ) : (
          null
        )}
        {members.map((data, i) => <MemberItem
          role={data.role}
          key={'member-'+i}
          value={data.value}
          label={data.label}
          removeMember={this.removeMember.bind(this)}
        />)}
      </div>
    );
  }
}

export default MemberList;
