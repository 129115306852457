import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "../partials/containerTranslation";

class InvoiceItem extends Component {

  render() {
    var date = new Date(this.props.date*1000)
    var displayDate = ""
    var day = "0" + date.getDate();
    day = day.substr(-2)
    var month = "0" + date.getMonth();
    month = month.substr(-2)
    var year = date.getFullYear()
    displayDate = day+"/"+month+"/"+year

    return (
      <div className="line">
        <a className="lineContent">
          <p style={{marginRight:'20px'}}><i className="fas fa-receipt"></i>{displayDate}</p>
          <p style={{marginRight:'20px'}}>{this.props.number}</p>
          <p style={{fontWeight:'bold'}}>{(this.props.amount/100).toFixed(2)} {this.props.currency}</p>
        </a>
        <div className="tools">
          <a className="Message-remove" data-for='downloadInvoice' data-tip="profile.downloadInvoice" href={this.props.pdf}><i className="fas fa-file-pdf"></i></a>
          <ReactTooltip className='tooltip' id='downloadInvoice' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
        </div>
      </div>
    );

  }
}

export default InvoiceItem;
