import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "../partials/containerTranslation";
import Button from "../partials/Button/Button"

import illu_help_map from '../../assets/images/illu_help_map.png';
import help1 from '../../assets/images/help.png';
import help2 from '../../assets/images/help.png';
import help3 from '../../assets/images/help.png';
import help4 from '../../assets/images/help.png';

class Help extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
    this.state = {
      contact:false,
    };
  }

  toogleContact(){
    this.setState({
      contact:true
    })
  }

  render() {
    return (
      <div className="page-container">
        <div className="container help">
          <div className="help-full">
            <div className="help-full-content">
              <img className="help-full-illu" src={illu_help_map} alt="help"/>
              <div className="content">
                <h1 className="page-title" style={{color:"#fff", marginTop:"20px", marginLeft:0}}><ContainerTranslation id={"help.mapTitle"}/></h1>
                <p style={{color:"#fff", marginTop:"20px"}}><ContainerTranslation id={"help.mapSubTitle"}/></p>
                <Button
                  link="/help/vizualisation"
                  icon="fas fa-child"
                  textTranslation="help.mapButton"
                />
              </div>
            </div>
          </div>
          <div className="col-container" style={{marginTop:'50px', marginLeft: '20px', width:'calc(100% - 10px)'}}>
            <div className="col col-1-4">
              <Link className="tile vizualisation" to="/help/create_source">
                <div className="tile-wrapper">
                  <div className="tile-thumbnail" style={{backgroundColor:'#EB89B8'}}>
                    <img src={help1} alt="help" style={{width: '40%', paddingTop: '10%', paddingLeft: '30%', paddingBottom: '10%'}}/>
                  </div>
                  <div className="tile-content" style={{height: '100px'}}>
                    <h3 style={{fontWeight: 'bold', fontSize:'14px', marginTop:0, marginBottom:0}}><ContainerTranslation id={"help.sourceCreation"}/></h3>
                    <p style={{fontSize:'12px', textAlign:'justify', marginTop:"10px"}}><ContainerTranslation id={"help.sourceCreationDescription"}/></p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col col-1-4">
              <Link className="tile vizualisation" to="/help/create_vizualisation">
                <div className="tile-wrapper">
                  <div className="tile-thumbnail" style={{backgroundColor:'#4CCEDE'}}>
                    <img src={help2} alt="help" style={{width: '40%', paddingTop: '10%', paddingLeft: '30%', paddingBottom: '10%'}}/>
                  </div>
                  <div className="tile-content" style={{height: '100px'}}>
                    <h3 style={{fontWeight: 'bold', fontSize:'14px', marginTop:0, marginBottom:0}}><ContainerTranslation id={"help.mapCreation"}/></h3>
                    <p style={{fontSize:'12px', textAlign:'justify', marginTop:"10px"}}><ContainerTranslation id={"help.mapCreationDescription"}/></p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col col-1-4">
              <Link className="tile vizualisation" to="/help/team">
                <div className="tile-wrapper">
                  <div className="tile-thumbnail" style={{backgroundColor:'#FFCD47'}}>
                    <img src={help3} alt="help" style={{width: '40%', paddingTop: '10%', paddingLeft: '30%', paddingBottom: '10%'}}/>
                  </div>
                  <div className="tile-content" style={{height: '100px'}}>
                    <h3 style={{fontWeight: 'bold', fontSize:'14px', marginTop:0, marginBottom:0}}><ContainerTranslation id={"help.team"}/></h3>
                    <p style={{fontSize:'12px', textAlign:'justify', marginTop:"10px"}}><ContainerTranslation id={"help.teamDescription"}/></p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col col-1-4">
              <Link className="tile vizualisation" to="/help/account">
                <div className="tile-wrapper">
                  <div className="tile-thumbnail" style={{backgroundColor:'#0CE9AF'}}>
                    <img src={help4} alt="help" style={{width: '40%', paddingTop: '10%', paddingLeft: '30%', paddingBottom: '10%'}}/>
                  </div>
                  <div className="tile-content" style={{height: '100px'}}>
                    <h3 style={{fontWeight: 'bold', fontSize:'14px', marginTop:0, marginBottom:0}}><ContainerTranslation id={"help.account"}/></h3>
                    <p style={{fontSize:'12px', textAlign:'justify', marginTop:"10px"}}><ContainerTranslation id={"help.accountDescription"}/></p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div style={{textAlign: 'center', marginTop: '50px'}}>
            <p style={{fontSize:'14px', textAlign:'center', marginTop:"10px", color: "#0a1760"}}><ContainerTranslation id={"help.contactText"}/></p>
            {!this.state.contact ? (
              <Button
                action={this.toogleContact.bind(this)}
                icon="fas fa-coffee"
                textTranslation="help.contactButton"
              />
            ) : (
              <div>
                <Button
                  href="mailto:contact@bakamap.fr"
                  textTranslation="help.contact1"
                />
                <br/>
                <Button
                  style={{marginTop:'10px'}}
                  href="mailto:contact@bakamap.fr"
                  textTranslation="help.contact2"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Help;
