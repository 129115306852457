import React from "react";
import ReactExport from "react-export-excel";
import _ from 'lodash';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ExportExcel extends React.Component {

    renderExcelToExport =() => {
      let dataSet = []
      var columns = []

      this.props.dataToExcel.categories.map( (item, i) => {
        return columns.push(<ExcelColumn key={"column"+i} label={item.title} value={item.title}/>)
      })
      this.props.dataToExcel.records.map( item => {
        let content = {}
        _.each(item.content, function(c){
          if(c.value){
            if(!content[c.category]){
              content[c.category] = []
            }
            content[c.category].push(c.value)
          }
        })
        _.each(content, function(value, key) {
          content[key] = _.join(value, '//');
        });
        return dataSet.push(content)
      })

      return (
        <ExcelSheet data={dataSet} name="Data">
          {columns}
        </ExcelSheet>
      )
    }

    render() {
        var date = new Date()
        return (
            <ExcelFile
              element={
                <i className="fas fa-download"></i>
              }
              filename={"bakamap_export_"+date}
              >
              {this.renderExcelToExport()}
            </ExcelFile>
        );
    }
}
