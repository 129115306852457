import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MemberItem extends Component {

  handleDelete(elm) {
    var _this = this
    this.props.removeMember({
      role: _this.props.role,
      value: _this.props.value
    })
  }

  render() {
    return (
      <div className="line dark">
        {this.props.label === this.props.value ? (
          <a>
            <i className="fa fa-user" aria-hidden="true"></i> <p>{this.props.label}</p>
          </a>
        ) : (
          <a>
            <i className="fa fa-user-friends" aria-hidden="true"></i> <p style={{fontWeight:'bold'}}>{this.props.label}</p>
          </a>
        )}
        <div className="tools">
          <a onClick={this.handleDelete.bind(this)} className="Message-remove"><i className="fa fa-trash" aria-hidden="true"></i></a>
        </div>
      </div>
    );
  }
}

export default MemberItem;
