import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "./../partials/containerTranslation";
import SourceAddUpload from "./add/upload";
import SourceAddRecords from "./add/records";
import SourceAddCategories from "./add/categories";
import SourceAddCard from "./add/card";
import SourceAddShare from "./add/share";
import _ from 'lodash'

import { createSource, fetchSources, fetchSource, updateSource } from '../../actions/source';

class SourceAdd extends Component {

  constructor(props) {
    super(props);
    const { params, createSource, fetchSources, fetchSource, updateSource } = this.props;

    var idSource = window.location.pathname.split('/')[window.location.pathname.split('/').length-1]
    if(idSource !== 'add' && idSource !== 'callback'){
      fetchSource(idSource)
    }

    this.state = {
      idSource:idSource,
      type:'file',
      file: null,
      sheets: [],
      shareWith: [],
      selectedSheet: null,
      name: "",
      data:null,
      selectedData:null,
      categories:[],
      records:[],
      loading:false,
      card:{
        title:"",
        description:"",
        url:""
      }
    };
  }

  componentDidUpdate(prevProps){
    if(this.props.source) {
      if(!prevProps.source || this.props.source._id !== prevProps.source._id){
        var card = this.props.source.card
        var categories = this.props.source.categories
        var records = this.props.records

        _.forEach(card, function(value, key) {
          if(value && value !== ""){
            var c = _.find(categories, function(cat){
              return cat.title.toString() === value.toString()
            })
            if(value){
              return card[key] = c
            }
            return card[key] = ""
          }
          return card[key] = ""
        });

        _.each(categories, function(cat) {
          cat.selected = true
          cat.type = {
            value:"text",
            label:"Text"
          }
        });

        this.setState({
          shareWith:this.props.source.contributors,
          categories:categories,
          records:records,
          name:this.props.source.name,
          card : card,
          type:this.props.source.type,
          activeStep:0
        })
      }
    }
    if(this.props.profile && this.props.profile.limitations && this.props.sources.length >= this.props.profile.limitations.sources) {
      this.props.history.push('/source');
    }

    if(this.props.message && this.props.message === 'Source successfully updated!') {
      this.props.history.push('/source');
    }
    if(this.props.message && this.props.message === 'Source created !') {
      this.props.history.push('/source');
    }
  }

  sendData(data) {
    var _this = this

    var categories = _.filter(this.state.categories, {'selected':true})
    categories = _.map(categories, function(category){
      return {
        color: category.color,
        separator: category.separator,
        type: category.type.value,
        title: category.title
      }
    })

    var card = this.state.card
    if(card.title && card.title.title){
      card.title = card.title.title
    }
    if(card.description && card.description.title){
      card.description = card.description.title
    }
    if(card.url && card.url.title){
      card.url = card.url.title
    }

    var objToReturn={
      name: this.state.name,
      type: this.state.type,
      categories : categories,
      card : this.state.card,
      contributors: this.state.shareWith
    }

    if(this.state.idSource === "add" || this.state.idSource === "callback"){
      var records = this.state.selectedData
      _.each(records, function(record, i){
        _.each(record, function(value, j){
          var selected = false
          if(_this.state.categories[j]){
            if(_this.state.categories[j].selected === true){
              selected = true
            }

            var foundIndexInCard = _.map(_this.state.card, 'title').indexOf(_this.state.categories[j].title)
            if(foundIndexInCard !== -1){
              selected = true
            }
          }
          if(!_this.state.file){
            if(selected === true && value && _this.state.categories[j]){
              records[i][j] = {
                category : _this.state.categories[j].title,
                value : value
              }
            }else{
              records[i][j] = null
            }
          }else{
            if(selected === true && value && _this.state.categories[j] && i > 0 ){
              records[i][j] = {
                category : _this.state.categories[j].title,
                value : value
              }
            }else{
              records[i][j] = null
            }
          }
        })
      })
      records = _.map(records, function(record){
        return _.compact(record)
      })

      records = _.filter(records, function(record){
        return record.length>0
      })

      objToReturn.records = records
      this.setState({
        loading:true
      })
      this.props.createSource(objToReturn)
    }else{
      this.setState({
        loading:true
      })
      this.props.updateSource(objToReturn, _this.state.idSource)
    }
  };

  setNewUploadState(data) {
    var categories = []
    if(data.selectedData && data.selectedData[0]){
      categories = _.map(data.selectedData[0], function(cat){
        return {
          title:cat,
          color: {
            r: '241',
            g: '112',
            b: '19',
            a: '1',
          },
          type:{
            value:"text",
            label:"Text"
          },
          displayColorPicker: false,
          open: false,
          separator:null,
          selected:true
        }
      })
    }else if(this.state.categories){
      categories = this.state.categories
    }
    this.setState({
      file:data.file,
      data:data.data,
      sheets:data.sheets,
      name:data.name,
      categories:categories,
      selectedSheet:data.selectedSheet,
      selectedData:data.selectedData,
      error:data.error,
    })
  };

  setNewConnectionState(data, type) {
    var categories = _.cloneDeep(data.categories)
    _.each(categories, function(cat){
      cat.selected = true
      cat.type = {
        value:cat.type,
        label:cat.type
      }
    })

    data.card.title = _.find(categories, function(cat){
      return cat.title.toString() === data.card.title
    })
    data.card.description = _.find(categories, function(cat){
      return cat.title.toString() === data.card.description
    })
    data.card.url = _.find(categories, function(cat){
      return cat.title.toString() === data.card.url
    })

    var selectedData = _.map(data.records, function(r){
      var content = []
      _.each(r.content, function(c){
        var foundIndex = _.findIndex(data.categories, function(cat){return cat.title === c.category})
        content[foundIndex] = c.value
      })
      return content
    })

    this.setState({
      type:type,
      categories:categories,
      selectedData:selectedData,
      card:data.card,
    })
  };

  setNewCategoriesState(data) {
    this.setState({
      categories:data.categories,
      error:data.error,
    })
  };

  setNewFileState(data) {
    this.setState({
      card:data.card,
      error:data.error,
    })
  };

  setNewShareState(data) {
    this.setState({
      shareWith:data.shareWith,
      error:data.error,
    })
  };

  renderContainer() {
    var _this = this
    return ([<SourceAddUpload
      key="upload"
      idSource={this.state.idSource}
      file={this.state.file}
      sheets={this.state.sheets}
      selectedSheet={this.state.selectedSheet}
      name={this.state.name}
      data={this.state.data}
      selectedData={this.state.selectedData}
      setNewState={this.setNewUploadState.bind(this)}
      setNewConnectionState={this.setNewConnectionState.bind(this)}
    />,<SourceAddRecords
      key="records"
      categories={this.state.categories}
      records={this.state.records}
      setNewState={this.setNewCategoriesState.bind(this)}
    />,<SourceAddCard
      key="card"
      card={this.state.card}
      categories={this.state.categories}
      setNewState={this.setNewFileState.bind(this)}
    />,<SourceAddShare
      key="share"
      shareWith={this.state.shareWith}
      setNewState={this.setNewShareState.bind(this)}
    />])
  }

  renderAlert() {
    if (this.props.error.statusText === "Forbidden") {
      return (
        <div className="message error">
          <ContainerTranslation id={"global.limitReached"}/>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="page-container">
        <div className="page-container-header">
          <Link className="add-btn btn" to="/source"><i className="fas fa-arrow-left"></i></Link>
          <Link className="page-back" style={{textDecoration:"none"}} to="/source"><ContainerTranslation id={"global.back"}/></Link>
        </div>
        {this.renderContainer()}
        {this.renderAlert()}
        <div style={{width:'100%', textAlign:'center'}}>
            {this.state.shareWith && this.state.categories && this.state.categories.length ? (
              <a className="btn" style={{textTransform:'inherit'}} onClick={this.sendData.bind(this)}>
                <i className="fas fa-check-circle"></i><ContainerTranslation id={"source.shareOk"}/>
              </a>
            ) : (
              <a className="btn" style={{textTransform:'inherit', opacity:0.2, cursor:"not-allowed"}}>
                <i className="fas fa-check-circle"></i><ContainerTranslation id={"source.shareOk"}/>
              </a>
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    source: state.source.source,
    records: state.source.records,
    sources: state.source.sources,
    profile: state.user.profile,
    message: state.source.message,
    error: state.source.error
  };
}

export default withRouter(connect(mapStateToProps, {createSource, fetchSources, fetchSource, updateSource})(SourceAdd));
