import { getData, postData, putData, deleteData } from './index';
import { FETCH_COMMENTS, FETCH_COMMENT, SELECT_COMMENT, CREATE_COMMENT, UPDATE_COMMENT, DELETE_COMMENT, COMMENT_ERROR } from './types';

//= ===============================
// Comment actions
//= ===============================

export function createComment(comment) {
  const data = comment;
  const url = '/comment';
  return dispatch => postData(CREATE_COMMENT, COMMENT_ERROR, true, url, dispatch, data);
}

export function fetchComments(vizualisationId, isPublic) {
  let url = ''
  if(isPublic === true){
    url = '/comment/public/'+vizualisationId;
  }else{
    url = '/comment/'+vizualisationId;
  }
  return dispatch => getData(FETCH_COMMENTS, COMMENT_ERROR, true, url, dispatch);
}

export function fetchComment(commentId) {
  const url = '/comment/'+commentId;
  return dispatch => getData(FETCH_COMMENT, COMMENT_ERROR, true, url, dispatch);
}

export function selectComment(id) {
  return dispatch => dispatch({
    type: SELECT_COMMENT,
    payload: id,
  });
}

export function updateComment(comment, commentId) {
  const url = '/comment/'+commentId
  let data = comment
  return dispatch => putData(UPDATE_COMMENT, COMMENT_ERROR, true, url, dispatch, data);
}

export function deleteComment(commentId) {
  const url = '/comment/'+commentId;
  return dispatch => deleteData(DELETE_COMMENT, COMMENT_ERROR, true, url, dispatch);
}
