import { CLOSE_V1_MESSAGE } from './types';

//= ===============================
// Global actions
//= ===============================

export function closeV1Message() {
  return dispatch => dispatch({
    type: CLOSE_V1_MESSAGE
  });
}
