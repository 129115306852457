import { FETCH_COMMENTS, CREATE_COMMENT, UPDATE_COMMENT, DELETE_COMMENT, FETCH_COMMENT, SELECT_COMMENT, COMMENT_ERROR } from '../actions/types';

const INITIAL_STATE = { comment: {}, comments: [], message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_COMMENTS:
      return { ...state, comments: action.payload.comments, message:'', error: '' };
    case FETCH_COMMENT:
      return { ...state, comment: action.payload.comment, message: '', error: '' };
    case SELECT_COMMENT:
      return { ...state, comment: action.payload, message: '', error: '' };
    case CREATE_COMMENT:
      return { ...state, comments: action.payload.comments, comment:action.payload.comment, message:action.payload.message, error: '' };
    case UPDATE_COMMENT:
      return { ...state, message:action.payload.message, error: '' };
    case DELETE_COMMENT:
      return { ...state, comment:{}, message: action.payload.message, error: '' };
    case COMMENT_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
