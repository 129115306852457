import React, { Component } from 'react'
import _ from 'lodash';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import moment from 'moment';
import { connect } from 'react-redux';
import ContainerTranslation from "./../../partials/containerTranslation";
import ReactTooltip from 'react-tooltip'
import { setPlayMode } from '../../../actions/vizualisation';

const Range = Slider.Range;

var playInterval = null
var iInterval = 0

class Timeline extends Component {

   constructor(props){
      super(props)
      const { params, setPlayMode } = this.props;
      this.state = {
        timelineData : [],
        selectedDates : [null,null]
      };
   }

   componentDidMount() {
     if(this.props.categories && this.props.records){
       this.updateDates()
     }
   }

   componentDidUpdate(prevProps) {
     var _this = this
     if(this.props.categories && this.props.records && JSON.stringify(this.props.records) !== JSON.stringify(prevProps.records)){
       this.updateDates()
     }
     if(prevProps.selectedDates !== this.props.selectedDates && this.props.playMode !==true){
       if(this.props.selectedDates[0] && this.props.selectedDates[1]){
         var startDate = new Date(this.props.selectedDates[0])
         var endDate = new Date(this.props.selectedDates[1])

         var startIndex = _.findIndex(_this.state.timelineData, function(d){
           return moment(startDate).format('L').toString() === d.date.toString()
         })
         var endIndex = _.findIndex(_this.state.timelineData, function(d){
           return moment(endDate).format('L').toString() === d.date.toString()
         })
         if((startIndex || startIndex === 0) && endIndex){
           var selectedDates = [startIndex,endIndex]
           if(selectedDates !== this.state.selectedDates){
             this.setState({
               selectedDates:selectedDates
             })
           }
         }
       }else{
         var selectedDates = [0,this.state.timelineData.length-1]
         this.setState({
           selectedDates:selectedDates
         })
       }
     }
   }

   updateDates() {
     var categories = this.props.categories
     var records = this.props.records
     var boundingDates = this.props.boundingDates
     var foundDateCategory = _.find(categories, function(cat){return cat.type === 'date'})

     var firstYear = new Date(boundingDates[0]).getFullYear()
     var lastYear = new Date(boundingDates[1]).getFullYear()
     var dates = []

     for(var i=firstYear-1; i<=lastYear+1; i++){
       dates.push({
         value: i.toString(),
         date: moment(i.toString()).format('L')
       })
     }

     dates = _.map(dates, function(date){
       var filteredRecords = _.filter(records, function(r){
         var foundDate = _.find(r.content, function(c){
           return c.category === foundDateCategory.title
         })
         if(foundDate && moment(foundDate.value, foundDateCategory.dateFormat)._isValid){
           if(moment(foundDate.value, foundDateCategory.dateFormat).format('YYYY') === date.value){
             return true
           }
         }
         return false
       })
       return {
         count : filteredRecords.length,
         value : date.value,
         date: date.date
       }
     })

     var selectedDates = this.state.selectedDates
     if(selectedDates[0] === null && selectedDates[1] === null){
       selectedDates = [0,dates.length-1]
     }

     this.setState({
       timelineData:dates,
       selectedDates:selectedDates
     })
   }

   changingDates(value) {
     this.setState({
       selectedDates:value
     })
   }

   changeDates(value) {
     var dates = [new Date(this.state.timelineData[value[0]].date).getTime(),new Date(this.state.timelineData[value[1]].date).getTime()]
     this.props.setSelectedDates(dates);
   }

   setPlayMode(mode) {
     if(mode){
       var _this = this
       iInterval = _this.state.selectedDates[0]+1
       var nbRecords = _this.state.timelineData[iInterval].categories
       var dates = [new Date(_this.state.timelineData[iInterval].date).getTime(),new Date(_this.state.timelineData[iInterval+1].date).getTime()]
       _this.props.setSelectedDates(dates);
       playInterval = setInterval(function(){
         iInterval++
         if(iInterval === _this.state.selectedDates[1]){
           clearInterval(playInterval)
           dates = [new Date(_this.state.timelineData[_this.state.selectedDates[0]].date).getTime(),new Date(_this.state.timelineData[_this.state.selectedDates[1]].date).getTime()]
           _this.props.setSelectedDates(dates);
           _this.props.setPlayMode(null)
         }else{
           dates = [new Date(_this.state.timelineData[iInterval].date).getTime(),new Date(_this.state.timelineData[iInterval+1].date).getTime()]
           _this.props.setSelectedDates(dates);
         }
       }, 2000);
       this.props.setPlayMode(mode)
     }else{
       clearInterval(playInterval)
       var dates = [new Date(this.state.timelineData[this.state.selectedDates[0]].date).getTime(),new Date(this.state.timelineData[this.state.selectedDates[1]].date).getTime()]
       this.props.setSelectedDates(dates);
       this.props.setPlayMode(null)
     }
   }

   render() {
     let _this = this
     let marks = {}
     let startDate = this.state.selectedDates[0]
     let endDate = this.state.selectedDates[1]
     let key = new Date().getTime()+'timeline'

     if(startDate === null || endDate === null){
       return (
         <div>
         </div>
       )
     }

      var maxValue = _.max(_.map(this.state.timelineData, function(t){
        return t.count
      }))

     _.each(this.state.timelineData, function(time, i){

       if(i >= startDate && i <= endDate){
         var bars=[]
         var label=[]

         //BARS
         if(i > startDate){
           if(_this.props.playMode){
             if(i === iInterval){
               bars.push(<p key={key} style={{color:'#00E3CC', lineHeight: '15px'}}><i className="fas fa-caret-left" style={{marginLeft:'12px', marginRight:'5px'}}></i>{_this.state.timelineData[iInterval].value}</p>)
             }
           }else{
             if(time.count > 0){
              var value = time.count*50/maxValue
              bars.push(<div className='timeline-bar' key={"mark-bar"+i} style={{width:value+"px"}}></div>)
            }
           }
           if(bars.length){
             bars = <div className='timeline-bar-container'>{bars}</div>
           }
         }

         if(i === startDate || i === endDate){
           label.push(<p key={"mark-label"+i}><strong>{time.value}</strong></p>)
         }

         return marks[i] = <div key={"mark"+i}>{label}{bars}</div>
       }
       return marks[i] = <div key={"mark"+i}></div>
     })

      return (
        <div className="vizualisation-timeline">
          <Range vertical dots pushable
            marks={marks}
            min={0} max={this.state.timelineData.length-1} step={1} defaultValue={[startDate, endDate]}
            value={this.state.selectedDates}
            handleStyle={{ backgroundColor: '#ffffff', borderColor:'#ffffff' }}
            trackStyle={{ background: '#3d4679' }}
            railStyle={{ background: '#3d4679', width:"2px", marginLeft:"1px" }}
            dotStyle={{ backgroundColor: '#384276', border:'none', width: '6px', height: '6px', marginLeft: '-3px', marginBottom: '-2px' }}
            activeDotStyle={{ backgroundColor: '#00E3CC', width: '8px', height: '8px', marginLeft: '-4px', marginBottom: '-4px' }}
            onChange={this.changingDates.bind(this)}
            onAfterChange={this.changeDates.bind(this)}
          />
          {this.props.playMode ? (
            <a key={'btn-stop'} key="timelineStop" className="btn-play" data-for='timeline' data-tip="vizualisation.timelineFilmStop" onClick={ (e) => _this.setPlayMode(null)}><i className="fas fa-square"></i></a>
          ) : (
            <a key={'btn-play'} key="timelinePlay" className="btn-play decale" data-for='timeline' data-tip="vizualisation.timelineFilm" onClick={ (e) => _this.setPlayMode(true)}><i className="fas fa-play"></i></a>
          )}
          <ReactTooltip className='tooltip' id='timeline' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
        </div>
      )
   }
}

function mapStateToProps(state) {
  return {
    playMode: state.vizualisation.playMode
  };
}

export default connect(mapStateToProps, {setPlayMode})(Timeline);
