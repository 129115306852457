import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "../partials/containerTranslation";
import List from './../partials/List/List'
import Button from "../partials/Button/Button"
import _ from 'lodash';

import help1 from "../../assets/help/help1.png";
import help2 from "../../assets/help/help2.png";
import help3 from "../../assets/help/help3.png";
import help4 from "../../assets/help/help4.png";
import help5 from "../../assets/help/help5.png";
import help6 from "../../assets/help/help6.png";
import help7 from "../../assets/help/help7.png";
import help8 from "../../assets/help/help8.png";

class HelpVizualisation extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
    this.state = {
      selectedHelp:null,
    };
  }

  selectHelp(d){
    var scrollTop = document.getElementById(d).offsetTop
    window.scrollTo({
      top: scrollTop-126,
      behavior: 'smooth'
    });
    this.setState({
      selectedHelp:d
    })
  }

  render() {
    var tabList = [
      {
        _id:"help"+0,
        nameTranslate:"help.vizualisation1Title",
        media:help1,
        mediaType:"image"
      },
      {
        _id:"help"+1,
        nameTranslate:"help.vizualisation2Title",
        media:help2,
        mediaType:"image"
      },
      {
        _id:"help"+2,
        nameTranslate:"help.vizualisation3Title",
        media:help3,
        mediaType:"image"
      },
      {
        _id:"help"+3,
        nameTranslate:"help.vizualisation4Title",
        media:help4,
        mediaType:"image"
      },
      {
        _id:"help"+4,
        nameTranslate:"help.vizualisation5Title",
        media:help5,
        mediaType:"image"
      },
      {
        _id:"help"+5,
        nameTranslate:"help.vizualisation6Title",
        media:help6,
        mediaType:"image"
      },
      {
        _id:"help"+6,
        nameTranslate:"help.vizualisation7Title",
        media:help7,
        mediaType:"image"
      },
      {
        _id:"help"+7,
        nameTranslate:"help.vizualisation8Title",
        media:help8,
        mediaType:"image"
      }
    ]
    var classLine = []
    var _this = this
    _.each(tabList, function(help, i){
      var c = "list-item"
      if(_this.state.selectedHelp === i){
        c += " selected"
      }
      classLine.push(c)
    })

    return (
      <div className="page-container">
        <div className="col-1-3" style={{position:"fixed", maxWidth:"calc(970px / 3 - 10px)"}}>
          <Link to="/help"><h1 className="page-title"><ContainerTranslation id={"help.title"}/></h1></Link><p style={{display: 'inline-block', marginTop: '8px'}}><i className="fas fa-angle-right" style={{marginLeft:'10px', marginRight:'10px', color:'#8389AA'}}></i><ContainerTranslation id={"help.mapTitle"}/></p>
          <div className="container" style={{marginTop: 0, marginLeft: 0, textAlign:'center'}}>
            <List
              data = {tabList}
              theme = "light"
              selectedItem={this.state.selectedHelp}
              selectItem={this.selectHelp.bind(this)}
            />
            <Button
              style={{marginTop:'20px'}}
              href="mailto:contact@bakamap.fr"
              icon="fas fa-coffee"
              textTranslation="help.contactButton"
            />
          </div>
        </div>
        <div className="col-2-3" style={{marginLeft: 'calc(33.33% - 10px)'}}>
          <div className="container help" style={{marginTop:0}}>
            {tabList.map((help, i) => (
              <div className="help-text" id={"help"+i} key={"help"+i}>
                <h3 style={{fontWeight: 'bold', fontSize:'16px', marginTop: '30px', textTransform: 'uppercase'}}><span style={{color:'#00E3CC'}}>#{(i+1)}</span> <ContainerTranslation id={"help.vizualisation"+(i+1)+"Title"}/></h3>
                {help.mediaType === "video" ? (
                  <video autoPlay={false} muted loop controls style={{width: '100%', borderRadius: '8px'}}>
                    <source src={help} type="video/mp4"/>
                  </video>
                ) : (
                  <img src={help.media} style={{width: '100%', borderRadius: '8px'}}/>
                )}
                <p><ContainerTranslation id={"help.vizualisation"+(i+1)+"Text"}/></p>
              </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default HelpVizualisation;
