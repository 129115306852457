import React, { Component } from 'react';
import ContainerTranslation from "./../../partials/containerTranslation";
import ReactTooltip from 'react-tooltip'
import List from './../../partials/List/List'
import { connect } from 'react-redux';
import _ from 'lodash';

import { createComment, fetchComments, deleteComment, selectComment } from '../../../actions/comment';
import { setMode, setSelectedNodes, setSelectedDates, setPosition } from '../../../actions/vizualisation';

class Comments extends Component {

  constructor(props) {
    super(props);
    const { createComment, fetchComments, selectComment, deleteComment, setMode, setSelectedNodes, setSelectedDates, setPosition } = this.props;
    this.state = {
      displayAddComment:false,
      name:"",
      description:"",
      selectedComment: null
    };
  }

  componentDidMount(){
      if(localStorage.getItem("token")){
        this.props.fetchComments(this.props.vizualisation, false)
      }else{
        this.props.fetchComments(this.props.vizualisation, true)
      }
  }

  componentDidUpdate(prevProps){
    var _this = this
    if(this.props.message && this.props.message === 'Comment created !' && !prevProps.message) {
      this.setState({
        displayAddComment:false
      })
      this.props.fetchComments(this.props.vizualisation);
    }
    if(this.props.message && this.props.message === 'Comment successfully removed!' && !prevProps.message) {
      this.props.fetchComments(this.props.vizualisation);
    }
  }

  handleDeleteComment(id) {
    var result = window.confirm("Do you really want to delete this comment ?");
    if (result) {
      this.props.deleteComment(id)
    }
  }

  handleNameChange(e){
    this.setState({
      name:e.currentTarget.value
    })
  }

  handleDescriptionChange(e){
    this.setState({
      description:e.currentTarget.value
    })
  }

  displayAddComment(elm) {
    this.setState({
      displayAddComment:!this.state.displayAddComment
    })
  }

  addComment(elm) {
    var obj = {
      name: this.state.name,
      description: this.state.description,
      nodes: _.map(this.props.selectedNodes, 'compound'),
      mode: this.props.mode,
      dates: this.props.selectedDates,
      position: this.props.position,
      vizualisation: this.props.vizualisation
    }
    this.props.createComment(obj)
  }

  selectComment(id) {
    var comments = this.props.comments
    var comment = _.find(comments, function(c){
      return c._id.toString() === id.toString()
    })
    if(comment){
      this.props.setMode(comment.mode)
      this.props.setSelectedNodes(comment.nodes)
      this.props.setSelectedDates(comment.dates)
      this.props.setSelectedNodes(comment.nodes)
      this.props.setSelectedDates(comment.dates)
      if(comment.position){
        this.props.setPosition(comment.position)
      }
      this.props.selectComment(comment)
      this.setState({
        selectedComment:id
      })
    }
  }

  render() {
    var _this = this
    var isMine = false
    if(this.props.author === this.props.profile._id){
      isMine = true
    }

    return (
      <div className="comments">
        { this.props.description ? (
          <>
            <h1 className="page-title" style={{color:'#fff', marginTop:30, marginLeft:20}}><ContainerTranslation id={"vizualisationView.description"}/></h1>
            <p style={{color:'#fff', paddingRight: 20, paddingLeft: 20, marginTop: 24}}>{this.props.description}</p>
          </>
        ):(
          <>
            <h1 className="page-title" style={{color:'#fff', marginTop:30, marginLeft:20}}><ContainerTranslation id={"vizualisationView.description"}/></h1>
            <p style={{color:'#fff', paddingRight: 20, paddingLeft: 20, marginTop: 24}}><ContainerTranslation id={"vizualisationView.descriptionNA"}/></p>
          </>
        )}
        <h1 className="page-title" style={{color:'#fff', marginTop:30, marginLeft:20}}><ContainerTranslation id={"vizualisationView.comments"}/></h1>
        { isMine ? (
          <>
            { !this.state.displayAddComment ? (
              <div style={{display:'inline-block', marginLeft: 20, marginTop: 16}}>
                <a className="add-btn btn small" data-for='displayAddComment' data-tip="vizualisationView.displayAddComment" onClick={this.displayAddComment.bind(this)} style={{marginTop: '10px'}}><i className="fas fa-plus"></i></a>
                <ReactTooltip className='tooltip' id='displayAddComment' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
              </div>
            ) : (
              null
            )}
          </>
        ) : (
          <div style={{display:'inline-block', float:'right', width:10, marginBottom: 70}}></div>
        )}  
        {this.state.displayAddComment ? (
          <div style={{textAlign:'center', marginTop: '16px'}}>
            <label className="dark" style={{display: 'block', width: "100%", textAlign: 'left', paddingLeft: 26}}><ContainerTranslation id={"vizualisationView.commentTitle"}/></label>
            <input type="text" placeholder="Title" onChange={ (e) => this.handleNameChange(e) } style={{
              display: 'block',
              verticalAlign: 'top',
              marginLeft: '28px',
              width: 'calc(100% - 86px)'
            }}/>
            <label className="dark" style={{display: 'block', width: "100%", textAlign: 'left', paddingLeft: 26, marginTop: '15px'}}><ContainerTranslation id={"vizualisationView.commentDescription"}/></label>
            <div style={{
              width: 'calc(100% - 56px)',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: 30
            }}>
              <textarea id="description" value={this.state.description} name="description" placeholder="Description..." onChange={ (e) => this.handleDescriptionChange(e) } style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '5px'
              }}>
              </textarea>
            </div>
            <a className="btn" onClick={this.addComment.bind(this)}>
              <ContainerTranslation id={"vizualisationView.commentAdd"}/>
            </a>
            <a onClick={this.displayAddComment.bind(this)} data-for='closeComment' data-tip="vizualisationView.commentClose" style={{display: 'inline-block', verticalAlign: 'top',marginTop: '6px',marginLeft: '10px',fontSize: '24px', color:"#ffffff"}}><i className="fas fa-times"></i></a>
            <ReactTooltip className='tooltip' id='closeComment' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
          </div>
        ) : (
          <div style={{marginTop:'10px'}}>
            { this.props.comments && this.props.comments.length ? (
              <List
                data = {this.props.comments}
                icon = "fas fa-comment-alt"
                theme = "dark"
                withDetails = {true}
                withTools = {isMine}
                selectedItem={this.state.selectedComment}
                selectItem={this.selectComment.bind(this)}
                deleteItem={this.handleDeleteComment.bind(this)}
              />
            ) : (
              <div className="empty">
                <p style={{color:"#fff", marginLeft: 20, marginRight: 20, marginTop: 14, textAlign:'left'}}><ContainerTranslation id={"vizualisation.commentEmpty"}/></p>
              </div>
            )}

          </div>
        )}

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    comments:state.comment.comments,
    author: state.vizualisation.vizualisationView.vizualisation.author,
    profile: state.user.profile,
    graphData: state.vizualisation.graphData,
    position: state.vizualisation.position,
    message: state.comment.message
  };
}

export default connect(mapStateToProps, {createComment, fetchComments, selectComment, deleteComment, setMode, setSelectedNodes, setSelectedDates, setPosition})(Comments);
