import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ContainerTranslation from "../partials/containerTranslation";
import ReactTooltip from 'react-tooltip'
import { fetchVizualisations, deleteVizualisation } from '../../actions/vizualisation';
import empty from '../../assets/images/empty.png';
import _ from 'lodash';

/*Pinterest Style !*/
var resizeMasonryItem = function(item){
  var grid = document.getElementsByClassName('tiles')[0],
      rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
      rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));

  var rowSpan = Math.ceil((item.querySelector('.tile-wrapper').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));

  item.style.gridRowEnd = 'span '+rowSpan;
}

var resizeAllMasonryItems = function(){
  var allItems = document.getElementsByClassName('tile');
  for(var i=0;i<allItems.length;i++){
    allItems[i].style.opacity = 0;
  }
  setTimeout(function(){
    for(var i=0;i<allItems.length;i++){
      allItems[i].style.opacity = 1;
      resizeMasonryItem(allItems[i]);
    }
  }, 100);
}

var masonryEvents = ['load', 'resize'];

class Data extends Component {

  constructor(props) {
    super(props);
    const { params, fetchVizualisations } = this.props;
    fetchVizualisations('personal');
    this.state = {
      type: 'personal',
    };
  }

  componentDidMount(){
    var _this = this
    window.addEventListener('scroll',(e) => _this.manageScroll(e))
    masonryEvents.forEach( function(event) {
      window.addEventListener(event, resizeAllMasonryItems);
    });
    resizeAllMasonryItems()
  }

  componentWillUnmount() {
    var _this = this
    window.removeEventListener('scroll',(e) => _this.manageScroll(e))
    masonryEvents.forEach( function(event) {
      window.removeEventListener(event, resizeAllMasonryItems);
    });
  }

  componentDidUpdate(prevProps){
    var _this = this
    if(this.props.message && this.props.message === 'Vizualisation successfully removed!') {
      this.props.fetchVizualisations('personal');
    }
    if(this.props.type && this.props.type !== prevProps.type) {
      this.setState({
        type:_this.props.type
      })
    }
    resizeAllMasonryItems()
  }

  manageScroll(e){
    var element = document.getElementById('container-visualisation')
    if(element){
      element = element.getBoundingClientRect()
      if(element.bottom <= window.innerHeight){
        console.log('load !')
      }
    }
  }

  handleDelete(id, e) {
    e.preventDefault();
    var result = window.confirm("Do you really want to delete this vizualisation ?");
    if (result) {
      this.props.deleteVizualisation(id)
    }
  }

  handleEdit(id, e) {
    e.preventDefault();
    this.props.history.push("/vizualisation/add/"+id);
  }

  setType(type) {
    if(type !== this.state.type){
      this.props.fetchVizualisations(type);
    }
  }

  renderVizualisations() {
    var _this = this
    if (this.props.vizualisations && this.props.vizualisations.length) {
      return (
        <div className="tiles">
          {this.props.vizualisations.map((data, i) =>
            _this.renderVizualisation(data, i)
          )}
        </div>
      );
    }else{
      return (
        <div className="empty">
          <img className="empty-illu" src={empty} alt="empty"/>
          <p><ContainerTranslation id={"vizualisation.empty"}/> <Link to="help/create_vizualisation"><ContainerTranslation id={"vizualisation.emptyLink"}/></Link></p>
        </div>
      );
    }
  }

  renderVizualisation(vizualisation, i) {
    let keywords = null
    if(_.compact(vizualisation.keywords) && _.compact(vizualisation.keywords).length){
      keywords = ""
      _.each(_.compact(vizualisation.keywords), function(keyword){
        keywords +="#"+keyword+" "
      })
    }

    let description = null
    if(vizualisation.description && vizualisation.description !== "undefined"){
      description = vizualisation.description
      if(vizualisation.description.length > 200){
        description = vizualisation.description.substring(0, 200)+"..."
      }
    }

    return (
      <Link className="tile vizualisation" to={"/vizualisation/view/"+vizualisation._id} key={vizualisation._id+i}>
        <div className="tile-wrapper">
          <div className="tile-thumbnail">
            { vizualisation.thumbnail ? (
              <img src={vizualisation.thumbnail} alt="thumbnail" width="100%"/>
            ) : (
            null
            )}
          </div>
          <div className="infos">
            <div className="info"><span style={{fontWeight:'bold', color:'#0a1760'}}>{vizualisation.author}</span></div>
            { vizualisation.isPublic ? (
              <div className="info"><i className="fas fa-globe-europe" aria-hidden="true"></i></div>
            ) : (
            null
            )}
            { vizualisation.isShared ? (
              <div className="info"><i className="fas fa-share-alt" aria-hidden="true"></i></div>
            ) : (
            null
            )}
          </div>
          <div className="tile-content">
            { this.state.type === 'personal' ? (
              <div className="tools">
                <div onClick={this.handleEdit.bind(this, vizualisation._id)} className="tool" data-for='toolviz' data-tip="vizualisation.edit">
                  <i className="fa fa-pen" aria-hidden="true"></i>
                </div>
                <div onClick={this.handleDelete.bind(this, vizualisation._id)} className="tool" data-for='toolviz' data-tip="vizualisation.delete">
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </div>
                <ReactTooltip className='tooltip' id='toolviz' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
              </div>
            ) : (
            null
            )}
            <h3 style={{fontWeight: 'bold', fontSize:'14px', marginTop:0, marginBottom:0}}>{vizualisation.name}</h3>
            { description ? (
              <p style={{fontSize:'12px', textAlign:'justify', width: '100%', wordBreak: 'break-word'}}>{description}</p>
            ) : (
            null
            )}
            { keywords ? (
              <p style={{fontSize:'12px', color:'#00E3CC', fontWeight: 'bold', display:'block'}}>{keywords}</p>
            ) : (
            null
            )}
          </div>
        </div>
      </Link>
    );
  }

  render() {

    var limitationStyle = {}
    if(this.props.profile && this.props.profile.limitations && this.props.vizualisations.length >= this.props.profile.limitations.maps){
      limitationStyle = {backgroundColor:'#c92b2b'}
    }
    var limitationTextStyle = {margin:0}
    if(this.props.profile && this.props.profile.limitations && this.props.vizualisations.length >= this.props.profile.limitations.maps){
      limitationTextStyle = {color:'#ffffff',margin:0}
    }

    return (
      <div className="page-container" id="container-visualisation">
        { this.props.profile && this.props.profile.limitations && this.props.vizualisations.length < this.props.profile.limitations.maps && this.state.type === "personal" ? (
          <Link className="add-btn btn" to="/vizualisation/add" data-for='new' data-tip="vizualisation.new"><i className="fas fa-plus"></i></Link>
        ) : (
          <Link className="add-btn btn" style={{opacity:0.3, cursor:"not-allowed"}} to="" data-for='new' data-tip="vizualisation.newDisabled"><i className="fas fa-plus"></i></Link>
        )}
        { this.state.type !== "personal" ? (
          <h1 className="page-title"><span className="title-number">{this.props.vizualisations.length}</span> <ContainerTranslation id={"vizualisation.title"}/></h1>
        ) : (
          <h1 className="page-title"><ContainerTranslation id={"vizualisation.title"}/></h1>
        )}

        { this.props.profile && this.props.profile.limitations && this.state.type === "personal" ? (
          <div className="limitation-box" style={limitationStyle}><p style={limitationTextStyle}><span style={{fontWeight:'bold'}}>{this.props.vizualisations.length}</span>/{this.props.profile.limitations.maps}</p></div>
        ) : (
          null
        )}
        <div className="tabs" style={{}}>
          <a className={(this.state.type === 'personal' ? 'tab active' : 'tab')} onClick={ (e) => this.setType('personal') }>
            <ContainerTranslation id={"vizualisation.tabPersonal"}/>
          </a>
          <a className={(this.state.type === 'shared' ? 'tab active' : 'tab')} onClick={ (e) => this.setType('shared') }>
            <ContainerTranslation id={"vizualisation.tabShared"}/>
          </a>
          {/*<a className={(this.state.type === 'public' ? 'tab active' : 'tab')} onClick={ (e) => this.setType('public') }>
            <ContainerTranslation id={"vizualisation.tabPublic"}/>
          </a>*/}
        </div>
        <div className="container" style={{marginTop: 0, marginLeft: '36px'}}>
          {this.renderVizualisations()}
        </div>
        <ReactTooltip className='tooltip' id='new' place="right" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    type: state.vizualisation.type,
    vizualisations: state.vizualisation.vizualisations,
    profile: state.user.profile,
    message: state.vizualisation.message,
  };
}

export default withRouter(connect(mapStateToProps, {fetchVizualisations, deleteVizualisation})(Data));
