import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "./../partials/containerTranslation";
import Information from "./add/information";
import Categories from "./add/categories";
import Share from "./add/share";
import _ from 'lodash'

import { createVizualisation, fetchVizualisation, updateVizualisation } from '../../actions/vizualisation';
import { fetchSource } from '../../actions/source';

class VizualisationAdd extends Component {

  constructor(props) {
    super(props);
    const { params, createVizualisation, fetchVizualisation, updateVizualisation, fetchSource } = this.props;
    var idVizualisation = window.location.pathname.split('/')[window.location.pathname.split('/').length-1]
    if(idVizualisation !== 'add'){
      fetchVizualisation(idVizualisation)
    }
    this.state = {
      idVizualisation:idVizualisation,
      shareWith: [],
      isPublic:false,
      name: "",
      description: "",
      keywords: [],
      file: null,
      source:null,
      categories:[],
      loading:false
    };
  }

  componentDidMount(){
    if(this.props.location.query && this.props.location.query.sourceId){
      this.props.fetchSource(this.props.location.query.sourceId)
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.vizualisation) {
      if(!prevProps.vizualisation || this.props.vizualisation._id !== prevProps.vizualisation._id){
        var categories = this.props.vizualisation.categories
        _.each(categories, function(cat){
          if(cat.type === "text"){
            cat.type = {
              value:"text",
              label:"Text"
            }
          }else if(cat.type === "number"){
            cat.type = {
              value:"number",
              label:"Number"
            }
          }else if(cat.type === "country"){
            cat.type = {
              value:"country",
              label:"Countries"
            }
          }else if(cat.type === "city"){
            cat.type = {
              value:"city",
              label:"Cities"
            }
          }else if(cat.type === "lat"){
            cat.type = {
              value:"lat",
              label:"Latitude"
            }
          }else if(cat.type === "long"){
            cat.type = {
              value:"long",
              label:"Longitude"
            }
          }else if(cat.type === "date"){
            cat.type = {
              value:"date",
              label:"Dates"
            }
          }
        })
        this.setState({
          shareWith:this.props.vizualisation.contributors,
          isPublic:this.props.vizualisation.isPublic,
          name: this.props.vizualisation.name,
          description: this.props.vizualisation.description,
          keywords: this.props.vizualisation.keywords,
          source: this.props.vizualisation.source,
          categories: categories,
          activeStep:0
        })
      }
    }
    if(this.props.source) {
      if(!prevProps.source || this.props.source._id !== prevProps.source._id){
        this.setState({
          source:this.props.source._id,
          categories:this.props.source.categories
        })
      }
    }
    if(this.props.message && this.props.message === 'Vizualisation successfully updated!') {
      this.props.history.push('/vizualisation');
    }
    if(this.props.message && this.props.message === 'Vizualisation created !') {
      this.props.history.push('/vizualisation');
    }
    if(this.props.profile && this.props.profile.limitations && this.props.vizualisations.length >= this.props.profile.limitations.maps) {
      this.props.history.push('/vizualisation');
    }
  }

  sendData(data) {
    var _this = this

    var categories = _.map(_.filter(this.state.categories, {'selected':true}), function(category){
      var type = category.type
      if(type.value){
        type = type.value
      }
      var sizedBy = category.sizedBy
      if(sizedBy && sizedBy.value){
        sizedBy = sizedBy.value
      }
      return {
        title:category.title,
        type:type,
        sizedBy:sizedBy,
        separator:category.separator,
        dateFormat:category.dateFormat,
        linkedTo:category.linkedTo,
        focusOn:category.focusOn,
        color:category.color
      }
    })

    var source = null
    if(this.state.source){
      if(this.state.source._id){
        source = this.state.source._id
      }else{
        source = this.state.source
      }
    }

    var objToReturn={
      name: this.state.name,
      description: this.state.description,
      keywords: this.state.keywords,
      type: "map",
      file: this.state.file,
      isPublic: this.state.isPublic,
      source: source,
      categories : categories,
      contributors: this.state.shareWith
    }

    this.setState({
      loading:true
    })

    if(this.state.idVizualisation === "add"){
      this.props.createVizualisation(objToReturn)
    }else{
      this.props.updateVizualisation(objToReturn, _this.state.idVizualisation)
    }
  };

  setNewInformationState(data) {
    this.setState({
      file:data.file,
      name:data.name,
      description:data.description,
      keywords:data.keywords,
      error:data.error,
    })
  };

  setNewCategoriesState(data) {
    this.setState({
      categories:data.categories
    })
  };

  setNewSourceState(data) {
    this.props.fetchSource(data.source)
  };

  setNewShareState(data) {
    this.setState({
      shareWith:data.shareWith,
      isPublic:data.isPublic
    })
  };

  renderContainer() {
    var _this = this
    var thumbnail = null
    if(this.props && this.props.vizualisation && this.props.vizualisation.thumbnail){
      thumbnail = this.props.vizualisation.thumbnail
    }
    return ([<Information
      name={this.state.name}
      description={this.state.description}
      keywords={this.state.keywords}
      file={this.state.file}
      thumbnail={thumbnail}
      setNewState={this.setNewInformationState.bind(this)}
    />,<Categories
      source={this.state.source}
      categories={this.state.categories}
      setNewSourceState={this.setNewSourceState.bind(this)}
      setNewCategoriesState={this.setNewCategoriesState.bind(this)}
    />,<Share
      shareWith={this.state.shareWith}
      isPublic={this.state.isPublic}
      setNewState={this.setNewShareState.bind(this)}
    />])
  }

  renderAlert() {
    if (this.props.error.statusText === "Forbidden") {
      return (
        <div className="message error" style={{marginLeft: '120px', width: 'calc(100% - 320px)'}}>
          <ContainerTranslation id={"global.limitReached"}/>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="page-container">
        <div className="page-container-header">
          <Link className="add-btn btn" to="/vizualisation"><i className="fas fa-arrow-left"></i></Link>
          <Link className="page-back" style={{textDecoration:"none"}} to="/vizualisation"><ContainerTranslation id={"global.back"}/></Link>
        </div>
        {this.renderContainer()}
        {this.renderAlert()}
        <div style={{width:'100%', textAlign:'center'}}>
            {this.state.categories && this.state.categories.length ? (
              <a className="btn" style={{textTransform:'inherit'}} onClick={this.sendData.bind(this)}>
                <i className="fas fa-check-circle"></i><ContainerTranslation id={"vizualisation.shareOk"}/>
              </a>
            ) : (
              <a className="btn" style={{textTransform:'inherit', opacity:0.2, cursor:"not-allowed"}}>
                <i className="fas fa-check-circle"></i><ContainerTranslation id={"vizualisation.shareOk"}/>
              </a>
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vizualisation: state.vizualisation.vizualisation,
    vizualisations: state.vizualisation.vizualisations,
    source: state.source.source,
    message: state.vizualisation.message,
    error: state.vizualisation.error
  };
}

export default withRouter(connect(mapStateToProps, {createVizualisation, fetchVizualisation, updateVizualisation, fetchSource})(withRouter(VizualisationAdd)));
