import { FETCH_TEAMS, CREATE_TEAM, UPDATE_TEAM, DELETE_TEAM, FETCH_TEAM, TEAM_ERROR } from '../actions/types';

const INITIAL_STATE = { team: {}, teams: [], message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TEAMS:
      return { ...state, teams: action.payload.teams, message:'', error: '' };
    case FETCH_TEAM:
      return { ...state, team: action.payload.team, message: '', error: '' };
    case CREATE_TEAM:
      return { ...state, teams: action.payload.teams, team:action.payload.team, message:action.payload.message, error: '' };
    case UPDATE_TEAM:
      return { ...state, message:action.payload.message, error: '' };
    case DELETE_TEAM:
      return { ...state, team:{}, message: action.payload.message, error: '' };
    case TEAM_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
