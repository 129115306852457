import { CREATE_SOURCE, FETCH_SOURCE, FETCH_SOURCE_NODES, FETCH_SOURCES, UPDATE_SOURCE, INIT_SOURCE, DELETE_SOURCE, SOURCE_ERROR } from '../actions/types';

const INITIAL_STATE = { source: null, records: [], sources: [], nodes: [], type: 'personal', message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_SOURCE:
      return { ...state, source: action.payload.source, message: action.payload.message, error: '' };
    case FETCH_SOURCE:
      return { ...state, source: action.payload.source, records:action.payload.records, message:'', error: '' };
    case FETCH_SOURCE_NODES:
      return { ...state, nodes: action.payload.nodes, message:'', error: '' };
    case FETCH_SOURCES:
      return { ...state, type:action.payload.type, sources: action.payload.sources, source:null, records: [], nodes: [], message:'', error: '' };
    case UPDATE_SOURCE:
      return { ...state, message: action.payload.message, error: '' };
    case INIT_SOURCE:
      return { source: null, records: [], sources: [], nodes: [], message: '', error: '' };
    case DELETE_SOURCE:
      return { ...state, message: action.payload.message, error: '' };
    case SOURCE_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
