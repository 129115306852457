import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { fetchProfile } from '../../actions/index';
import ReactTooltip from 'react-tooltip'
import ContainerTranslation from "../partials/containerTranslation";
import logo from '../../assets/images/logo.png';
import logoWhite from '../../assets/images/logo_fond_blanc.png';

class HeaderTemplate extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
    if(this.props.profile && !this.props.profile._id){
      if(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))){
        const userId = JSON.parse(localStorage.getItem("user"))._id
        this.props.fetchProfile(userId);
      }
    }
    this.state = {
      open: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.authenticated !== prevProps.authenticated) {
      this.setState({
        open: true,
      });
    }
  }

  isRole(roleToCheck) {
    const userRole = this.props.profile.accountType
    if (userRole == roleToCheck) {
      return true;
    }
    return false;
  }

  renderLinks() {
    return [
      <li key={`${1}header`} className={(this.props.location.startsWith("/vizualisation") || this.props.location === "/" ? 'active' : '')}>
        <Link to="/vizualisation"><i className="fas fa-search showOnMobile" style={{display:'none'}}></i><ContainerTranslation id={"header.vizualisation"}/></Link>
      </li>,
      <li key={`${2}header`} className={(this.props.location.startsWith("/source") ? 'active' : '')}>
        <Link to="/source"><i className="fas fa-database showOnMobile" style={{display:'none'}}></i><ContainerTranslation id={"header.source"}/></Link>
      </li>,
      <li key={`${3}header`} className={(this.props.location.startsWith("/help") ? 'active' : '')}>
        <Link to="/help"><i className="fas fa-life-ring showOnMobile" style={{display:'none'}}></i><ContainerTranslation id={"header.help"}/></Link>
      </li>,
    ];
  }

  renderUsersLinks() {
    var profile = ""
    if(this.props.profile && this.props.profile._id){
      profile = this.props.profile.firstName+" "+this.props.profile.lastName.toUpperCase()
    }
    if(this.isRole('Bakasable') || this.isRole('Admin') || this.isRole('Owner')){
      return [
        <li key={`${1}header`} className={(this.props.location.startsWith("/team") ? 'active' : '')}>
          <Link to="/team"><i className="fas fa-users" aria-hidden="true"></i><span className="hideOnTablet"><ContainerTranslation id={"header.team"}/></span></Link>
        </li>,
        <li key={`${2}header`} className={(this.props.location.startsWith("/profile") ? 'active' : '')}>
          <Link to="/profile"><i className="fas fa-user" aria-hidden="true"></i><span className="hideOnTablet">{profile}</span></Link>
        </li>,
        <li key={`${3}header`}>
          <Link to="/logout" data-for='logout' data-tip="header.logout"><i className="fas fa-sign-out-alt" aria-hidden="true" style={{marginRight:0}}></i></Link>
        </li>,
      ];
    }
    return [
      <li key={`${1}header`} className={(this.props.location.startsWith("/profile") ? 'active' : '')}>
        <Link to="/profile"><i className="fas fa-user" aria-hidden="true"></i><span className="hideOnTablet">{profile}</span></Link>
      </li>,
      <li key={`${2}header`}>
        <Link to="/logout" data-for='logout' data-tip="header.logout"><i className="fas fa-sign-out-alt" aria-hidden="true" style={{marginRight:0}}></i></Link>
      </li>,
    ];
  }

  renderLoginLinks() {
    return [
      <li key={`${1}header`} className={(this.props.location.startsWith("/profile") ? 'active' : '')}>
        <Link to="/login"><i className="fas fa-sign-in-alt" aria-hidden="true"></i><span className="hideOnTablet">Log in</span></Link>
      </li>,
      <li key={`${2}header`}>
        <Link to="/register"><i className="fas fa-user-plus" aria-hidden="true"></i><span className="hideOnTablet">Create an account</span></Link>
      </li>,
    ];
  }

  render() {
    if(this.props.authenticated && this.props.profile.accountType && !this.props.location.startsWith("/logout")){
      var classHeader = ""
      if(this.state.open){
        classHeader="open"
      }
      if(this.props.location.startsWith("/source/add") || this.props.location.startsWith('/vizualisation/add')){
        classHeader+=" dark"
    }

      return (
        <div>
          {this.props.location.startsWith('/vizualisation/view')/* || this.props.location.startsWith("/source/add") || this.props.location.startsWith("/vizualisation/add")*/ ? (
            null
          ) : (
            <header className={classHeader}>
              <nav className="navbar">
                {this.props.profile && this.props.profile.logo && this.props.profile.accountType !== 'Member'? (
                  <Link className="navbar-brand" to="/"><img src={this.props.profile.logo} alt="logo" style={{marginRight:0}}/></Link>
                ) : (
                  <>
                  {this.props.location.startsWith('/source/add') || this.props.location.startsWith('/vizualisation/add') || this.props.location.startsWith('/vizualisation/view') ? (
                    <Link className="navbar-brand" to="/"><img src={logo} alt="logo"/><span style={{color:'#FFFFFF', fontWeight:"700", fontSize:"20px"}}>BAKA</span><span style={{color:'#00E3CC', fontWeight:"400", fontSize:"20px"}}>MAP</span></Link>
                  ) : (
                    <Link className="navbar-brand" to="/"><img src={logo} alt="logo"/><span style={{color:'#004798', fontWeight:"700", fontSize:"20px"}}>BAKA</span><span style={{color:'#00E3CC', fontWeight:"400", fontSize:"20px"}}>MAP</span></Link>
                  )}
                  </>
                )}
                <ul className="navbar-center">
                  {this.renderLinks()}
                </ul>
                <ul className="navbar-right">
                  {this.renderUsersLinks()}
                </ul>
              </nav>
              <ReactTooltip className='tooltip' place="left" id="logout" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
            </header>
          )}
        </div>
      );
    }else if(!this.props.authenticated && this.props.location.startsWith("/help")){
      return (
        <div>
          <header className={classHeader}>
            <nav className="navbar">
              <Link className="navbar-brand" to="/"><img src={logo} alt="logo"/><span style={{color:'#004798', fontWeight:"700", fontSize:"20px"}}>BAKA</span><span style={{color:'#00E3CC', fontWeight:"400", fontSize:"20px"}}>MAP</span></Link>
              <ul className="navbar-right">
                {this.renderLoginLinks()}
              </ul>
            </nav>
            <ReactTooltip className='tooltip' place="left" id="logout" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
          </header>
        </div>
      );
    }else{
      return null
    }
  }
}

function mapStateToProps(state) {

  return {
    authenticated: state.auth.authenticated,
    profile: state.user.profile
  };
}

export default connect(mapStateToProps, {fetchProfile})(HeaderTemplate);
