import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "../../partials/containerTranslation";
import { Translate } from "react-localize-redux";
import XLSX from 'xlsx';
import Select from 'react-select'
import _ from 'lodash'

import ConnectionGoogle from './connection/google'

let url = null

class SourceAddUpload extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;

    var connection = null

    this.state = {
      loading:false,
      connection:connection
    }
  }

  handleDatasetName(e){
    var _this = this
    this.props.setNewState({
      file:_this.props.file,
      data:_this.props.data,
      name:e.currentTarget.value,
      sheets: _this.props.sheets,
      selectedSheet: _this.props.selectedSheet,
      selectedData: _this.props.selectedData,
      connection: null,
      error:_this.props.error
    })
  }

  handleChange(selectorFiles){
    var extension = selectorFiles[0].name.split('.')[selectorFiles[0].name.split('.').length-1]
    var _this = this
    if(extension === 'xlsx'){
      this.setState({
        loading:true
      })
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {type: 'binary'});
        var sheets = _.map(workbook.SheetNames, function(s, i){
          return {
            value: i,
            label: s
          }
        })
        _this.props.setNewState({
          file:selectorFiles[0],
          error:null,
          sheets:sheets,
          data:workbook,
          name:_this.props.name,
          selectedSheet:sheets[0],
          selectedData:XLSX.utils.sheet_to_json(workbook['Sheets'][sheets[0].label], {header:1})
        })
        _this.setState({
          loading:false
        })
      };
      reader.readAsBinaryString(selectorFiles[0])
    }else{
      this.props.setNewState({
        file:null,
        data:null,
        name:null,
        sheets: [],
        selectedSheet: null,
        selectedData:null,
        error:"Wrong extension, please use .xlsx file"
      })
      this.setState({
        loading:false
      })
    }
  }

  handleSheetChange(e) {
    var _this = this
    this.props.setNewState({
      file:_this.props.file,
      data:_this.props.data,
      name:_this.props.name,
      sheets: _this.props.sheets,
      selectedSheet: e,
      selectedData:XLSX.utils.sheet_to_json(_this.props.data['Sheets'][e.label], {header:1}),
      error:_this.props.error
    })
  }

  handleConnectionChange(connection) {
    this.setState({
      connection:connection
    })
  }

  handleConnectionClose() {
    this.setState({
      connection:null
    })
  }

  handleConnectionData(data, type) {
    this.setState({
      connection:null
    })
    this.props.setNewConnectionState(data, type)
  }


  render() {
    //CONNECTION
    var connections = []
    if(this.props.profile && this.props.profile.limitations && this.props.profile.limitations.connections){
      connections = this.props.profile.limitations.connections
    }

    var isNew = true
    if(this.props.idSource !== "add" && this.props.source !== "callback"){
      isNew = false
    }

    return (
      <div className="container container-add" style={{display: 'inline-block', marginLeft: '100px', width: 'calc(100% - 300px)', marginTop:0, paddingTop:0}}>
        <div className="row">
          <label className="dark"><ContainerTranslation id={"source.name"}/></label>
          <Translate>
            {({ translate }) =>
              <input type="text" id="title" name="title" value={this.props.name} placeholder={translate("source.namePlaceholder")} onChange={ (e) => this.handleDatasetName(e) } style={{
                display: 'block',
              }}/>
            }
          </Translate>
        </div>

        <p style={{color:'#fff', fontWeight:'700'}}><ContainerTranslation id={"source.source"}/></p>
        <p style={{color:'#fff'}}><ContainerTranslation id={"source.uploadDescription"}/></p>

        {/*FILE*/}
        {this.state.loading ? (
          <label htmlFor="source" className="btn"><i className="fas fa-upload"></i>Loading...</label>
        ) : (
          <label htmlFor="source" className="btn"><i className="fas fa-upload"></i><ContainerTranslation id={"source.source"}/></label>
        )}
        <input type="file" className="input-file" id="source" accept=".xlsx" onChange={ (e) => this.handleChange(e.target.files) }/>

        {/*FILE OK*/}
        {this.props.file ? (
          <div>
            <div style={{width:'100%'}}>
              <p style={{
                color:'#fff',
                fontWeight:'700',
                marginTop:'30px',
                backgroundColor:"#071043",
                borderRadius:'8px',
                display:'inline-block',
                paddingLeft:'20px',
                paddingRight:'20px',
                lineHeight:'48px'
              }}>{this.props.file.name}</p>
            </div>
            {this.props.sheets && this.props.sheets.length ? (
              <div style={{width: '50%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '40px'}}>
                <p style={{color:'#ffffff'}}><ContainerTranslation id={"source.sheets"}/></p>
                <Select
                  className="Select"
                  value={this.props.selectedSheet}
                  clearable={false}
                  searchable={false}
                  options={this.props.sheets}
                  multi={false}
                  onChange={ (e) => this.handleSheetChange(e) }
                />
              </div>
            ) : (
              null
            )}
            <div style={{width:'100%', height:'1px', backgroundColor:"#1D2F98", display:'block', marginTop:'30px', marginLeft:'auto', marginRight:'auto', maxWidth:'300px'}}></div>
          </div>
        ) : (
          null
        )}


        {/*CONNECTIONS*/}
        {/*connections.indexOf('drive') !== -1 ? (
          <ConnectionGoogle
            useData={this.handleConnectionData.bind(this)}
          />
        ) : (
          null
      )*/}


        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile
  };
}

export default connect(mapStateToProps)(SourceAddUpload);
