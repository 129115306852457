import { getData, postData, putData, deleteData } from './index';
import { ACCOUNT_ERROR, FETCH_ACCOUNT, UPDATE_ACCOUNT, UPDATE_ACCOUNT_LOGO } from './types';

//= ===============================
// Account actions
//= ===============================

export function fetchAccount() {
  const url = '/account';
  return dispatch => getData(FETCH_ACCOUNT, ACCOUNT_ERROR, true, url, dispatch);
}

export function updateAccount(account) {
  const data = { account };
  const url = '/account'
  return dispatch => putData(UPDATE_ACCOUNT, ACCOUNT_ERROR, true, url, dispatch, data);
}

export function updateAccountLogo(logo) {
  var formObj  = new FormData();
  if(logo){
    formObj.append('file',logo)
  }
  const url = '/account/logo'
  return dispatch => putData(UPDATE_ACCOUNT_LOGO, ACCOUNT_ERROR, true, url, dispatch, formObj);
}
