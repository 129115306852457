import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { loginUser } from '../../actions/auth';
import logo from '../../assets/images/logo.svg';

const form = reduxForm({
  form: 'login',
});

class Login extends Component {

  constructor(props) {
    super(props);
    if (this.props.authenticated) {
      this.props.history.push('/');
    }
    this.state = {
      open: false,
    };
  }

  handleFormSubmit(formProps) {
    this.props.loginUser(formProps);
  }

  componentDidUpdate(prevProps) {
    var _this = this
    if (this.props.authenticated !== prevProps.authenticated) {
      this.setState({
        open: true,
      });
      setTimeout(function(){
        _this.props.history.push('/');
      }, 500);
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="message error">
          <span><strong>Error!</strong> {this.props.errorMessage}</span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;
    var classLine = "line-login"
    if(this.state.open){
      classLine+=" open"
    }
    var classHeader = "header-login"
    if(this.state.open){
      classHeader+=" open"
    }
    var classForm = "form-login"
    if(this.state.open){
      classForm+=" open"
    }

    return (
      <div className="page-login">
        <div className="line-login-back"></div>
        <div className={classLine}></div>
        <div className={classHeader}>
          <img src={logo}/>
        </div>
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className={classForm}>
          {this.renderAlert()}
          {/*<h1>Login</h1>*/}
          <div className="row">
            <label className="" htmlFor="email">Email</label>
            <Field name="email" placeholder="email" id="email" className="form-control" component="input" type="email" />
          </div>
          <div className="row">
            <label className="" htmlFor="password">Password</label>
            <Field name="password" placeholder="password" id="password" className="form-control" component="input" type="password" />
          </div>
          <button type="submit" className="btn btn-submit">Log in</button>
          <Link to="/forgot-password" style={{display:"block",marginTop:"10px"}}>Forgot Password ?</Link>
          <Link to="/register" style={{display:"block",marginTop:"10px"}}>Create an account</Link>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
  };
}

export default withRouter(connect(mapStateToProps, { loginUser })(form(Login)));
