import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { registerUser } from '../../actions/auth';
import { fetchUsers } from '../../actions/user';
import { withRouter } from 'react-router';
import logo from '../../assets/images/logo.svg';

const form = reduxForm({
  form: 'register',
  validate,
});

const renderField = field => (
  <div>
    {field.meta.touched && field.meta.error && <div className="error">{field.meta.error}</div>}
    <input className="form-control" type={field.type} placeholder={field.placeholder} {...field.input} value={ null }/>
  </div>
);

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstName = 'Please enter a first name';
  }

  if (!formProps.lastName) {
    errors.lastName = 'Please enter a last name';
  }

  if (!formProps.email) {
    errors.email = 'Please enter an email';
  }
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formProps.email)){
  }else{
    errors.email = 'Please enter a valid email';
  }

  if (!formProps.password) {
    errors.password = 'Please enter a password';
  }

  if (!formProps.passwordConfirm) {
    errors.passwordConfirm = 'Please confirm new password';
  }

  if (formProps.password !== formProps.passwordConfirm) {
    errors.passwordConfirm = 'Passwords must match';
  }

  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  if(!strongRegex.test(formProps.password)) {
    errors.password = 'Please enter a password with all requirement';
  }

  if(!strongRegex.test(formProps.passwordConfirm)) {
    errors.passwordConfirm = 'Please enter a password with all requirement';
  }

  return errors;
}

class Register extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
  }

  handleFormSubmit(formProps) {
    this.props.registerUser(formProps);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="message error">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message === 'user register !') {
      var _this = this
      setTimeout(function(){ _this.props.history.push('/login'); }, 3000);
      return (
        <div className="message">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="page-login">
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className="form-login" style={{top:0, animationDelay: 0}}>
          <img src={logo}/>
          {this.renderAlert()}
          <h1>Create an account</h1>
          <div className="row">
            <label className="" htmlFor="firstName">First Name</label>
            <Field name="firstName" id="firstName" component={renderField} type="text" placeholder="Your first name ..."/>
          </div>
          <div className="row">
            <label className="" htmlFor="lastName">Last Name</label>
            <Field name="lastName" id="lastName" component={renderField} type="text"  placeholder="Your last name ..."/>
          </div>
          <div className="row">
            <label className="" htmlFor="email">Email</label>
            <Field name="email" id="email" component={renderField} type="email"  placeholder="Your email ..."/>
          </div>
          <p style={{fontStyle: 'italic', color: '#999', marginTop: 0}}>Password must contain one uppercase, one lowercase, one number, one special character (!@#$%^$*) and be 8 characters long</p>
          <div className="row">
            <label className="" htmlFor="password">Password</label>
            <Field name="password" id="password" component={renderField} type="password" placeholder="Type your password ..."/>
          </div>
          <div className="row">
            <label className="" htmlFor="passwordConfirm">Confirm Password</label>
            <Field name="passwordConfirm" id="passwordConfirm" component={renderField} type="password" placeholder="Confirm your password ..."/>
          </div>
          <button type="submit" className="btn btn-submit">Register</button>
          <Link to="/login" style={{display:"block",marginTop:"10px"}}>Already have an account ?</Link>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    //authenticated: state.auth.authenticated,
  };
}

export default withRouter(connect(mapStateToProps, { registerUser })(form(Register)));
