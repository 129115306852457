import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContainerTranslation from "../../../partials/containerTranslation";
import GooglePicker from 'react-google-picker';
import { connect } from 'react-redux';
import _ from 'lodash';
import { GoogleSpreadsheet } from "google-spreadsheet";

const CLIENT_ID = '24006783402-e9cic0hohc2pfcs720gbfagklif7vt87.apps.googleusercontent.com';
const DEVELOPER_KEY = 'AIzaSyC7PgyjHDFDx3VF3K81Jx_2KCDNrKbSY9w';
const SCOPE = ['https://www.googleapis.com/auth/drive.readonly'];

class ConnectionGoogle extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
    this.state = {
      loading:false,
      error:null,
      token:null,
      file:null,
    }
  }

  async componentDidUpdate (prevProps, prevState) {
    var _this = this
    if(!prevState.file || (prevState.file && !prevState.file.docs)){
      if(this.state.file && this.state.file.docs){
        var doc = new GoogleSpreadsheet(this.state.file.docs[0].id);
        let rows = null
        doc.useApiKey('AIzaSyC7PgyjHDFDx3VF3K81Jx_2KCDNrKbSY9w')
        try {
          await doc.loadInfo();
        } catch (e) {
          _this.setState({
            loading:false,
            error:'Error in getting this file, make sure you own it.'
          })
        }
        const sheet = doc.sheetsByIndex[0]
        try {
          rows = await sheet.getRows();
        } catch (e) {
          _this.setState({
            loading:false,
            error:'Error in getting this file, make sure you own it.'
          })
        }

        const categories = _.map(sheet.headerValues, function(h){
          return {
            color: {r: 0, g: 0, b: 0, a: 1},
            separator: "",
            title: h,
            type: "text"
          }
        })
        const records = _.map(rows, function(r){
          var obj = {
            content : []
          }
          _.each(categories, function(cat){
            if(r[cat.title]){
              obj.content.push({
                category: cat.title,
                value: r[cat.title]
              })
            }
          })
          return obj
        })
        const card = {
          description: "",
          title: "",
          url: ""
        }
        this.setState({
          loading:false,
          error:null
        })
        this.props.useData({
          categories:categories,
          records:records,
          card:card
        }, 'connection');

      }
    }
  }

  renderAlert() {
    if (this.state.error) {
      return (
        <div className="message error">
          <strong>Oops!</strong> {this.state.error}
        </div>
      );
    }
  }

  render() {
    return (
      <div style={{display:'inline-block'}}>
        <GooglePicker clientId={CLIENT_ID}
          developerKey={DEVELOPER_KEY}
          scope={SCOPE}
          onChange={data => this.setState({file:data, loading:true, error:null})}
          onAuthenticate={token => this.setState({token:token})}
          onAuthFailed={data => console.log('on auth failed:', data)}
          multiselect={false}
          navHidden={true}
          authImmediate={false}
          mimeTypes={['application/vnd.google-apps.spreadsheet']}
          viewId={'DOCS'}>
          {this.state.loading ? (
            <a className="btn add-btn" key="laoding-sheet" style={{textAlign:'left',display:'inline-block', width:125, marginTop:10, marginLeft: 10, marginRight: 10, paddingLeft: 20, paddingRight: 20}}>
              <i className="fa fa-circle-notch fa-spin fa-3x" style={{color: '#fff'}}></i>
              <span style={{verticalAlign: "top", marginLeft: 10}}>Loading...</span>
            </a>
          ):(
            <a className="btn add-btn" key="laod-sheet" style={{textAlign:'left',display:'inline-block', width:125, marginTop:10, marginLeft: 10, marginRight: 10, paddingLeft: 20, paddingRight: 20}}>
              <i className="fab fa-google-drive"></i>
              <span style={{verticalAlign: "top", marginLeft: 10}}>Google Sheet</span>
            </a>
          )}
          <div className="google"></div>
        </GooglePicker>
        {this.renderAlert()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile
  };
}

export default connect(mapStateToProps)(ConnectionGoogle);
