import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "./../containerTranslation";

class Item extends Component {

  handleDelete(elm) {
    this.props.deleteItem(this.props.id)
  }

  selectItem(elm) {
    this.props.selectItem(this.props.id)
  }

  render() {
    var customClass = "list-item"
    if(this.props.selectedItem === this.props.id){
      customClass += " selected"
    }
    if(this.props.withTools === false && this.props.icon === ""){
      customClass += " minus-10"
    }

    return (
      <div className={customClass}>
        <a className="" onClick={this.selectItem.bind(this)}>
          <i className={this.props.icon} style={{verticalAlign:'top', marginTop:'4px'}}></i>
          {this.props.nameTranslate ? (
          <p className="list-title"><ContainerTranslation id={this.props.nameTranslate}/></p>
          ) : (
            <p className="list-title">{this.props.name}</p>
          )}
        </a>
        {this.props.withDetails === true && this.props.selectedItem === this.props.id ? (
          <p style={{
            display: 'block',
            fontWeight: 'normal',
            opacity: 0.7,
            lineHeight: 'initial',
            marginBottom: "10px"
          }}>{this.props.description}</p>
        ) : (
          null
        )}
        {this.props.withTools ? (
          <div className="tools">
            <a onClick={this.handleDelete.bind(this)} data-for='deleteTeam' data-tip="team.deleteTeam" className="Message-remove"><i className="fa fa-trash" aria-hidden="true"></i></a>
            <ReactTooltip className='tooltip' id='deleteTeam' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
          </div>
        ) : (
          null
        )}
      </div>
    );

  }
}

export default Item;
