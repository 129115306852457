import React from "react";
import { withLocalize } from "react-localize-redux";
import Select from 'react-select';
import _ from 'lodash';

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => (
  <div>
    <Select
      className="Select"
      name="language"
      options={_.map(languages, function(lang){return {value:lang.code,label:lang.name}})}
      value={{value:_.find(languages, {active:true}).code, label:_.find(languages, {active:true}).name}}
      multi={false}
      placeholder={'Select your language'}
      onChange={ (e) => setActiveLanguage(e.value) }
    />
  </div>
);

export default withLocalize(LanguageToggle);
