import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    let {token} = await this.props.stripe.createToken({name: this.props.account._id});
    this.props.submitSubscription(token)
  }

  render() {
    return (
      <div className="checkout">
        <CardElement />
        <button className="btn btn-submit" onClick={this.submit}>Let's Go !</button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
