import moment from 'moment';
import _ from 'lodash';

import {
  CREATE_VIZUALISATION,
  FETCH_VIZUALISATION,
  FETCH_KEYWORDS,
  FETCH_VIZUALISATION_VIEW,
  FETCH_VIZUALISATIONS,
  UPDATE_VIZUALISATION,
  INIT_VIZUALISATION,
  DELETE_VIZUALISATION,
  VIZUALISATION_ERROR,
  /////////////////////////////////////////////VIEW
  SET_POSITION,
  SET_SELECTED_NODES,
  SET_SELECTED_DATES,
  SET_MODE,
  SET_PLAY_MODE
} from '../actions/types';

const INITIAL_STATE = {
  vizualisation: null,
  vizualisationView: null,
  type: 'personal',
  keywords: [],
  vizualisations: [],
  message: '',
  error: '',
  /////////////////////////////////////////////VIEW
  selectedNodes:[],
  selectedDates : [null,null],
  boundingDates : [null,null],
  filteredRecords:[],
  mode:"group",
  graphData:null,
  playMode:null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_VIZUALISATION:
      return { ...state, vizualisation: action.payload.vizualisation, message: action.payload.message, error: '' };
    case FETCH_VIZUALISATION:
      return { ...state, vizualisation: action.payload.vizualisation, message:'', error: '' };
    case FETCH_KEYWORDS:
      return { ...state, keywords: action.payload.keywords, message:'', error: '' };
    case FETCH_VIZUALISATION_VIEW:
      var graphData = generateGraphData(action.payload.records, action.payload.vizualisation)
      var boundingDates = getBoundingDates(action.payload.records, action.payload.vizualisation.categories)
      return {
        ...state,
        vizualisationView: action.payload,
        filteredRecords: action.payload.records,
        graphData: graphData,
        boundingDates: boundingDates,
        selectedNodes:[],
        selectedDates : [null,null],
        position:null,
        mode:"group",
        message:'',
        error: ''
      };
    case FETCH_VIZUALISATIONS:
      return { ...state, type:action.payload.type, vizualisations: action.payload.vizualisations, vizualisation:null, vizualisationView:null, message:'', error: '' };
    case UPDATE_VIZUALISATION:
      return { ...state, message: action.payload.message, error: '' };
    case INIT_VIZUALISATION:
      return { vizualisation: null, vizualisationView:null, vizualisations: [], keywords:[], message: '', error: '' };
    case DELETE_VIZUALISATION:
      return { ...state, message: action.payload.message, error: '' };
    case VIZUALISATION_ERROR:
      return { ...state, error: action.payload };
    /////////////////////////////////////////////VIEW
    case SET_POSITION:
      return {
        ...state,
        position:action.payload
      };
    case SET_SELECTED_NODES:
      var filteredRecords = filterRecords(state.vizualisationView.records, state.vizualisationView.vizualisation.categories, action.payload, state.selectedDates)
      var graphData = generateGraphData(filteredRecords, state.vizualisationView.vizualisation)
      var selectedNodes = _.filter(graphData.nodes, function(node){
        return action.payload.indexOf(node.compound) !== -1
      })
      return {
        ...state,
        filteredRecords: filteredRecords,
        graphData: graphData,
        selectedNodes: selectedNodes
      };
    case SET_SELECTED_DATES:
      var filteredRecords = filterRecords(state.vizualisationView.records, state.vizualisationView.vizualisation.categories, _.map(state.selectedNodes, 'compound'), action.payload)
      var graphData = generateGraphData(filteredRecords, state.vizualisationView.vizualisation)
      return {
        ...state,
        filteredRecords: filteredRecords,
        graphData: graphData,
        selectedDates: action.payload
      };
    case SET_MODE:
      return { ...state, mode: action.payload };
    case SET_PLAY_MODE:
      return { ...state, playMode: action.payload };
  }

  return state;
}

var filterRecords = function(records, categories, nodes, dates) {
  if(dates[0] && dates[1]){
    var foundDateCategory = _.find(categories, function(cat){return cat.type === 'date'})
    records = _.filter(records, function(r){
      var foundDate = _.find(r.content, function(c){
        return c.category === foundDateCategory.title
      })
      if(foundDate && moment(foundDate.value, foundDateCategory.dateFormat)){
        var recordDate = new Date(moment(foundDate.value, foundDateCategory.dateFormat))
        if(recordDate.getTime() >= (dates[0] - 60*60*1000) && recordDate.getTime() < (dates[1] - 60*60*1000)){
          return true
        }
      }
      return false
    })
  }

  if(nodes && nodes.length){
    records = _.filter(records, function(record){
      var recordNodesCompound = []
      _.each(record.content, function(n){
        var categorie = _.find(categories, function(cat){
          return cat.title === n.category
        })
        var separator = null
        if(categorie && categorie.separator && categorie.separator !== ""){
          separator = categorie.separator
        }
        if(separator){
          var splitedNodes = n.value.split(separator)
          _.each(splitedNodes, function(splitedNode){
            recordNodesCompound.push(n.category+""+splitedNode.toString().trim())
          })
        }else{
          recordNodesCompound.push(n.category+""+n.value.toString().trim())
        }
      })
      var intersectionNodes = _.intersection(nodes, recordNodesCompound)
      return intersectionNodes.length === nodes.length
    })
  }

  return records
}

var getBoundingDates = function(records, categories) {
  var boundingDates = [null, null]
  var foundDateCategory = _.find(categories, function(cat){return cat.type === 'date'})
  if(foundDateCategory){
    var recordsDates = _.uniq(_.compact(_.map(records, function(r){
      var foundDate = _.find(r.content, function(c){
        return c.category === foundDateCategory.title
      })
      if(foundDate && moment(foundDate.value, foundDateCategory.dateFormat)._isValid){
        return moment(foundDate.value, foundDateCategory.dateFormat).format()
      }
      return null
    })))
    recordsDates = recordsDates.sort()
    boundingDates = [recordsDates[0], recordsDates[recordsDates.length-1]]
  }
  return boundingDates
}

var generateGraphData = function(records, vizualisation){

  var nodes = {}
  var links = {}
  var allLinks = []
  var categories = _.filter(vizualisation.categories, function(cat){
    return cat.type !== 'date'
  })

  var index = 0
  _.each(records, function(record){
    var doc = {}
    _.each(record.content, function(node){
      doc[node.category] = node.value
    })
    var docNodes = []

    _.each(categories, function(category){
      if(doc[category.title]){
        var splitedNodes = []
        var countValue = 1
        if(category.sizedBy && category.sizedBy !== "frequency"){
          if(doc[category.sizedBy] && !isNaN(parseFloat(doc[category.sizedBy]))){
            countValue = parseFloat(doc[category.sizedBy])
          }
        }
        if(category && category.separator && category.separator !== ""){
          splitedNodes = doc[category.title].split(category.separator)
        }else{
          splitedNodes.push(doc[category.title])
        }
        _.each(splitedNodes, function(node){
          if(node){
            var value = null
            var label = node.toString().trim()
            var compound = category.title+node.toString().trim()

            if(nodes[compound]){
              nodes[compound].count+=countValue
              value = nodes[compound].id
            }else{
              nodes[compound]={
                id:index,
                category:category.title,
                value:label,
                compound:compound,
                count:countValue
              }
              value = index
              index ++
            }
            docNodes.push({
              cat: category.title,
              linkedTo: category.linkedTo,
              value: value
            })
          }
        })
      }
    })
    docNodes.pairs(function(pair){
      allLinks.push(pair)
    });
  })
  nodes = Object.values(nodes)
  _.each(allLinks, function(link){
    if(link[0].linkedTo && !_.find(link[0].linkedTo, function(linkTo){return linkTo === link[1].cat})){
      return
    }
    var source = link[0].value
    var target = link[1].value

    if(links[source+"-"+target]){
      links[source+"-"+target].count +=1
    }else if(links[target+"-"+source]){
      links[target+"-"+source].count +=1
    }else{
      links[source+"-"+target] = {
        source:source,
        target:target,
        count:1
      }
    }
  })
  links = Object.values(links)

  _.each(categories, function(category){
    category.count = _.filter(nodes, function(node){
      return node.category === category.title
    }).length
  })

  return {
    categories:categories,
    nodes:nodes,
    links:links
  }
}

Array.prototype.pairs = function (func) {
    for (var i = 0; i < this.length - 1; i++) {
        for (var j = i; j < this.length - 1; j++) {
            func([this[i], this[j+1]]);
        }
    }
}
