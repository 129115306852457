import React, { Component } from 'react';
import ContainerTranslation from "./../../partials/containerTranslation";
import List from './../../partials/List/List'
import _ from 'lodash'

class Documents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRecord:null
    };
  }

  setSelectedRecord(i) {
    this.setState({
      selectedRecord:i
    })
  }

  selectRecord(i) {
    this.setState({
      selectedRecord:i
    })
  }

  close() {
    this.props.close();
  }

  render() {
    var records = this.props.records
    var selectedRecordId = null
    var selectedRecord = null
    if(this.state.selectedRecord){
      selectedRecordId = this.state.selectedRecord
      selectedRecord = _.find(records, function(r){return r._id === selectedRecordId})
    }else{
      selectedRecordId = records[0]._id
      selectedRecord = records[0]
    }
    _.each(records, function(record){
      record.name = record.title
    })

    return (
      <div className="page-vizualisation-overlay-documents">
        <div className="page-vizualisation-content-documents">
          <div className="page-vizualisation-documents-list">
            <p className="documents-title">{this.props.records.length} <ContainerTranslation id={"vizualisationView.documents"}/></p>
            <List
              data = {records}
              icon = ""
              theme = "light"
              withDetails = {false}
              withTools = {false}
              selectedItem={selectedRecordId}
              selectItem={this.selectRecord.bind(this)}
            />
          </div>
          <div className="page-vizualisation-documents-detail">
            {selectedRecord ? (
              <>
                <h1 style={{color:'#0A1760'}}>{selectedRecord.title}</h1>
                <a href={selectedRecord.url} target="_blank" rel="noopener noreferrer">{selectedRecord.url}</a>
                <p>{selectedRecord.description}</p>
              </>
            ) : (
              null
            )}
          </div>
          <a className="close-documents" onClick={ (e) => this.close(e) }>
            <i className="fas fa-times"></i> <ContainerTranslation id={"vizualisationView.back"}/>
          </a>
        </div>
      </div>
    );
  }
}

export default Documents;
