import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import _ from 'lodash'
import moment from 'moment';

import ContainerTranslation from "./../partials/containerTranslation";
import Comments from "./view/comments";
import Categories from "./view/categories";
import Documents from "./view/documents";
import Graph from "./view/graph";
import Geography from "./view/geography";
import Timeline from "./view/timeline";
import ExportExcel from './view/exportExcel';
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'

import logo from '../../assets/images/logo.svg';
import { country_codes } from "./../../assets/geography/country-codes";
import { string_to_slug } from "./../helpers";

import { fetchVizualisationView, setMode, setSelectedNodes, setSelectedDates } from '../../actions/vizualisation';

class VizualisationView extends Component {

  constructor(props) {
    super(props);
    const { params, fetchVizualisationView, setMode, setSelectedNodes, setSelectedDates } = this.props;
    this.state = {
      displayLinks:false,
      displayDocuments:false,
      displayComments:false,
      showTimeline:false,
      showGeography:false,
      showComments:false,
      share:false,
      hiddenCategories:[]
    };
  }

  componentDidMount(){
    var idVizualisation = window.location.pathname.split('/')[window.location.pathname.split('/').length-1]
    if(idVizualisation !== 'view'){
      if(localStorage.getItem("token")){
        this.props.fetchVizualisationView(idVizualisation, false)
      }else{
        this.props.fetchVizualisationView(idVizualisation, true)
      }
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.records && this.props.vizualisation) {
      if(!prevProps.vizualisation || this.props.vizualisation._id !== prevProps.vizualisation._id){
        var _this = this
        let showTimeline = false
        if(_.map(_this.props.vizualisation.categories, 'type').indexOf('date') !== -1){
          showTimeline = true
        }
        let showGeography = false
        if(_.map(_this.props.vizualisation.categories, 'type').indexOf('country') !== -1){
          showGeography = true
        }
        let showComments = false
        if(_this.props.vizualisation.accountLimitations.indexOf('comment') !== -1 || _this.props.vizualisation.accountType !== 'Member'){
          //if(_this.props.authenticated){
            showComments = true
          //}
        }
        this.setState({
          showTimeline:showTimeline,
          showGeography:showGeography,
          showComments:showComments
        })
      }
    }
  }

  displayLinks(e) {
    this.setState({
      displayLinks:!this.state.displayLinks
    })
  }

  setMode(mode) {
    this.props.setMode(mode)
  }

  downloadViz(e) {
    console.log("download")
  }

  shareViz(e) {
    var _this = this
    this.setState({
      share:!_this.state.share
    })
  }

  openShareModal(e, href){
    window.open(href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=500,width=700');
    return false;
  }

  displayDocuments(e) {
    this.setState({
      displayDocuments:true
    })
  }

  toggleComments(e) {
    var _this = this
    this.setState({
      displayComments:!_this.state.displayComments
    })
  }

  closeDocuments() {
    this.setState({
      displayDocuments:false
    })
  }

  selectDates(dates) {
    this.props.setSelectedDates(dates)
  }

  selectCategory(category) {
    var hiddenCategories = this.state.hiddenCategories
    if(hiddenCategories.indexOf(category) !== -1){
      _.remove(hiddenCategories, function(c){
        return c.toString() === category.toString()
      })
    }else{
      hiddenCategories.push(category)
    }
    this.setState({
      hiddenCategories:hiddenCategories
    })
  }

  selectNode(node) {
    var _this = this
    var foundNode = _.find(_this.props.graphData.nodes, function(n){
      return n.compound.toString() === node.compound.toString()
    })
    if(foundNode){
      var selectedNodes = _.cloneDeep(_this.props.selectedNodes)
      var foundSelectedNode = _.find(selectedNodes, function(n){
        return n.compound.toString() === node.compound.toString()
      })
      if(foundSelectedNode){
        _.remove(selectedNodes, function(n){
          return n.compound.toString() === foundNode.compound.toString()
        })
      }else{
        selectedNodes.push(foundNode)
      }
      this.props.setSelectedNodes(_.map(selectedNodes, 'compound'))
    }
  }

  handleSelectedNodesChange(e,i) {
    var nodes = this.props.graphData.nodes
    nodes = _.filter(nodes, function(node){
      return _.find(e, function(n){
        return node.value === n.label && node.category === n.category
      })
    })
    this.props.setSelectedNodes(_.map(nodes, 'compound'))
  }

  render() {
    let name = ""
    let id = ""
    let graphData = null
    const _this = this

    if(this.props.graphData){
      graphData = Object.assign({}, _this.props.graphData)
    }
    if(this.props.vizualisation && this.props.vizualisation.name){
      name = this.props.vizualisation.name
    }
    if(this.props.vizualisation && this.props.vizualisation._id){
      id = this.props.vizualisation._id
    }
    let shareClass = "vizualisation-tool hideOnMobile"
    if(this.state.share){
      shareClass += " active"
    }
    let linkClass = "vizualisation-tool hideOnMobile"
    if(this.state.displayLinks){
      linkClass += " active"
    }
    let commentClass = "vizualisation-tool btn-comments hideOnMobile"
    if(this.state.displayComments){
      commentClass += " active"
    }
    let groupClass = "vizualisation-mode"
    if(this.props.mode === "group"){
      groupClass += " active"
    }
    let networkClass = "vizualisation-mode"
    if(this.props.mode === "network"){
      networkClass += " active"
    }
    let geographyClass = "vizualisation-mode"
    if(this.props.mode === "geography"){
      geographyClass += " active"
    }
    let blurredClass = "page-vizualisation-back"
    if(this.state.displayDocuments){
      blurredClass += " blurred"
    }

    var selectedNodesOptions = null
    if(graphData){
      selectedNodesOptions = _.compact(_.map(graphData.nodes, function(node){
        var color = '#ccc'
        var category = _.find(graphData.categories, function(c){
          return c.title === node.category
        })
        if(category){
          color = category.color
        }
        return {
          value:node.id,
          category: node.category,
          label:node.value,
          color:color
        }
      }))
    }

    var selectedNodesValue = _.compact(_.map(_this.props.selectedNodes, function(node){
      var color = '#ccc'
      var category = _.find(graphData.categories, function(c){
        return c.title === node.category
      })
      if(category){
        color = category.color
      }
      return {
        value:node.id,
        label:node.value,
        category: node.category,
        color:color
      }
    }))

    var isMine = false
    if(this.props.author === this.props.profile._id){
      isMine = true
    }

    var countryCategory = null
    if(this.state.showGeography && this.props.mode === 'geography'){
      countryCategory = _.find(_this.props.vizualisation.categories, function(cat){return cat.type === 'country'})
      if(countryCategory){
        _.each(graphData.nodes, function(node){
          var foundCountry = _.find(country_codes, function(code){return string_to_slug(code.name) === string_to_slug(node.value)})
          if(foundCountry){
            node.code = foundCountry["alpha-2"]
          }else{
            node.code = null
          }
        })

        var foundSelectedNodeCountry = _.find(_this.props.selectedNodes, function(n){
          return n.category.toString() === countryCategory.title.toString()
        })
        if(!foundSelectedNodeCountry){
          graphData.nodes = _.filter(graphData.nodes, function(node){return node.category === countryCategory.title && node.code})
          graphData.links = _.filter(graphData.links, function(link){
            var foundSource = _.find(graphData.nodes, function(node){return node.compound === link.source.compound})
            var foundTarget = _.find(graphData.nodes, function(node){return node.compound === link.target.compound})
            return foundSource && foundTarget
          })
        }
      }
    }

    return (
      <div className="page-vizualisation">

        <div className={blurredClass}>
          {/*GEOGRAPHY*/}
          {countryCategory ? (
            <Geography
              category={countryCategory}
              mode={this.props.mode}
              playMode={this.props.playMode}
              selectedNodes={this.props.selectedNodes}
              setSelectedNode={this.selectNode.bind(this)}
            />
          ) : (
            null
          )}

          {/*NODES*/}
          {this.props.vizualisation && graphData ? (
            <Graph
              graphData={graphData}
              width={this.state.displayComments ? 'calc(100% - 300px)' : '100%'}
              mode={this.props.mode}
              playMode={this.props.playMode}
              displayLinks={this.state.displayLinks}
              displayDocuments={this.state.displayDocuments}
              selectedNodes={this.props.selectedNodes}
              hiddenCategories={this.state.hiddenCategories}
              setSelectedNode={this.selectNode.bind(this)}
            />
          ) : (
            null
          )}

          {/*HEADER*/}
          <div className="page-vizualisation-header">
            <Link to="/vizualisation">
              {this.props.vizualisation && this.props.vizualisation.logo ? (
                <img src={this.props.vizualisation.logo} height="50"/>
              ) : (
                <img src={logo} height="50"/>
              )}
            </Link>
            <p className="page-back hideOnMobile">
              <Link className="hideOnTablet" style={{color:"#fff", textDecoration:"none"}} to="/vizualisation">
              <ContainerTranslation id={"vizualisation.viewTitle"}/></Link>
              <i className="fas fa-angle-right" style={{marginLeft:'10px', marginRight:'10px', color:'#8389AA'}}></i>
              <span style={{fontWeight:'bold'}}>{name}</span>
            </p>
          </div>

          {/*TOOLS*/}
          <div className="page-vizualisation-tools" style={this.state.displayComments ? {right:'328px'} : {right:'28px'}}>
            <div className="vizualisation-search">
              <Select
                className="Select"
                value={selectedNodesValue}
                placeholder="Selected Nodes..."
                options={selectedNodesOptions}
                styles={colourStyles}
                isMulti
                isSearchable
                onChange={ (e) => this.handleSelectedNodesChange(e) }
              />
            </div>

            {this.props.vizualisation && this.props.vizualisation._id && localStorage.getItem("token") && isMine ? (
              <Link className="vizualisation-tool hideOnMobile" to={"/vizualisation/add/"+this.props.vizualisation._id} data-for='tool' data-tip="vizualisation.edit">
                <i className="fa fa-pen"></i>
              </Link>
            ) : (
              null
            )}
            {graphData && graphData.categories && isMine ? (
                <a className="vizualisation-tool" data-for='tool' data-tip="vizualisation.download">
                    <ExportExcel dataToExcel={{records:this.props.records, categories:graphData.categories}} data-for='tool' data-tip="vizualisation.download"/>
                </a>
            ) : (
              null
            )}

              <div style={{display:'inline-block', verticalAlign:'top'}}>
                  <a className={shareClass} style={{display:'block'}} data-for='tool' data-tip="vizualisation.share" onClick={ (e) => this.shareViz(e) }>
                    <i className="fas fa-share"></i>
                  </a>
                {this.state.share ?(
                    <>
                        <a className="vizualisation-tool" style={{display:'block', opacity:0.3, cursor:"not-allowed"}} href="" alt="Embed Code" data-for='tool' data-tip="vizualisation.shareEmbed">
                          <i className="fas fa-code"></i>
                        </a>
                        <a className="vizualisation-tool"
                          rel="nofollow"
                          style={{display:'block'}}
                          onClick={ (e) => this.openShareModal(e, "https://twitter.com/share?url=https://app.bakamap.com/vizualisation/view/"+id+"&text=Check out this map : "+name+" ->&hashtags=Dataviz&via=Baka_map") }
                          alt="Share on Twitter"
                          data-for='tool'
                          data-tip="vizualisation.shareTwitter">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a className="vizualisation-tool"
                          rel="nofollow"
                          style={{display:'block'}}
                          onClick={ (e) => this.openShareModal(e, "https://www.facebook.com/sharer/sharer.php?u=https://app.bakamap.com/vizualisation/view/"+id+"&title="+name+"&description="+this.props.vizualisation.description) }
                          alt="Share on Facebook"
                          data-for='tool'
                          data-tip="vizualisation.shareFacebook">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                    </>
                ) : (
                  null
                )}
              </div>


            <a className={linkClass} data-for='tool' data-tip="vizualisation.showLinks" onClick={ (e) => this.displayLinks(e) }>
              <i className="fas fa-bezier-curve"></i>
            </a>

            <a className="vizualisation-tool hideOnMobile reset-view" data-for='tool' data-tip="vizualisation.reset">
              <i className="fas fa-compress"></i>
            </a>

            {/*COMMENTS BTN*/}
            {this.props.vizualisation && graphData && this.state.showComments === true ? (
              <a className={commentClass} data-for='tool' data-tip="vizualisation.comment" onClick={ (e) => this.toggleComments(e) }>
                <i className="fas fa-info"></i>
              </a>
            ) : (
              <a className="vizualisation-tool" data-for='tool' data-tip="vizualisation.commentNA" style={{opacity:0.3, cursor:'not-allowed'}}>
                <i className="fas fa-info"></i>
              </a>
            )}
          </div>

          {/*MODE*/}
          {this.props.vizualisation && graphData ? (
            <div className="page-vizualisation-modes" style={this.state.displayComments ? {left:'calc(50% - 150px)'} : {left:'50%'}}>
              <a className={groupClass} onClick={ (e) => this.setMode("group") }>
                <i className="fas fa-th-large"></i><ContainerTranslation id={"vizualisationView.group"}/>
              </a>
              <a className={networkClass} onClick={ (e) => this.setMode("network") }>
                <i className="fas fa-project-diagram"></i><ContainerTranslation id={"vizualisationView.network"}/>
              </a>
              { this.state.showGeography ? (
                <a className={geographyClass} onClick={ (e) => this.setMode("geography") }>
                  <i className="fas fa-map-marked-alt"></i><ContainerTranslation id={"vizualisationView.geography"}/>
                </a>
              ) : (
                <a className="vizualisation-mode" style={{opacity:0.3, cursor:'not-allowed'}}>
                  <i className="fas fa-map-marked-alt"></i><ContainerTranslation id={"vizualisationView.geography"}/>
                </a>
              )}
            </div>
          ) : (
            null
          )}

          {/*CATEGORIES*/}
          {this.props.vizualisation && graphData ? (
            <Categories
              mode={this.props.mode}
              displayComments={this.state.displayComments}
              categories={graphData.categories}
              hiddenCategories={this.state.hiddenCategories}
              selectCategory={this.selectCategory.bind(this)}
            />
          ) : (
            null
          )}

          {/*TIMELINE*/}
          {this.props.vizualisation && this.state.showTimeline && this.props.boundingDates && this.props.boundingDates[0] && this.props.boundingDates[1] ? (
            <Timeline
              categories={this.props.vizualisation.categories}
              boundingDates={this.props.boundingDates}
              selectedDates={this.props.selectedDates}
              records={this.props.records}
              setSelectedDates={this.selectDates.bind(this)}
            />
          ) : (
            null
          )}

          {/*COMMENTS*/}
          {this.props.vizualisation && this.state.displayComments ? (
            <Comments
              description={this.props.vizualisation.description}
              selectedNodes={this.props.selectedNodes}
              selectedDates={this.props.selectedDates}
              mode={this.props.mode}
              vizualisation={this.props.vizualisation._id}
            />
          ) : (
            null
          )}

          {/*BTN CONTENTS*/}
          <div className="btn-contents" style={this.state.displayComments ? {right:'318px'} : {right:'18px'}}>
            {/*DOCUMENTS BTN*/}
            {this.props.vizualisation && graphData && this.props.records && this.props.records.length && this.props.vizualisation.showRecords === true && !this.state.displayDocuments ? (
              <a className="btn-documents" onClick={ (e) => this.displayDocuments(e) }>
                <span><i className="fas fa-list"></i></span>
                <span className="hideOnMobile" style={{fontWeight:'bold', marginLeft:"5px"}}>{this.props.records.length}</span>
                <span className="hideOnMobile" style={{marginLeft:"5px"}}><ContainerTranslation id={"vizualisationView.documents"}/></span>
              </a>
            ) : (
              null
            )}
          </div>

        </div>

        {/*DOCUMENTS LIST*/}
        {this.state.displayDocuments ? (
          <Documents
            records={this.props.records}
            close={this.closeDocuments.bind(this)}
          />
        ) : (
          null
        )}

        <ReactTooltip key={'tooltip'+new Date().getTime()} className='tooltip' id='tool' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let vizualisation = null
  let author = null
  if(state.vizualisation.vizualisationView){
    vizualisation = state.vizualisation.vizualisationView.vizualisation
    author = vizualisation.author
  }

  return {
    vizualisation: vizualisation,
    author: author,
    profile: state.user.profile,
    records: state.vizualisation.filteredRecords,
    boundingDates: state.vizualisation.boundingDates,
    mode: state.vizualisation.mode,
    graphData: state.vizualisation.graphData,
    playMode: state.vizualisation.playMode,
    selectedNodes: state.vizualisation.selectedNodes,
    selectedDates: state.vizualisation.selectedDates,
    authenticated: state.auth.authenticated,
    message: state.vizualisation.message
  };
}

export default connect(mapStateToProps, {fetchVizualisationView, setMode, setSelectedNodes, setSelectedDates})(VizualisationView);

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const dotSelected = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginLeft: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  dropdownIndicator: styles => ({ ...styles, display: 'none' }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none'
  }),
  clearIndicator: styles => ({
    ...styles,
    color: '#152764',
    cursor:'pointer',
    ':hover': {
      color:"#152764",
      opacity:0.7
    }
  }),
  control: styles => ({
    ...styles, borderRadius: '8px',
    border: 'none',
    boxShadow:  '2px 2px 20px -10px #000;',
    minHeight: '36px',
    background: '#fff'
  }),
  input: styles => ({
    color: '#152764'
  }),
  /*menu: styles => ({
    ...styles, borderRadius: '18px',
    border: 'none',
    boxShadow:  '4px 4px 7px #122155, -4px -4px 7px #182d73',
    background: '#152764'
    //background: "linear-gradient(145deg, #162a6b, #13235a)"
  }),*/
  placeholder: styles => ({ ...styles, color: '#152764', opacity:0.3 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      ...dot('rgb('+data.color.r+','+data.color.g+','+data.color.b+')'),
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? 'rgba('+data.color.r+','+data.color.g+','+data.color.b+', 0.1)'
        : null,
      color: isDisabled
        ? '#ccc'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : 'rgba('+data.color.r+','+data.color.g+','+data.color.b+', 0.3)'),
      }
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      ...dotSelected('rgb('+data.color.r+','+data.color.g+','+data.color.b+')'),
      backgroundColor: 'rgba('+data.color.r+','+data.color.g+','+data.color.b+', 0.1)',
      color: '#152764',
      borderRadius: '22px'
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    cursor:'pointer',
    ':hover': {
      backgroundColor: data.color,
      color: '#152764',
    },
  }),
};
