import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from "react-dom/server";
import HeaderTemplate from './partials/header';
import FooterTemplate from './partials/footer';
import Message from './partials/Message/Message';
import ContainerTranslation from "./partials/containerTranslation";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "./../assets/translations/global.json";
import { closeV1Message } from './../actions/global'

const onMissingTranslation = ({ defaultTranslation }) => {
  return ''
};

class Page extends Component {

  constructor(props) {
    super(props);
    const { params, closeV1Message } = this.props;

    const languages = [
      { name: "English", code: "en" },
      { name: "Français", code: "fr" }
    ];
    const defaultLanguage = localStorage.getItem("languageCodeBakamap") || languages[0].code;

    this.props.initialize({
      languages: languages,
      translation: globalTranslations,
      options: {
        defaultLanguage,
        renderToStaticMarkup : false,
        onMissingTranslation
     }
    });

    if(this.props.location.pathname === "/"){
      this.props.history.push('/vizualisation');
    }

  }

  componentDidUpdate(prevProps) {
    var prevLangCode = null
    var curLangCode = null;

    if(!prevProps.activeLanguage){
      prevLangCode = null
      if(this.props.activeLanguage){
        curLangCode = this.props.activeLanguage.code
      }else{
        curLangCode = null
      }
    }else{
      prevLangCode = prevProps.activeLanguage.code
      if(this.props.activeLanguage){
        curLangCode = this.props.activeLanguage.code
      }else{
        curLangCode = null
      }
    }

    const hasLanguageChanged = prevLangCode !== curLangCode;

    if (hasLanguageChanged) {
      localStorage.setItem('languageCodeBakamap', curLangCode);
    }
  }

  closeV2(e) {
    this.props.closeV1Message()
  }

  render() {

    return (
      <div className="mainContainer">
        {this.props.location.pathname.startsWith('/source/add') || this.props.location.pathname.startsWith('/vizualisation/add') || this.props.location.pathname.startsWith('/vizualisation/view') ? (
        <div className="darkContainer"></div>
        ) : (
        null
        )}
        <div>
          {this.props.children}
        </div>
        <HeaderTemplate title="Bakamap" location={this.props.location.pathname}/>
        {this.props.v1message ? (
          <Message
            close={this.closeV2.bind(this)}
            icon="fas fa-coffee"
            type=""
            message="header.v2"
            href="https://v1.app.bakamap.com"
            linkMessage="header.v2Link"
          />
        ) : (
          null
        )}
        <FooterTemplate location={this.props.location.pathname}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    v1message: state.global.message
  };
}


export default withLocalize(connect(mapStateToProps, {closeV1Message})(Page));
