import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth_reducer';
import userReducer from './user_reducer';
import accountReducer from './account_reducer';
import teamReducer from './team_reducer';
import commentReducer from './comment_reducer';
import sourceReducer from './source_reducer';
import vizualisationReducer from './vizualisation_reducer';
import globalReducer from './global_reducer';

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  user: userReducer,
  global: globalReducer,
  account: accountReducer,
  team: teamReducer,
  comment: commentReducer,
  source: sourceReducer,
  vizualisation: vizualisationReducer,
});

export default rootReducer;
