import { ACCOUNT_ERROR, FETCH_ACCOUNT, UPDATE_ACCOUNT, UPDATE_ACCOUNT_LOGO } from '../actions/types';

const INITIAL_STATE = { account: {}, message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ACCOUNT:
      return { ...state, account: action.payload.account, message:'', error: '' };
    case UPDATE_ACCOUNT:
      return { ...state, account: action.payload.account, message: action.payload.message, error: '' };
    case UPDATE_ACCOUNT_LOGO:
      return { ...state, message: action.payload.message, error: '' };  
    case ACCOUNT_ERROR:
      return { ...state, message: action.payload.message, error: action.payload };
  }

  return state;
}
