import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContainerTranslation from "../../partials/containerTranslation";
import Select from 'react-select'
import _ from 'lodash'

class SourceAddCard extends Component {

  constructor(props) {
    super(props);
    const { params } = this.props;
  }

  handleTitleChange(e) {
    var card = this.props.card
    card.title = this.props.categories[e.value]
    this.props.setNewState({
      card:card
    })
  }
  handleDescriptionChange(e) {
    var card = this.props.card
    card.description = this.props.categories[e.value]
    this.props.setNewState({
      card:card
    })
  }
  handleURLChange(e) {
    var card = this.props.card
    card.url = this.props.categories[e.value]
    this.props.setNewState({
      card:card
    })
  }

  renderCategories() {
    var _this = this
    var categoryClass = "category"

    var options = _.map(this.props.categories, function(c,i){
      return {
        value:i,
        label:c.title
      }
    })

    var optionTitle = null
    if(this.props.card && this.props.card.title && this.props.card.title.title){
      optionTitle = _.find(options, {'label':this.props.card.title.title})
    }

    var optionDescription = null
    if(this.props.card && this.props.card.description && this.props.card.description.title){
      optionDescription = _.find(options, {'label':this.props.card.description.title})
    }

    var optionURL = null
    if(this.props.card && this.props.card.url && this.props.card.url.title){
      optionURL = _.find(options, {'label':this.props.card.url.title})
    }

    return (
      <div style={{marginTop:'20px'}}>
        <div className={categoryClass} style={{paddingRight: 0}}>
          <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', marginRight: '5px', width: '110px'}}><ContainerTranslation id={"source.cardTitle"}/></p>
          <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop:"3px"}}>
            <Select
              className="Select"
              value={optionTitle}
              clearable={false}
              searchable={false}
              options={options}
              multi={false}
              onChange={ (e) => this.handleTitleChange(e) }
            />
          </div>
        </div>
        <div className={categoryClass} style={{paddingRight: 0}}>
          <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', marginRight: '5px', width: '110px'}}><ContainerTranslation id={"source.cardDescription"}/></p>
          <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop:"3px"}}>
            <Select
              className="Select no-z-index"
              value={optionDescription}
              clearable={false}
              searchable={false}
              options={options}
              multi={false}
              onChange={ (e) => this.handleDescriptionChange(e) }
            />
          </div>
        </div>
        <div className={categoryClass} style={{paddingRight: 0}}>
          <p style={{display:'inline-block', verticalAlign: 'top', marginTop: '2px', marginRight: '5px', width: '110px'}}><ContainerTranslation id={"source.cardURL"}/></p>
          <div style={{display: 'inline-block', width: 'calc(100% - 120px)', marginTop:"3px"}}>
            <Select
              className="Select no-z-index"
              value={optionURL}
              clearable={false}
              searchable={false}
              options={options}
              multi={false}
              onChange={ (e) => this.handleURLChange(e) }
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container container-add" style={{display: 'inline-block', marginTop:400, marginLeft: '100px', width: 'calc(100% - 300px)', textAlign:'left', paddingTop:0}}>
        <p style={{color:'#fff', fontWeight:'700',textAlign: 'left', width: '60%', marginTop:50}}><ContainerTranslation id={"source.cardPageTitle"}/></p>
        <p style={{color:'#fff', marginTop:'20px',textAlign: 'left', width: '60%', fontWeight: 300}}><ContainerTranslation id={"source.cardPageDescription"}/></p>
        {this.renderCategories()}
        {this.props.error ? (
          <div className='message error' style={{marginTop:'20px'}}>
            {this.props.error}
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(mapStateToProps)(SourceAddCard);
