import React, { Component } from 'react';
import { Router, Route, IndexRoute } from 'react-router';
import { createBrowserHistory } from 'history';
import { LocalizeProvider } from "react-localize-redux";

import ReactGA from 'react-ga';

// Import miscellaneous routes and other requirements
import Page from './page';
import NotFoundPage from './pages/not-found-page';

// Import authentication related pages
import Register from './auth/register';
import Login from './auth/login';
import Logout from './auth/logout';
import ForgotPassword from './auth/forgot_password';
import ResetPassword from './auth/reset_password';

// Import users pages
import Team from './team/team';

// Import Profile pages
import Profile from './profile/profile';

// Import higher order components
import RequireAuth from './auth/require_auth';

// Import source pages
import Source from './source/source';
import SourceAdd from './source/add';

// Import visualisations pages
import Vizualisation from './vizualisation/vizualisation';
import VizualisationAdd from './vizualisation/add';
import VizualisationView from './vizualisation/view';

// Import help pages
import Help from './help/help';
import HelpVizualisation from './help/vizualisation';
import HelpCreateSource from './help/createSource';
import HelpCreateVizualisation from './help/createVizualisation';
import HelpAccount from './help/account';
import HelpTeam from './help/team';

// Initialize Google Analytics
ReactGA.initialize('UA-38976794-1');
function logPageView() {
  ReactGA.pageview(window.location.pathname);
}

class App extends Component {
  render() {
    const register = function register(props){
      return <Page title="Register" {...props}><Register /></Page>
    }
    const login = function login(props){
      return <Page title="Login" {...props}><Login /></Page>
    }
    const logout = function logout(props){
      return <Page title="Logout" {...props}><Logout /></Page>
    }

    return (
      <LocalizeProvider>
        <Router history={createBrowserHistory()} onUpdate={logPageView}>
          <Route exact path="/" component={RequireAuth((props) => <Page title="Vizualisation" {...props}><Vizualisation /></Page>)} />

          <Route path="/register" component={register} />
          <Route path="/login" component={login} />
          <Route path="/logout" component={logout} />
          <Route path="/forgot-password" component={(props) => <Page title="ForgotPassword" {...props}><ForgotPassword /></Page>} />
          <Route path="/reset-password/:resetToken" component={(props) => <Page title="ResetPassword" {...props}><ResetPassword /></Page>} />

          <Route path="/profile" component={RequireAuth((props) => <Page title="Profile" {...props}><Profile /></Page>)} />

          <Route path="/team" component={RequireAuth((props) => <Page title="Team" {...props}><Team /></Page>)} />

          <Route exact path="/source" component={RequireAuth((props) => <Page title="Source" {...props}><Source /></Page>)} />
          <Route exact path="/source/add" component={RequireAuth((props) => <Page title="Source" {...props}><SourceAdd /></Page>)} />
          <Route path="/source/add/:id" component={RequireAuth((props) => <Page title="Source" {...props}><SourceAdd /></Page>)} />

          <Route exact path="/vizualisation" component={RequireAuth((props) => <Page title="Vizualisation" {...props}><Vizualisation /></Page>)} />
          <Route exact path="/vizualisation/add" component={RequireAuth((props) => <Page title="Vizualisation" {...props}><VizualisationAdd /></Page>)} />
          <Route path="/vizualisation/add/:id" component={RequireAuth((props) => <Page title="Vizualisation" {...props}><VizualisationAdd /></Page>)} />
          <Route path="/vizualisation/view/:id" component={(props) => <Page title="Vizualisation" {...props}><VizualisationView /></Page>} />

          <Route exact path="/help" component={(props) => <Page title="Help" {...props}><Help /></Page>} />
          <Route path="/help/vizualisation" component={(props) => <Page title="Help Vizualisation" {...props}><HelpVizualisation /></Page>} />
          <Route path="/help/create_source" component={(props) => <Page title="Help Create Source" {...props}><HelpCreateSource /></Page>} />
          <Route path="/help/create_vizualisation" component={(props) => <Page title="Help Create Vizualisation" {...props}><HelpCreateVizualisation /></Page>} />
          <Route path="/help/account" component={(props) => <Page title="Help Account" {...props}><HelpAccount /></Page>} />
          <Route path="/help/team" component={(props) => <Page title="Help Team" {...props}><HelpTeam /></Page>} />

          {/*<Route path="*" component={NotFoundPage} />*/}
        </Router>
      </LocalizeProvider>
    );
  }
}

export default App;
