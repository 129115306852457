import React, { Component } from 'react';
import MemberItem from './member-item';

const moment = require('moment');

class MemberList extends Component {
  render() {
    const _this = this
    return (
      <div className="fullpage-list">
        { this.props.members.length === 0 ? (
          <div className="line" style={{boxShadow: 'none'}}>
            <i className="far fa-frown"></i> <p>You currently have no member here !</p>
          </div>
        ) : (
          null
        )}
        {this.props.members.map((data, i) => <MemberItem
          selectedTeam={_this.props.selectedTeam}
          profile={data.profile}
          email={data.email}
          i={i}
          key={'member'+i}
        />)}
      </div>
    );
  }
}

export default MemberList;
